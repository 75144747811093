import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getPessoaLogada, getRotasPermitidas } from '../actions';
import Routers from '../router';

const Components = ({ getRotasAction, getPessoaAction }) => {
  useEffect(() => {
    getRotasAction();
    getPessoaAction();
  }, [getRotasAction, getPessoaAction]);

  return <Routers />;
};

const mapDispatchToProps = {
  getRotasAction: getRotasPermitidas,
  getPessoaAction: getPessoaLogada,
};

export default connect(
  null,
  mapDispatchToProps,
)(Components);

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  sindicos: [],
  processandoSindico: false,
  unidadesSindico: [],
  unidadesSubSindico: [],
  sindicoById: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.SDC_SET_ESTADO_INICIAL:
      return { ...state, processandoSindico: false, sindicos: [] };
    case a.SDC_GET_SINDICOS:
      return { ...state, sindicos: payload };
    case a.SDC_PROCESSANDO_SINDICO:
      return { ...state, processandoSindico: payload };
    case a.SDC_GET_UNIDADES_SINDICO:
      return { ...state, unidadesSindico: payload };
    case a.SDC_GET_UNIDADES_SUB_SINDICO:
      return { ...state, unidadesSubSindico: payload };
    case a.SDC_GET_SINDICOS_BY_ID:
      return { ...state, sindicoById: payload };
    default:
      return { ...state };
  }
};

import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { formatarNumeroGeral } from '../utils/Functions';
import { createSuccessNotification, sendErroRequest } from '../utils/Notification';

const { hashHistory } = hash;

export const getPortadoresSuccess = (response) => ({
  type: a.PTD_GET_PORTADORES,
  payload: response,
});

export const setBancos = (response) => ({
  type: a.PTD_GET_BANCOS,
  payload: response,
});

export const processandoPortador = (bool) => ({
  type: a.PTD_PROCESSANDO_PORTADOR,
  payload: bool,
});

export const setBancosById = (data) => ({
  type: a.PTD_GET_PORTADORES_BY_ID,
  payload: data,
});

export const getPortadores = () => async (dispatch) => {
  try {
    dispatch(processandoPortador(true));

    const { data } = await laris.get(`/portadores`);
    dispatch(getPortadoresSuccess(data), processandoPortador(false));
  } catch (err) {
    dispatch(processandoPortador(false));
    sendErroRequest(err, 'Ocorreu um erro ao buscar os Portadores!');
  }
};

export const getBancos = () => async (dispatch) => {
  try {
    const { data } = await laris.get(`/portadores/getBancos`);
    dispatch(setBancos(data));
  } catch (err) {
    sendErroRequest(err, 'Ocorreu um erro ao carregar os bancos!');
  }
};

export const getPortadorById = (id) => async (dispatch) => {
  try {
    dispatch(processandoPortador(true));
    const { data } = await laris.get(`/portadores/${id}`);
    dispatch(setBancosById(data));
    dispatch(processandoPortador(false));
  } catch (err) {
    dispatch(processandoPortador(false));
    sendErroRequest(err, 'Ocorreu um erro ao buscar a Portador selecionada!');
  }
};

export const deletePortador = (id) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      await laris.delete(`/portadores/${id}`);
      dispatch(getPortadores());
      createSuccessNotification('Portador excluído com sucesso!');
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao excluir o Portador!');
  }
};

export const putPortador = (values) => async (dispatch) => {
  try {
    const formularioPortador = {
      // geral
      ...(values.id && { id: values.id }),
      ...(values.descricao && { descricao: values.descricao }),
      ...(values.contaRecursoFinanceiro && {
        contaRecursoFinanceiro: values.contaRecursoFinanceiro,
      }),
      // cobranca
      ...(values.banco && { banco: values.banco }),
      ...(values.sistemaDeCobranca && { sistemaDeCobranca: values.sistemaDeCobranca }),
      ...(values.modeloDeCarteira && { modeloDeCarteira: values.modeloDeCarteira }),
      ...(values.agencia && { agencia: values.agencia }),
      ...(values.dvAgencia && { dvAgencia: values.dvAgencia }),
      ...(values.conta && { conta: values.conta }),
      ...(values.dvConta && { dvConta: values.dvConta }),
      ...(values.carteira && { carteira: values.carteira }),
      ...(values.variacaoDaCarteira && { variacaoDaCarteira: values.variacaoDaCarteira }),

      // cedente

      ...(values.cedente && { cedente: values.cedente }),
      ...(values.tipoDeInscricao && { tipoDeInscricao: values.tipoDeInscricao }),
      ...(values.inscricaoDoCedente && { inscricaoDoCedente: values.inscricaoDoCedente }),
      ...(values.convenio && { convenio: values.convenio }),
      ...(values.dvConvenio && { dvConvenio: values.dvConvenio }),

      // taxas

      ...(values.tipoValorDoJuro && { tipoValorDoJuro: values.tipoValorDoJuro }),
      ...(values.juro && {
        juro:
          values.juro.toString().indexOf('%') >= 0
            ? values.juro.replace('%', '').replace(',', '.')
            : values.juro.toString().indexOf('$') >= 0
            ? formatarNumeroGeral(values.juro)
            : values.juro,
      }),
      ...(values.tipoValorDaMulta && { tipoValorDaMulta: values.tipoValorDaMulta }),
      ...(values.multa && {
        multa:
          values.multa.toString().indexOf('%') >= 0
            ? values.multa.replace('%', '').replace(',', '.')
            : values.multa.toString().indexOf('$') >= 0
            ? formatarNumeroGeral(values.multa)
            : values.multa,
      }),
      ...(values.encargos && {
        encargos:
          values.encargos.toString().indexOf('$') >= 0
            ? formatarNumeroGeral(values.encargos)
            : values.encargos,
      }),

      ...(values.dataDoDesconto && { dataDoDesconto: values.dataDoDesconto }),
      ...(values.valorDoDesconto && {
        valorDoDesconto:
          values.valorDoDesconto.toString().indexOf('$') >= 0
            ? formatarNumeroGeral(values.valorDoDesconto)
            : values.valorDoDesconto,
      }),

      // remessa

      ...(values.nossoNumeroInicial && {
        nossoNumeroInicial: values.nossoNumeroInicial.toString().replace(/\D+/g, ''),
      }),
      ...(values.nossoNumeroFinal && {
        nossoNumeroFinal: values.nossoNumeroFinal.toString().replace(/\D+/g, ''),
      }),
      ...(values.posto && {
        posto: values.posto,
      }),
      ...(values.prazoDevolucaoTitulo && {
        prazoDevolucaoTitulo: values.prazoDevolucaoTitulo,
      }),
      ...(values.tipoCodificacaoArquivo && {
        tipoCodificacaoArquivo: values.tipoCodificacaoArquivo,
      }),
      ...(values.layoutCNAB && {
        layoutCNAB: values.layoutCNAB,
      }),
      ...(values.codigoTransmissao && { codigoTransmissao: values.codigoTransmissao }),
      ...(values.dataMora && { dataMora: values.dataMora }),
    };
    dispatch(processandoPortador(true));

    await laris.put(`/portadores`, formularioPortador);
    dispatch(processandoPortador(false));
    createSuccessNotification('Portador alterado com sucesso!');
    hashHistory.push('/portadores');
  } catch (err) {
    dispatch(processandoPortador(false));
    sendErroRequest(err, 'Ocorreu um erro ao alterar o Portador!');
  }
};

export const postPortador = (values) => async (dispatch) => {
  try {
    const formularioPortador = {
      // geral
      ...(values.id && { id: values.id }),
      ...(values.descricao && { descricao: values.descricao }),
      ...(values.contaRecursoFinanceiro && {
        contaRecursoFinanceiro: values.contaRecursoFinanceiro,
      }),
      // cobranca
      ...(values.banco && { banco: values.banco }),
      ...(values.sistemaDeCobranca && { sistemaDeCobranca: values.sistemaDeCobranca }),
      ...(values.modeloDeCarteira && { modeloDeCarteira: values.modeloDeCarteira }),
      ...(values.agencia && { agencia: values.agencia }),
      ...(values.dvAgencia && { dvAgencia: values.dvAgencia }),
      ...(values.conta && { conta: values.conta }),
      ...(values.dvConta && { dvConta: values.dvConta }),
      ...(values.carteira && { carteira: values.carteira }),
      ...(values.variacaoDaCarteira && { variacaoDaCarteira: values.variacaoDaCarteira }),

      // cedente

      ...(values.cedente && { cedente: values.cedente }),
      ...(values.tipoDeInscricao && { tipoDeInscricao: values.tipoDeInscricao }),
      ...(values.inscricaoDoCedente && { inscricaoDoCedente: values.inscricaoDoCedente }),
      ...(values.convenio && { convenio: values.convenio }),
      ...(values.dvConvenio && { dvConvenio: values.dvConvenio }),

      // taxas

      ...(values.tipoValorDoJuro && { tipoValorDoJuro: values.tipoValorDoJuro }),
      ...(values.juro && {
        juro:
          values.juro.indexOf('%') >= 0
            ? values.juro.replace('%', '').replace(',', '.')
            : formatarNumeroGeral(values.juro),
      }),
      ...(values.tipoValorDaMulta && { tipoValorDaMulta: values.tipoValorDaMulta }),
      ...(values.multa && {
        multa:
          values.multa.indexOf('%') >= 0
            ? values.multa.replace('%', '').replace(',', '.')
            : formatarNumeroGeral(values.multa),
      }),
      ...(values.encargos && { encargos: formatarNumeroGeral(values.encargos) }),
      ...(values.dataDoDesconto && { dataDoDesconto: values.dataDoDesconto }),
      ...(values.valorDoDesconto && {
        valorDoDesconto: formatarNumeroGeral(values.valorDoDesconto),
      }),

      // remessa

      ...(values.nossoNumeroInicial && {
        nossoNumeroInicial: values.nossoNumeroInicial.replace(/\D+/g, ''),
      }),
      ...(values.nossoNumeroFinal && {
        nossoNumeroFinal: values.nossoNumeroFinal.replace(/\D+/g, ''),
      }),
      ...(values.posto && {
        posto: values.posto,
      }),
      ...(values.prazoDevolucaoTitulo && {
        prazoDevolucaoTitulo: values.prazoDevolucaoTitulo,
      }),
      ...(values.tipoCodificacaoArquivo && {
        tipoCodificacaoArquivo: values.tipoCodificacaoArquivo,
      }),
      ...(values.layoutCNAB && {
        layoutCNAB: values.layoutCNAB,
      }),

      ...(values.codigoTransmissao && { codigoTransmissao: values.codigoTransmissao }),
      ...(values.dataMora && { dataMora: values.dataMora }),
    };
    dispatch(processandoPortador(true));
    await laris.post(`/portadores`, formularioPortador);

    dispatch(processandoPortador(false));

    createSuccessNotification('Portador cadastrado com sucesso!');
    hashHistory.push('/portadores');
  } catch (err) {
    dispatch(processandoPortador(false));
    sendErroRequest(err, 'Ocorreu um erro ao salvar o Portador!');
  }
};

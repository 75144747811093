import { applyMiddleware, createStore, compose } from "redux";
import ReduxThunk from "redux-thunk";

import reducers from "../reducers";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  {},
  composeEnhancer(applyMiddleware(ReduxThunk))
);

export default store;

import * as types from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  unidadesIsentas: [],
  processandoUnidadeIsenta: false,
  unidadesIsentasById: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.UDI_SET_ESTADO_INICIAL:
      return { ...state, processandoUnidadeIsenta: false, unidadesIsentas: [] };
    case types.UDI_GET_UNIDADES_ISENTAS:
      return { ...state, unidadesIsentas: payload };
    case types.UDI_PROCESSANDO_UNIDADE_ISENTA:
      return { ...state, processandoUnidadeIsenta: payload };
    case types.UDI_GET_UNIDADES_ISENTAS_BY_ID:
      return { ...state, unidadesIsentasById: payload };
    default:
      return { ...state };
  }
};

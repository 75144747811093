import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  tabelasPreco: [],
  processandoTabelaPreco: false,
  contasDespesa: [],
  tabelasPrecoById: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.TPC_SET_ESTADO_INICIAL:
      return { ...state, processandoTabelaPreco: false, tabelasPreco: [] };
    case a.TPC_GET_TABELAS_PRECO:
      return { ...state, tabelasPreco: payload };
    case a.TPC_PROCESSANDO_TABELA_PRECO:
      return { ...state, processandoTabelaPreco: payload };
    case a.TPC_GET_CONTAS_DESPESA:
      return { ...state, contasDespesa: payload };
    case a.TPC_GET_TABELAS_PRECO_BY_ID:
      return { ...state, tabelasPrecoById: payload };
    default:
      return { ...state };
  }
};

import InputMask from 'react-input-mask';
import React from 'react';

export const FormInputMask = ({ field, form: { touched, errors }, ...props }) => {
  const { col, label } = props;
  const { value } = field;

  return (
    <div className={`col-lg-${col}`}>
      <div className={`form-group ${errors[field.name] ? 'has-error' : null}`}>
        <label style={{ fontWeight: 'bold' }}>{label}</label>
        <InputMask
          className="form-control"
          maskChar={null}
          {...field}
          {...props}
          value={value || ''}
        />
        {touched[field.name] && errors[field.name] && (
          <span className="help-block m-b-none has-error control-label">{errors[field.name]}</span>
        )}
      </div>
    </div>
  );
};

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  tiposValor: [],
  tiposReceitaDespesa: [],
  tiposConsumo: [],
  tiposLancamento: [],
  tiposRateio: [],
  tiposPatrimonio: [],
  naturezasPatrimonio: [],
  modelosBoleto: [],
  tiposInscricao: [],
  sistemasDeCobranca: [],
  tiposValorJuros: [],
  tiposValorMulta: [],
  modelosCarteira: [],
  descricoesPrevisao: [],
  statusFatura: [],
  statusAcordo: [],
  tipoFatura: [],
  tiposCalculoFundoReserva: [],
  tipoRelatorio: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.ENUM_SET_TIPOS_VALOR:
      return { ...state, tiposValor: payload };
    case a.ENUM_SET_TIPOS_DESPESA_RECEITA:
      return { ...state, tiposReceitaDespesa: payload };
    case a.ENUM_SET_TIPOS_CONSUMO:
      return { ...state, tiposConsumo: payload };
    case a.ENUM_SET_TIPOS_LANCAMENTO:
      return { ...state, tiposLancamento: payload };
    case a.ENUM_SET_TIPOS_RATEIO:
      return { ...state, tiposRateio: payload };
    case a.ENUM_SET_TIPOS_PATRIMONIO:
      return { ...state, tiposPatrimonio: payload };
    case a.ENUM_SET_NATUREZAS_PATRIMONIO:
      return { ...state, naturezasPatrimonio: payload };
    case a.ENUM_SET_MODELOS_BOLETO:
      return { ...state, modelosBoleto: payload };
    case a.ENUM_SET_TIPOS_INSCRICAO:
      return { ...state, tiposInscricao: payload };
    case a.ENUM_SET_SISTEMAS_DE_COBRANCA:
      return { ...state, sistemasDeCobranca: payload };
    case a.ENUM_SET_TIPOS_VALOR_JURO:
      return { ...state, tiposValorJuros: payload };
    case a.ENUM_SET_TIPOS_VALOR_MULTA:
      return { ...state, tiposValorMulta: payload };
    case a.ENUM_SET_MODELOS_CARTEIRA:
      return { ...state, modelosCarteira: payload };
    case a.ENUM_SET_DESCRICAO_PREVISAO_EMISSAO_BOLETO:
      return { ...state, descricoesPrevisao: payload };
    case a.ENUM_SET_STATUS_FATURA:
      return { ...state, statusFatura: payload };
    case a.ENUM_SET_STATUS_ACORDO_SUCCESS:
      return { ...state, statusAcordo: payload };
    case a.ENUM_SET_TIPOS_FATURA:
      return { ...state, tipoFatura: payload };
    case a.ENUM_SET_TIPOS_RELATORIOS:
      return { ...state, tipoRelatorio: payload };
    case a.ENUM_SET_TIPOS_CALCULO_FUNDO_RESERVA:
      return { ...state, tiposCalculoFundoReserva: payload };
    default:
      return { ...state };
  }
};

import * as a from '../actionsTypes/actionTypes';
import {
  createSuccessNotification,
  sendErroRequest,
  createErrorNotification,
  createInfoNotification,
} from '../utils/Notification';
import laris from '../api/laris';

export const processandoNotificacao = bool => ({
  type: a.PTR_PROCESSANDO_NOTIFICACAO,
  payload: bool,
});

export const postNotificacaoCondomino = (values, textEmail, file) => async dispatch => {
  try {
    if (values.unidadeId.length <= 0) createInfoNotification('É necessário selecionar ');
    dispatch(processandoNotificacao(true));

    const anexo = file != null ? file[0] : null;
    const formData = new FormData();
    values.unidadeId.forEach(u => formData.append('unidades', u.value));
    formData.append('assunto', values.assunto);
    formData.append('mensagem', textEmail);
    formData.append('file', anexo);

    const { data } = await laris.post('/protocolos/notificacoes-condomino', formData);
    if (data.result === true) createSuccessNotification('Notificações enviadas com sucesso!');
    else createErrorNotification('Não foi possível enviar notificações aos condôminos, verifique se em Configuração->Parâmetro esta preenchido corretamente!');
    dispatch(processandoNotificacao(false));
  } catch (error) {
    sendErroRequest(error, 'Erro ao notificar os Condôminos!');
    dispatch(processandoNotificacao(false));
  }
};

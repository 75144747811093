import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import Grid from './gridLeituraDeConsumo';
import FiltroLeituraConsumo from './filtroLeituraDeConsumo';
import ImportacaoLeituraConsumo from './importacaoLeitura';
import { guidEmpty } from '../../utils/Functions';
import Spin from '../../utils/spin';
import HeaderGrid from '../../theme/headerGrid';
import PageHeader from '../../theme/header';
import {
  getTiposConsumo,
  getLeiturasDeConsumo,
  deleteLeituraDeConsumo,
  limparListaLeituraConsumo,
  getUnidades,
} from '../../actions';

export default function LeituraConsumo() {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(getTiposConsumo());
    dispatch(getUnidades());
    dispatch(limparListaLeituraConsumo());
    return () => {
      dispatch(limparListaLeituraConsumo());
    };
  }, []);

  const data = useSelector(state => state);

  const arrayTiposConsumo = data.enumerador.tiposConsumo.map(item => {
    return {
      value: item.id,
      label: item.descricao,
    };
  });

  const todasUnidades = { value: guidEmpty, label: 'Todos' };
  const arrayUnidades = data.unidade.unidades.map(u => {
    return {
      value: u.id,
      label: u.unidadeCondomino,
    };
  });
  function handleModal() {
    setVisible(!visible);
  }

  arrayUnidades.unshift(todasUnidades);
  return (
    <div>
      <PageHeader title="Leitura De Consumo">
        <button
          onClick={() => handleModal()}
          type="button"
          className="btn btn-sm btn-primary"
          style={{ marginLeft: 5, marginRight: 4 }}
        >
          {' '}
          Importação Automática
        </button>
        <Link
          to="/leituras-de-consumo/adicionar"
          style={{ marginRight: 4 }}
          className="btn btn-sm btn-primary"
        >
          Adicionar
        </Link>
        <Link to="/home" className="btn btn-warning btn-sm">
          Voltar
        </Link>
      </PageHeader>
      <Dialog
        header="Importar Leituras de Consumo"
        visible={visible}
        style={{
          width: '35vw',
        }}
        dismissableMask
        onHide={() => setVisible(false)}
      >
        <div style={{ textAlign: 'center', marginBottom: '15px' }}>
          <ImportacaoLeituraConsumo
            visible={() => handleModal()}
            loading={data.leituraConsumo.processandoLeituraDeConsumo}
          />
        </div>
      </Dialog>
      <div className="wrapper wrapper-content animated fadeIn">
        <div className="row">
          <div className="col-md-12">
            <div className="ibox">
              <div className="ibox-content">
                <FiltroLeituraConsumo
                  tiposConsumo={arrayTiposConsumo}
                  unidades={arrayUnidades}
                  onSubmit={values => dispatch(getLeiturasDeConsumo(values))}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <HeaderGrid
              title="Leituras De Consumo"
              helperLink="http://kb.tron.com.br/artigos/laris/leituras-de-consumo/490"
            />
            <Spin loading={data.leituraConsumo.processandoLeituraDeConsumo}>
              <Grid
                remove={(values, dataSource) => {
                  dispatch(deleteLeituraDeConsumo(values, dataSource));
                }}
                dataSource={data.leituraConsumo.leiturasDeConsumo}
              />
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
}

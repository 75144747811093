import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  previsoes: [],
  previsoesSelecionadas: [],
  processandoPrevisao: false,
  itensPrevisao: [],
  previsaoDaImportacao: [],
  showModalItemPrevisao: {
    show: false,
    conteudo: '',
  },
  showModalPrevisaoParcelamento: false,
  previsaoEdit: {},
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.PRV_SET_ESTADO_INICIAL:
      return {
        ...state,
        previsoes: [],
        processandoPrevisao: false,
        itensPrevisao: [],
        showModalItemPrevisao: {
          show: false,
          conteudo: '',
          previsoesSelecionadas: [],
        },
        previsaoDaImportacao: [],
        showModalPrevisaoParcelamento: false,
        previsaoEdit: {},
      };
    case a.PRV_GET_PREVISOES:
      return { ...state, previsoes: payload };
    case a.PRV_GET_PREVISOES_CHECKED:
      return { ...state, previsoesSelecionadas: payload };
    case a.PRV_GET_PREVISOES_IMPORTACAO:
      return { ...state, previsaoDaImportacao: payload };
    case a.PRV_PROCESSANDO_PREVISAO:
      return { ...state, processandoPrevisao: payload };
    case a.PRV_SET_ITENS_PREVISAO:
      return { ...state, itensPrevisao: payload };
    case a.PRV_SET_PREVISAO_EDIT:
      return { ...state, previsaoEdit: payload };
    case a.PRV_SHOW_MODAL_MEMORIA_CALCULO_ITEM_PREVISAO:
      return { ...state, showModalItemPrevisao: payload };
    case a.PRV_SHOW_MODAL_GERAR_PREVISAO_PARCELAMENTO:
      return { ...state, showModalPrevisaoParcelamento: payload };
    default:
      return { ...state };
  }
};

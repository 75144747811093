import { lazy } from 'react';

// const Empresa = lazy(() => import('../components/Empresa'));

const NaoAutorizado = lazy(() => import('../components/NaoEncontrada'));
const Dashboard = lazy(() => import('../views/dashboard'));

// Condomino
const Condomino = lazy(() => import('../views/condomino'));
const CondominoAdicionar = lazy(() => import('../views/condomino/adicionar'));
const CondominoEditar = lazy(() => import('../views/condomino/editar'));

// Conta
const Conta = lazy(() => import('../views/conta'));
const AdicionarConta = lazy(() => import('../views/conta/adicionar'));
const EditarConta = lazy(() => import('../views/conta/editar'));

// escritorioCobranca
const EscritorioCobranca = lazy(() => import('../views/escritorioCobranca'));
const AdicionarEscritorioCobranca = lazy(() => import('../views/escritorioCobranca/adicionar'));
const EditarEscritorioCobranca = lazy(() => import('../views/escritorioCobranca/editar'));

// historico
const Historico = lazy(() => import('../views/historico'));
const HistoricoAdicionar = lazy(() => import('../views/historico/adicionar'));
const HistoricoEditar = lazy(() => import('../views/historico/editar'));

// InstrucaoBancaria
const InstrucaoBancaria = lazy(() => import('../views/instrucaoBancaria'));
const InstrucaoBancariaAdicionar = lazy(() => import('../views/instrucaoBancaria/adicionar'));
const InstrucaoBancariaEditar = lazy(() => import('../views/instrucaoBancaria/editar'));

//Indexadores
const Indexadores = lazy(() => import('../views/indexadores'));
const IndexadoresAdicionar = lazy(() => import('../views/indexadores/adicionar'));
const IndexadoresEditar = lazy(() => import('../views/indexadores/editar'));

// locais
const Locais = lazy(() => import('../views/locais'));
const LocaisAdicionar = lazy(() => import('../views/locais/adicionar'));
const LocaisEditar = lazy(() => import('../views/locais/editar'));

// patrimonios
const Patrimonios = lazy(() => import('../views/patrimonio'));
const PatrimoniosAdicionar = lazy(() => import('../views/patrimonio/adicionar'));
const PatrimoniosEditar = lazy(() => import('../views/patrimonio/editar'));

// portadores
const Portadores = lazy(() => import('../views/portador'));
const PortadoresAdicionar = lazy(() => import('../views/portador/adicionar'));
const PortadoresEditar = lazy(() => import('../views/portador/editar'));

// rateios
const Rateios = lazy(() => import('../views/rateio'));
const RateiosAdicionar = lazy(() => import('../views/rateio/adicionar'));
const RateiosEditar = lazy(() => import('../views/rateio/editar'));

// Unidades Centrais
const UnidadesCentrais = lazy(() => import('../views/unidadeCentral'));
const UnidadesCentraisAdicionar = lazy(() => import('../views/unidadeCentral/adicionar'));
const UnidadesCentraisEditar = lazy(() => import('../views/unidadeCentral/editar'));

// Sindicos
const Sindicos = lazy(() => import('../views/sindico'));
const SindicosAdicionar = lazy(() => import('../views/sindico/adicionar'));
const SindicosEditar = lazy(() => import('../views/sindico/editar'));

// Unidades Isentas
const UnidadesIsentas = lazy(() => import('../views/unidadeIsenta'));
const UnidadeIsentaAdicionar = lazy(() => import('../views/unidadeIsenta/adicionar'));
const UnidadeIsentaEditar = lazy(() => import('../views/unidadeIsenta/editar'));

// tabelas-preco
const TabelaPreco = lazy(() => import('../views/tabelaPreco'));
const TabelaPrecoAdicionar = lazy(() => import('../views/tabelaPreco/adicionar'));
const TabelaPrecoEditar = lazy(() => import('../views/tabelaPreco/editar'));

// taxas
const Taxas = lazy(() => import('../views/taxa'));
const TaxasAdicionar = lazy(() => import('../views/taxa/adicionar'));
const TaxasEditar = lazy(() => import('../views/taxa/editar'));

// Auditoria de Faturas Baixadas
const AuditoriaFaturasBaixadas = lazy(() => import('../views/auditoriaFaturasBaixadas'));

// Auditoria de Faturas Abertas
const AuditoriaFaturasAberto = lazy(() => import('../views/auditoriaFaturasAberto'));

// unidades
const Unidades = lazy(() => import('../views/unidade'));
const UnidadesAdicionar = lazy(() => import('../views/unidade/adicionar'));
const UnidadesEditar = lazy(() => import('../views/unidade/editar'));

// relatorios
const RelDeclacaoQuitacaoCondominal = lazy(() => import('../views/declaracaoQuitacaoCondominal'));
const RelRecebimentosCondomino = lazy(() => import('../views/recebimentoCondomino'));
const RelDemonstrativoFinanceiroAnalitico = lazy(() =>
  import('../views/demonstrativoFinanceiroAnalitico'),
);
const RelPrevisaoUnidades = lazy(() => import('../views/previsaounidades'));
const RelReciboFaturas = lazy(() => import('../views/reciboFaturas'));
const RelRecebimentoTaxa = lazy(() => import('../views/recebimentoTaxas'));
const RelBoletoGerado = lazy(() => import('../views/relacaoBoletoGerado'));
const RelMovimentacaoFinanceira = lazy(() => import('../views/movimentacaoFinanceira'));
const RelFaturaAcordadas = lazy(() => import('../views/faturasAcordadas'));
const RelEmissaoBoletos = lazy(() => import('../views/emissaoBoleto'));
const RelEvolucaoInadimplencia = lazy(() => import('../views/evolucaoInadimplencia'));
const RelCobranca = lazy(() => import('../views/cobranca'));
const RelBalanceteSintetico = lazy(() => import('../views/balanceteSintetico'));
const RelUnidades = lazy(() => import('../views/relatorioUnidades'));
const RelPatrimonio = lazy(() => import('../views/relatorioPatrimonio'));
const RelLeituraConsumo = lazy(() => import('../views/relatorioLeituraConsumo'));
const RelProtocoloRepassePatrimonio = lazy(() => import('../views/protocoloRepassePatrimonio'));
const RelProtocoloEntregaTaxas = lazy(() => import('../views/protocoloEntregaTaxas'));
const RelListagemCondominos = lazy(() => import('../views/listagemCondominos'));
const RelListaPresenca = lazy(() => import('../views/listaPresenca'));
const RelDemonstrativoSaldoConta = lazy(() => import('../views/demonstrativoSaldoConta'));
const RelExtratoContas = lazy(() => import('../views/extratoContas'));
const RelDemonstrativoDespesas = lazy(() => import('../views/demonstrativoDeDespesas'));
const RelDemonstrativoDespesaRateada = lazy(() =>
  import('../views/demonstrativoDeDespesasRateada'),
);
const RelRateioContas = lazy(() => import('../views/rateioContas'));
const RelBorderoArrecadacao = lazy(() => import('../views/borderoArrecadacao'));
const RelContasRateadas = lazy(() => import('../views/contasRateadas'));
const RelEspelhoLeiturasConsumo = lazy(() => import('../views/espelhoLeiturasConsumo'));
const RelFaturasEnviadasEmail = lazy(() => import('../views/faturasEnviadasPorEmail'));
const RelFichaCondominio = lazy(() => import('../views/fichaCondominio'));
const RelLancamentoDespesasReceitas = lazy(() => import('../views/lancamentoDespesasReceitas'));
const RelInadimplencia = lazy(() => import('../views/inadimplencia'));
const RelAnaliseReceitas = lazy(() => import('../views/analiseReceitas'));

// movimentacao
const ChecagemGeralSaldos = lazy(() => import('../views/checagemGeralSaldos'));

const FechamentoPeriodo = lazy(() => import('../views/fechamentoPeriodo'));
const LeituraConsumo = lazy(() => import('../views/leituraDeConsumo'));
const LeituraConsumoAdicionar = lazy(() => import('../views/leituraDeConsumo/adicionar'));
const Lancamentos = lazy(() => import('../views/lancamento'));
const LancamentosAdicionar = lazy(() => import('../views/lancamento/adicionar'));
const LancamentosEditar = lazy(() => import('../views/lancamento/editar'));
const TaxaAtrasoAvulsa = lazy(() => import('../views/taxaAtrasoAvulsa'));
const GerarFaturas = lazy(() => import('../views/gerarFaturas'));
const LancamentoPrevisao = lazy(() => import('../views/efetuarLancamentosPrevisoes'));
const Acordos = lazy(() => import('../views/acordo'));
const DemonstrativoTaxaFixa = lazy(() => import('../views/demonstrativoTaxaFixa'));
const DemonstrativoTaxaFixaAdicionar = lazy(() =>
  import('../views/demonstrativoTaxaFixa/adicionar'),
);
const DemonstrativoTaxaFixaEditar = lazy(() => import('../views/demonstrativoTaxaFixa/editar'));

const AcordosAdicionar = lazy(() => import('../views/acordo/adicionar'));
const AcordosEditar = lazy(() => import('../views/acordo/editar'));
// remessas
const Remessas = lazy(() => import('../views/remessa'));
const GerarRemessas = lazy(() => import('../views/remessa/gerarRemessa'));
const ConsultaBaixaFatura = lazy(() => import('../views/consultaBaixaFaturas'));
const Parcelamentos = lazy(() => import('../views/parcelamento'));
const ParcelamentoAdicionar = lazy(() => import('../views/parcelamento/adicionar'));
const ParcelamentoEditar = lazy(() => import('../views/parcelamento/editar'));
const ParcelamentoAdiantar = lazy(() => import('../views/parcelamento/adiantarParcelamento'));
const PrevisaoIndex = lazy(() => import('../views/previsao'));
const PrevisaoAdicionar = lazy(() => import('../views/previsao/adicionar'));
const PrevisaoEditar = lazy(() => import('../views/previsao/editar'));
const ContasContraPartida = lazy(() => import('../views/contaContaPartida/index'));
const ContasContraPartidaAdicionar = lazy(() => import('../views/contaContaPartida/adicionar'));
const ContasContraPartidaEditar = lazy(() => import('../views/contaContaPartida/editar'));
const ContasRecursoFinanceiro = lazy(() => import('../views/contaRecursoFinanceiro'));
const Parametros = lazy(() => import('../views/parametro'));
const GerenciadorRelatorios = lazy(() => import('../views/gerenciadorRelatorio'));

// retorno bancário
const RetornoBancario = lazy(() => import('../views/retornoBancario'));
const RelatorioAcordos = lazy(() => import('../views/relatorioAcordo'));
const InteAssembelia = lazy(() => import('../views/assembleia'));

// Conciliação de contas
const ConciliacaoContas = lazy(() => import('../views/conciliacaoContas'));

const NotificacaoCondomino = lazy(() => import('../views/notificacaoCondomino'));

//Observacoes
const Observacoes = lazy(() => import('../views/observacao'));
const ObservacoesAdicionar = lazy(() => import('../views/observacao/adicionar'));
const ObservacoesEditar = lazy(() => import('../views/observacao/editar'));

//passo a passo
const AjudaCadastro = lazy(() => import('../views/ajudaCadastro'));
const AjudaMovimentacao = lazy(() => import('../views/ajudaMovimentacao'));
const AjudaRelatorio = lazy(() => import('../views/ajudaRelatorio'));

//Logs
const Logs = lazy(() => import('../views/logs'));

const RouteComponents = {
  '/acesso-nao-autorizado': NaoAutorizado,
  '/dashboards': Dashboard,
  '/': Dashboard,
  '/home': Dashboard,
  '/home/:token/:tenant': Dashboard,

  // condominio
  '/condominos': Condomino,
  '/condominos/adicionar': CondominoAdicionar,
  '/condominos/editar/:id': CondominoEditar,

  // conta
  '/contas': Conta,
  '/contas/adicionar': AdicionarConta,
  '/contas/editar/:id': EditarConta,

  // cobranca
  '/escritorios-cobranca': EscritorioCobranca,
  '/escritorios-cobranca/adicionar': AdicionarEscritorioCobranca,
  '/escritorios-cobranca/editar/:id': EditarEscritorioCobranca,

  // historicos
  '/historicos': Historico,
  '/historicos/editar/:id': HistoricoEditar,
  '/historicos/adicionar': HistoricoAdicionar,

  // instrucaoBancaria
  '/instrucoes-bancarias': InstrucaoBancaria,
  '/instrucoes-bancarias/adicionar': InstrucaoBancariaAdicionar,
  '/instrucoes-bancarias/editar/:id': InstrucaoBancariaEditar,

  //Indexadores
  '/indexadores': Indexadores,
  '/indexadores/adicionar': IndexadoresAdicionar,
  '/indexadores/editar/:id': IndexadoresEditar,

  // locais
  '/locais': Locais,
  '/locais/adicionar': LocaisAdicionar,
  '/locais/editar/:id': LocaisEditar,

  // patrimonios
  '/patrimonios': Patrimonios,
  '/patrimonios/adicionar': PatrimoniosAdicionar,
  '/patrimonios/editar/:id': PatrimoniosEditar,

  // Portadores
  '/portadores': Portadores,
  '/portadores/adicionar': PortadoresAdicionar,
  '/portadores/editar/:id': PortadoresEditar,

  // Rateio
  '/rateios': Rateios,
  '/rateios/adicionar': RateiosAdicionar,
  '/rateios/editar/:id': RateiosEditar,

  // Unidade central
  '/unidades-centrais': UnidadesCentrais,
  '/unidades-centrais/adicionar': UnidadesCentraisAdicionar,
  '/unidades-centrais/editar/:id': UnidadesCentraisEditar,

  // sindicos
  '/sindicos': Sindicos,
  '/sindicos/adicionar': SindicosAdicionar,
  '/sindicos/editar/:id': SindicosEditar,
  // Unidade Isenta
  '/unidades-isentas': UnidadesIsentas,
  '/unidades-isentas/adicionar': UnidadeIsentaAdicionar,
  '/unidades-isentas/editar/:id': UnidadeIsentaEditar,

  // tabelas-preco
  '/tabelas-preco': TabelaPreco,
  '/tabelas-preco/adicionar': TabelaPrecoAdicionar,
  '/tabelas-preco/editar/:id': TabelaPrecoEditar,

  // taxas
  '/taxas': Taxas,
  '/taxas/adicionar': TaxasAdicionar,
  '/taxas/editar/:id': TaxasEditar,

  // unidades
  '/unidades': Unidades,
  '/unidades/adicionar': UnidadesAdicionar,
  '/unidades/editar/:id': UnidadesEditar,

  // Observacoes
  '/observacoes': Observacoes,
  '/observacoes/adicionar': ObservacoesAdicionar,
  '/observacoes/editar/:id': ObservacoesEditar,

  // movimentacao
  '/auditoria-faturas-baixadas': AuditoriaFaturasBaixadas,
  '/auditoria-faturas-em-aberto': AuditoriaFaturasAberto,
  '/checagem-saldos': ChecagemGeralSaldos,
  '/fechamento-periodo': FechamentoPeriodo,
  '/leituras-de-consumo': LeituraConsumo,
  '/leituras-de-consumo/adicionar': LeituraConsumoAdicionar,
  '/lancamentos': Lancamentos,
  '/lancamentos/adicionar': LancamentosAdicionar,
  '/lancamentos/editar/:id': LancamentosEditar,
  '/taxa-atraso-avulsa': TaxaAtrasoAvulsa,
  '/gerar-faturas': GerarFaturas,
  '/lancamentos-previsao': LancamentoPrevisao,
  '/acordos': Acordos,
  '/demonstrativos-taxa-fixa': DemonstrativoTaxaFixa,
  '/demonstrativos-taxa-fixa/adicionar': DemonstrativoTaxaFixaAdicionar,
  '/demonstrativos-taxa-fixa/editar/:id': DemonstrativoTaxaFixaEditar,
  '/acordos/adicionar': AcordosAdicionar,
  '/acordos/editar/:id': AcordosEditar,
  '/consulta-baixa-faturas': ConsultaBaixaFatura,
  '/parcelamentos': Parcelamentos,
  '/parcelamentos/adicionar': ParcelamentoAdicionar,
  '/parcelamentos/visualizar/:id': ParcelamentoEditar,
  '/parcelamentos/adiantar-parcela/:id': ParcelamentoAdiantar,
  '/previsoes': PrevisaoIndex,
  '/previsoes/adicionar': PrevisaoAdicionar,
  '/previsoes/editar/:id': PrevisaoEditar,
  '/contas-contra-partida': ContasContraPartida,
  '/contas-contra-partida/adicionar': ContasContraPartidaAdicionar,
  '/contas-contra-partida/editar/:idConta': ContasContraPartidaEditar,
  '/contas-recurso-financeiro': ContasRecursoFinanceiro,
  '/parametros': Parametros,
  '/gerenciador-relatorios': GerenciadorRelatorios,
  // relatorios
  '/declaracao-quitacao-condominial': RelDeclacaoQuitacaoCondominal,
  '/relacao-recebimentos-condominos': RelRecebimentosCondomino,
  '/demonstrativo-financeiro-analitico': RelDemonstrativoFinanceiroAnalitico,
  '/previsao-unidades': RelPrevisaoUnidades,
  '/recibo-faturas': RelReciboFaturas,
  '/recebimentos-taxas': RelRecebimentoTaxa,
  '/relacao-boletos-gerados': RelBoletoGerado,
  '/movimentacao-financeira': RelMovimentacaoFinanceira,
  '/faturas-acordadas': RelFaturaAcordadas,
  '/emissao-boletos': RelEmissaoBoletos,
  '/evolucao-inadimplencia': RelEvolucaoInadimplencia,
  '/cobranca': RelCobranca,
  '/balancete-sintetico': RelBalanceteSintetico,
  '/relatorio-unidades': RelUnidades,
  '/relatorio-patrimonio': RelPatrimonio,
  '/relatorio-leituras-consumo': RelLeituraConsumo,
  '/protocolo-repasse-patrimonio': RelProtocoloRepassePatrimonio,
  '/protocolo-entregas-taxas': RelProtocoloEntregaTaxas,
  '/listagem-condominos': RelListagemCondominos,
  '/lista-presenca': RelListaPresenca,
  '/demonstrativo-saldo-conta': RelDemonstrativoSaldoConta,
  '/inadimplencia': RelInadimplencia,

  '/extrato-contas': RelExtratoContas,
  '/demonstrativo-despesas': RelDemonstrativoDespesas,
  '/demonstrativo-despesa-rateada': RelDemonstrativoDespesaRateada,
  '/rateio-contas': RelRateioContas,
  '/bordero-arrecadacao': RelBorderoArrecadacao,
  '/contas-rateadas': RelContasRateadas,
  '/espelho-leitura-consumo': RelEspelhoLeiturasConsumo,
  '/faturas-enviadas-email': RelFaturasEnviadasEmail,
  '/ficha-condominio': RelFichaCondominio,
  '/lancamentos-despesas-receitas': RelLancamentoDespesasReceitas,
  '/relatorio-acordo': RelatorioAcordos,
  '/analise-receitas': RelAnaliseReceitas,

  // remessa
  '/remessas': Remessas,
  '/remessas/gerarRemessa': GerarRemessas,

  // retorno bancário
  '/retornos': RetornoBancario,
  '/assembleia': InteAssembelia,

  // ConciliacaoContas
  '/conciliacao-contas': ConciliacaoContas,
  // comunicacao
  '/notificacao-condomino': NotificacaoCondomino,

  // passo a passo
  '/passo-a-passo-cadastros': AjudaCadastro,
  '/passo-a-passo-movimentacao': AjudaMovimentacao,
  '/passo-a-passo-relatorios': AjudaRelatorio,

  //Logs
  '/logs': Logs,
};

export default RouteComponents;

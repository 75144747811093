import { TextBox } from 'devextreme-react';
import React from 'react';

export const FormInputButton = ({
  field,
  onClick,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const { col, label, labelbutton, onChange, onValueChanged } = props;
  return (
    <div className={`col-lg-${col}`}>
      <div className={`form-group ${errors[field.name] ? 'has-error' : null}`}>
        <label style={{ fontWeight: 'bold' }}>{label}</label>
        <div className="input-group">
          <TextBox
            className="form-control"
            // style={{ border: `${errors[field.name] ? '1px solid #ed5565' : 'null'}` }}
            {...field}
            {...props}
            onValueChanged={option => {
              setFieldValue(field.name, option ? option.value : '');
              onChange && onChange(option);
              onValueChanged && onValueChanged(option);
            }}
          />
          <span className="input-group-append">
            <button
              style={{ zIndex: 'auto' }}
              type="button"
              className="btn btn-primary"
              onClick={onClick}
            >
              {labelbutton}
            </button>
          </span>
          {touched[field.name] && errors[field.name] && (
            <span className="help-block m-b-none">{errors[field.name]}</span>
          )}
        </div>
      </div>
    </div>
  );
};

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
    analiseReceitas: [],
    processandoAnaliseReceitas: false,
};

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case a.LDC_SET_ESTADO_INICIAL:
            return { ...state, processandoAnaliseReceitas: false, analiseReceitas: [] };
        case a.LDC_GET_ANALISE_RECEITAS:
            return { ...state, analiseReceitas: payload };
        case a.LDC_PROCESSANDO_ANALISE_RECEITAS:
            return { ...state, processandoAnaliseReceitas: payload };
        default:
            return { ...state };
    }
};

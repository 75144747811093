export const required = value => (value ? undefined : 'Campo requerido.');
export const cpf = value => (value && validaCpf(value) ? undefined : 'CPF Inválido.');
export const cnpj = value => (value && validaCnpj(value) ? undefined : 'CNPJ Inválido.');
export const number = value =>
  value && isNaN(Number(value)) ? 'O campo aceita somente números.' : undefined;
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Email inválido' : undefined;

export const cpfCnpj = value => {
  if (value) {
    value = value.replace(/[^0-9]/g, '');

    if (value.length == 14) {
      if (validaCnpj(value)) {
        return undefined;
      } else {
        return 'Inscrição inválida.';
      }
    } else if (value.length == 11) {
      if (validaCpf(value)) {
        return undefined;
      } else {
        return 'Inscrição inválida.';
      }
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const validaEmail = email => {
  if (!email) return false;
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export function validaCnpj(values) {
  var cnpj = values.replace(/[^\d]+/g, '');
  var valida = new Array(6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2);
  var dig1 = new Number();
  var dig2 = new Number();

  var exp = /\.|\-|\//g;
  cnpj = cnpj.toString().replace(exp, '');
  var digito = new Number(eval(cnpj.charAt(12) + cnpj.charAt(13)));

  for (var i = 0; i < valida.length; i++) {
    dig1 += i > 0 ? cnpj.charAt(i - 1) * valida[i] : 0;
    dig2 += cnpj.charAt(i) * valida[i];
  }
  dig1 = dig1 % 11 < 2 ? 0 : 11 - (dig1 % 11);
  dig2 = dig2 % 11 < 2 ? 0 : 11 - (dig2 % 11);

  if (dig1 * 10 + dig2 != digito) {
    return false;
  }
  return true;
}

export function validaCpf(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf == '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length != 11 ||
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(10))) return false;
  return true;
}
export function RemoveCaracteres(values) {
  if (values) {
    const texto = values.replace(/[^\d]+/g, '');
    return texto;
  }
}

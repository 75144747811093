import axios from 'axios';
import * as a from '../actionsTypes/actionTypes';
import { sendErroRequest } from '../utils/Notification';

export const setEstados = response => ({
  type: a.COMUM_GET_ESTADOS,
  payload: response,
});

export const setCidades = response => ({
  type: a.COMUM_GET_CIDADES,
  payload: response,
});

export const getEstados = () => async dispatch => {
  try {
    const { data } = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');

    dispatch(setEstados(data));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar os estados!');
  }
};

export const getCidadeByIdEstado = id => async dispatch => {
  try {
    if (id) {
      const { data } = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id}/municipios`,
      );

      dispatch(setCidades(data));
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar as cidades do estado selecionado!');
  }
};

export async function getEstadosImportacao() {
  try {
    const { data } = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
    const estados = data.map(e => {
      return {
        sigla: e.sigla,
        nome: e.nome,
        id: e.id,
      };
    });
    return estados;
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar os estados!');
    return undefined;
  }
}

export async function getCidadeByIdEstadoImportacao(id) {
  try {
    if (id) {
      const { data } = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id}/municipios`,
      );

      const cidades = data.map(c => {
        return {
          id: c.id,
          nome: c.nome,
          uf: {
            id: c.microrregiao.mesorregiao.UF.id,
            sigla: c.microrregiao.mesorregiao.UF.sigla,
            nome: c.microrregiao.mesorregiao.UF.nome,
          },
        };
      });
      return cidades;
    }
    return undefined;
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar as cidades do estado selecionado!');
    return undefined;
  }
}

export const getDadosCep = values => async dispatch => {
  try {
    if (values) {
      const cep = values.replace(/[^0-9]/g, '');

      const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      if (data.erro) {
        return sendErroRequest(null, 'Não foi possível encontrar o endereço pelo cep informado.');
      }
      return data;
    }
  } catch (error) {
    sendErroRequest(error, 'Não foi possível encontrar o endereço pelo cep informado.');
  }
};

import Swal from 'sweetalert2';
import moment from 'moment';
import * as a from '../actionsTypes/actionTypes';
import { createSuccessNotification, sendErroRequest } from '../utils/Notification';

const Toast = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 3000,
  // timerProgressBar: true,
  // onOpen: toast => {
  //   toast.addEventListener('mouseenter', Swal.stopTimer);
  //   toast.addEventListener('mouseleave', Swal.resumeTimer);
  // },
});

export const processandoRelatorio = bool => dispatch =>
  dispatch({ type: a.REL_PROCESSANDO_RELATORIO, payload: bool });

export const aguardandoRelatorio = bool => dispatch =>
  dispatch({ type: a.REL_AGUARDANDO_RELATORIO, payload: bool });

export const setListaRelatorios = lista => dispatch =>
  dispatch({ type: a.REL_SET_RELATORIOS, payload: lista });

export const carregarRelatorios = () => dispatch => {
  try {
    dispatch(processandoRelatorio(true));
    const relatorios = sessionStorage.getItem('messages')
      ? JSON.parse(sessionStorage.getItem('messages'))
      : [];

    relatorios.forEach(
      item => (item.dataEmissao = moment(item.dataEmissao).format('DD MM YYYY, h:mm:ss a')),
    );

    dispatch({ type: a.REL_SET_ESTADO_INICIAL, payload: relatorios });

    setTimeout(() => dispatch(processandoRelatorio(false)), 500);
  } catch (err) {
    dispatch(processandoRelatorio(false));
    sendErroRequest(err, 'Ocorreu um erro ao carregar os Relatórios!');
  }
};

export const limparListaRelatorio = () => dispatch => {
  try {
    dispatch(processandoRelatorio(true));
    sessionStorage.setItem('messages', JSON.stringify([]));
    dispatch(setListaRelatorios([]));

    setTimeout(() => dispatch(processandoRelatorio(false)), 500);
  } catch (err) {
    dispatch(processandoRelatorio(false));
    sendErroRequest(err, 'Ocorreu um erro ao limpar lista de relatórios !');
  }
};

export const adicionarItemRelatorio = relatorio => dispatch => {
  try {
    dispatch(processandoRelatorio(true));

    const messages = JSON.parse(sessionStorage.getItem('messages')) || [];
    const novaLista = [...messages, ...[relatorio]];

    sessionStorage.setItem('messages', JSON.stringify(novaLista));

    novaLista.forEach(
      item => (item.dataEmissao = moment(item.dataEmissao).format('DD MM YYYY, h:mm:ss a')),
    );

    dispatch(setListaRelatorios(novaLista));
    dispatch(processandoRelatorio(false));

    Toast.fire({
      icon: 'success',
      title: `<a href="#/gerenciador-relatorios">${relatorio.nome}</a>`,
    });
  } catch (err) {
    dispatch(processandoRelatorio(false));
    sendErroRequest(err, 'Ocorreu um erro ao adicionar uma lista de relatórios !');
  }
};

export const adicionarAguardandoRelatorio = () => dispatch => {
  try {
    Toast.fire({
      icon: 'warning',
      title: `<a href="#/gerenciador-relatorios">Processando...</a>`,
    });
  } catch (err) {
    dispatch(aguardandoRelatorio(false));
    sendErroRequest(err, 'Ocorreu um erro ao carregar uma lista de relatórios !');
  }
};

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  escritoriosCobranca: [],
  processandoEscritorioCobranca: false,
  escritorioCobrancaById: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case a.ECB_SET_ESTADO_INICIAL:
      return { ...state, processandoEscritorioCobranca: false, escritoriosCobranca: [] };
    case a.ECB_GET_ESCRITORIOS_COBRANCA:
      return { ...state, escritoriosCobranca: payload };
    case a.ECB_PROCESSANDO_ESCRITORIO_COBRANCA:
      return { ...state, processandoEscritorioCobranca: payload };
    case a.ECB_GET_ESCRITORIOS_COBRANCA_BY_ID:
      return { ...state, escritorioCobrancaById: payload };
    default:
      return { ...state };
  }
};

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  fechamentosPeriodo: [],
  processandoFechamentoPeriodo: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.FCP_SET_ESTADO_INICIAL:
      return { ...state, processandoAcordo: false, acordos: [] };
    case a.FCP_GET_FECHAMENTOS_PERIODO:
      return { ...state, fechamentosPeriodo: payload };
    case a.FCP_PROCESSANDO_FECHAMENTO_PERIODO:
      return { ...state, processandoFechamentoPeriodo: payload };
    default:
      return { ...state };
  }
};

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  instrucoesBancarias: [],
  processandoInstrucaoBancaria: false,
  instrucaoBancariaById: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.IBC_SET_ESTADO_INICIAL:
      return { ...state, processandoInstrucaoBancaria: false, instrucoesBancarias: [] };
    case a.IBC_GET_INSTRUCOES_BANCARIAS:
      return { ...state, instrucoesBancarias: payload };
    case a.IBC_PROCESSANDO_INSTRUCAO_BANCARIA:
      return { ...state, processandoInstrucaoBancaria: payload };
    case a.IBC_GET_INSTRUCOES_BANCARIAS_BY_ID:
      return { ...state, instrucaoBancariaById: payload };
    default:
      return { ...state };
  }
};

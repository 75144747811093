import React from 'react';
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  ColumnChooser,
} from 'devextreme-react/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { ExportLeituraConsumo } from '../../actions';

export default function Grid({ dataSource, remove }) {
  const dispatch = useDispatch();
  const dataLeitura = useSelector(state => state.leituraConsumo);

  const dataSourceGrid = dataSource.map(item => ({
    ...item,
    leituraAtualFormatada: parseFloat(item.leituraAtual.toFixed(3)).toLocaleString('pt-BR', {
      currency: 'BRL',
    }),
    leituraAnteriorFormatada: parseFloat(item.leituraAnterior.toFixed(3)).toLocaleString('pt-BR', {
      currency: 'BRL',
    }),
    consumoFormatado: parseFloat(item.consumo.toFixed(3)).toLocaleString('pt-BR', {
      currency: 'BRL',
    }),
  }));

  function acoes(data) {
    return (
      <button
        type="button"
        className="btn btn-danger btn-sm"
        style={{ marginRight: 10 }}
        onClick={() => {
          remove(data.row.key.id, dataSourceGrid);
        }}
      >
        <i className="fa fa-remove" />
      </button>
    );
  }
  function handleExportacao() {
    dispatch(ExportLeituraConsumo(dataLeitura.leiturasDeConsumo));
  }
  return (
    <>
      <div className="row" style={{ marginBottom: '15px' }}>
        <div className="col-md-12">
          <div className="form-group">
            <button
              onClick={() => handleExportacao()}
              type="button"
              className="btn btn-sm btn-primary pull-right"
              style={{ marginLeft: 5, marginRight: 4 }}
              disabled={
                dataLeitura.leiturasDeConsumo === null || dataLeitura.leiturasDeConsumo.length <= 0
              }
            >
              {' '}
              Exportar Leituras de Consumo{' '}
            </button>
          </div>
        </div>
      </div>
      <DataGrid
        dataSource={dataSourceGrid}
        showRowLines
        showBorders
        rowAlternationEnabled
        columnAutoWidth
        allowColumnReordering
        allowColumnResizing
      >
        <ColumnChooser enabled />
        <GroupPanel visible />
        <SearchPanel visible highlightCaseSensitive />
        <Grouping autoExpandAll={false} />
        <HeaderFilter visible />

        <Column dataField="unidade.unidadeBasica" caption="Unidade" dataType="string" />
        <Column dataField="condomino" caption="Condômino" dataType="string" />
        <Column dataField="dataDeReferencia" caption="Referência" dataType="string" />
        <Column dataField="leituraAnteriorFormatada" caption="Anterior" dataType="string" />
        <Column dataField="leituraAtualFormatada" caption="Atual" dataType="string" />
        <Column
          dataField="consumoFormatado"
          alignment="center"
          caption="Consumo"
          dataType="string"
        />
        <Column
          caption="Ações"
          cellRender={acoes}
          alignment="center"
          width={150}
          allowExporting={false}
        />
        <Pager allowedPageSizes={[10, 25, 50, 100]} showPageSizeSelector showInfo />
        <Paging defaultPageSize={10} />
      </DataGrid>
    </>
  );
}

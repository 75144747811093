import swal from 'sweetalert2';
import hash from 'react-router-history';
import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { createSuccessNotification, sendErroRequest } from '../utils/Notification';
import { formatarNumeroMoeda, formatarNumeroGeral } from '../utils/Functions';

const { hashHistory } = hash;

export const setUnidadesCentrais = response => ({
  type: a.UCT_GET_UNIDADES_CENTRAIS,
  payload: response,
});

export const processandoUnidadeCentral = bool => ({
  type: a.UCT_PROCESSANDO_UNIDADE_CENTRAL,
  payload: bool,
});

export const setUnidadeCentralById = data => dispatch => {
  dispatch({
    type: a.UCT_GET_UNIDADES_CENTRAIS_BY_ID,
    payload: data,
  });
};

export const getUnidadesCentrais = () => async dispatch => {
  try {
    const { data } = await laris.get('/unidades-centrais');

    dispatch(setUnidadesCentrais(data));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao buscar as Unidades Centrais');
  }
};

export const getUnidadeCentralById = id => async dispatch => {
  try {
    dispatch(processandoUnidadeCentral(true));
    const { data } = await laris.get(`/unidades-centrais/${id}`);
    dispatch(setUnidadeCentralById(data));
    dispatch(processandoUnidadeCentral(false));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao buscar a Unidade Central selecionada!');
  }
};

export const deleteUnidadeCentral = id => async dispatch => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      const { data } = await laris.delete(`/unidades-centrais/${id}`);
      if (data.sucesso) createSuccessNotification('Unidade Central excluído com sucesso!');
    }
    dispatch(getUnidadesCentrais());
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao excluir a Unidade Central!');
  }
};

export const postUnidadeCentral = values => async dispatch => {
  try {
    dispatch(processandoUnidadeCentral(true));
    const params = {
      ...(values.descricao && { descricao: values.descricao }),
      ...(values.cobraTaxaFixa && { cobraTaxaFixa: values.cobraTaxaFixa }),
      ...(values.valorTaxaAreaUnidade && {
        valorTaxaAreaUnidade: formatarNumeroGeral(values.valorTaxaAreaUnidade),
      }),
      ...(values.cobraTaxaMinimaGas && { cobraTaxaMinimaGas: values.cobraTaxaMinimaGas }),
      ...(values.valorTaxaMinimaGas && {
        valorTaxaMinimaGas: formatarNumeroGeral(values.valorTaxaMinimaGas),
      }),
      ...(values.cobraTaxaMinimaAgua && { cobraTaxaMinimaAgua: values.cobraTaxaMinimaAgua }),
      ...(values.valorTaxaMinimaAgua && {
        valorTaxaMinimaAgua: formatarNumeroGeral(values.valorTaxaMinimaAgua),
      }),
      ...(values.cobraTaxaMinimaEnergia && {
        cobraTaxaMinimaEnergia: values.cobraTaxaMinimaEnergia,
      }),
      ...(values.valorTaxaMinimaEnergia && {
        valorTaxaMinimaEnergia: formatarNumeroGeral(values.valorTaxaMinimaEnergia),
      }),
    };

    await laris.post('/unidades-centrais', params);
    dispatch(processandoUnidadeCentral(false));
    createSuccessNotification('Unidade Central cadastrada com sucesso');
    hashHistory.push('/unidades-centrais');
  } catch (error) {
    dispatch(processandoUnidadeCentral(false));
    sendErroRequest(error, 'Ocorreu um erro ao cadastrar a Unidade Central');
  }
};

export const putUnidadeCentral = values => async dispatch => {
  try {
    const UnidadeCentral = {
      cobraTaxaFixa: values.cobraTaxaFixa,
      cobraTaxaMinimaGas: values.cobraTaxaMinimaGas,
      cobraTaxaMinimaAgua: values.cobraTaxaMinimaAgua,
      cobraTaxaMinimaEnergia: values.cobraTaxaMinimaEnergia,
      ...(values.id && { id: values.id }),
      ...(values.descricao && { descricao: values.descricao }),
      ...(values.valorTaxaAreaUnidade && {
        valorTaxaAreaUnidade: formatarNumeroMoeda(values.valorTaxaAreaUnidade),
      }),
      ...(values.valorTaxaMinimaGas && {
        valorTaxaMinimaGas: formatarNumeroMoeda(values.valorTaxaMinimaGas),
      }),
      ...(values.valorTaxaMinimaAgua && {
        valorTaxaMinimaAgua: formatarNumeroMoeda(values.valorTaxaMinimaAgua),
      }),
      ...(values.valorTaxaMinimaEnergia && {
        valorTaxaMinimaEnergia: formatarNumeroMoeda(values.valorTaxaMinimaEnergia),
      }),
    };

    dispatch(processandoUnidadeCentral(true));

    await laris.put('/unidades-centrais', UnidadeCentral);

    dispatch(getUnidadesCentrais());
    dispatch(processandoUnidadeCentral(false));
    createSuccessNotification('Unidade Central alterado com sucesso!');

    hashHistory.push('/unidades-centrais');
  } catch (error) {
    dispatch(processandoUnidadeCentral(false));
    sendErroRequest(error, 'Ocorreu um erro ao alterar a Unidade Central');
  }
};

export async function getImportarPlanilhaUnidadesCentrais(resp) {
  try {
    const { data } = await laris.post('/unidades-centrais/upload-planilha-Unidade-Central', resp);

    const unidadesCentrais = data.map(u => {
      return {
        ...u,
        key: u.id,
        label: u.descricao,
      };
    });

    return unidadesCentrais;
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao cadastrar a Unidade Central');
    return undefined;
  }
}

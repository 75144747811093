import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  parcelamentos: [],
  processandoParcelamento: false,
  parcelasSimuladas: [],
  parcelasAnaliticas: [],
  unidadesAdiantamento: [],
  parcelamentoByID: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.PRC_SET_ESTADO_INICIAL:
      return {
        ...state,
        parcelamentos: [],
        processandoParcelamento: false,
        parcelasSimuladas: [],
        parcelasAnaliticas: [],
        unidadesAdiantamento: [],
      };
    case a.PRC_GET_PARCELAMENTOS:
      return { ...state, parcelamentos: payload };
    case a.PRC_PROCESSANDO_PARCELAMENTO:
      return { ...state, processandoParcelamento: payload };
    case a.PRC_GET_PARCELAMENTOS_BY_ID:
      return { ...state, parcelamentoByID: payload };
    case a.PRC_SET_PARCELAS_SIMULADAS:
      return { ...state, parcelasSimuladas: payload };
    case a.PRC_SET_PARCELAS_ANALITICAS:
      return { ...state, parcelasAnaliticas: payload };
    case a.PRC_SET_UNIDADES_ADIANTAMENTO:
      return { ...state, unidadesAdiantamento: payload };
    default:
      return { ...state };
  }
};

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  portadores: [],
  processandoPortador: false,
  tipoInscricaoCedente: [],
  bancos: [],
  bancosById: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.PTD_SET_ESTADO_INICIAL:
      return { ...state, processandoPortador: false, portadores: [] };
    case a.PTD_GET_PORTADORES:
      return { ...state, portadores: payload };
    case a.PTD_PROCESSANDO_PORTADOR:
      return { ...state, processandoPortador: payload };
    case a.PTD_SET_MASCARA_INSCRICAO_CEDENTE:
      return { ...state, tipoInscricaoCedente: payload };
    case a.PTD_GET_BANCOS:
      return { ...state, bancos: payload };
    case a.PTD_GET_PORTADORES_BY_ID:
      return { ...state, bancosById: payload };
    default:
      return { ...state };
  }
};

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  unidades: [],
  processandoUnidade: false,
  medidores: [],
  adicionandoMedidor: false,
  unidadesComMedidor: [],
  unidade_By_Id: null,
  listaUnidadeEditar: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case a.UND_SET_ESTADO_INICIAL:
      return {
        ...state,
        processandoUnidade: false,
        unidades: [],
        medidores: [],
        adicionandoMedidor: false,
        unidadesComMedidor: [],
        listaUnidadeEditar: [],
      };
    case a.UND_GET_UNIDADES:
      return { ...state, unidades: payload };
    case a.UND_PROCESSANDO_UNIDADE:
      return { ...state, processandoUnidade: payload };
    case a.UND_ADD_MEDIDORES:
      return { ...state, medidores: payload };
    case a.UND_ADICIONANDO_MEDIDOR:
      return { ...state, adicionandoMedidor: payload };
    case a.UND_GET_UNIDADES_COM_MEDIDOR:
      return { ...state, unidadesComMedidor: payload };
    case a.UND_GET_UNIDADES_BY_ID:
      return { ...state, unidade_By_Id: payload };
    case a.UND_SET_UNIDADES_EDITAR:
      return { ...state, listaUnidadeEditar: payload };
    default:
      return { ...state };
  }
};

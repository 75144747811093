import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  faturas: [],
  processandoFatura: false,
  adicionandoItemFatura: false,
  itensFatura: [],
  faturaSelecionada: {},
  showModalItemFatura: false,
  showModalBaixaFatura: false,
  faturasEmAberto: [],
  valorTotalFaturas: 0.0,
  gerandoFaturas: false,
  processandoBaixaFatura: false,
  baixaColetivaFaturas: false,
  faturasSelecionadasChecked: [],
  faturasEmAbertoMarcadas: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.FTR_SET_ESTADO_INICIAL:
      return {
        ...state,
        processandFatura: false,
        faturas: [],
        itensFatura: [],
        adicionandoItemFatura: false,
        showModalItemFatura: false,
        faturasEmAberto: [],
        faturaSelecionada: {},
        showModalBaixaFatura: false,
        valorTotalFaturas: 0.0,
        gerandoFaturas: false,
        processandoBaixaFatura: false,
        baixaColetivaFaturas: false,
        faturasSelecionadasChecked: [],
      };
    case a.FTR_GET_FATURAS:
      return { ...state, faturas: payload };
    case a.FTR_SET_LISTA_ITENS_FATURA:
      return { ...state, itensFatura: payload };
    case a.FTR_PROCESSANDO_FATURA:
      return { ...state, processandoFatura: payload };
    case a.FTR_ADICIONANDO_ITEM_FATURA:
      return { ...state, adicionandoItemFatura: payload };
    case a.FTR_SHOW_MODAL_ITEM_FATURA:
      return { ...state, showModalItemFatura: payload };
    case a.FTR_GET_FATURAS_EM_ABERTO:
      return { ...state, faturasEmAberto: payload };
    case a.FTR_GET_FATURAS_EM_ABERTO_MARCADAS:
      return { ...state, faturasEmAbertoMarcadas: payload };
    case a.FTR_SET_FATURA_SELECIONADA:
      return { ...state, faturaSelecionada: payload };
    case a.FTR_SET_VALOR_TOTAL_FATURAS:
      return { ...state, valorTotalFaturas: payload };
    case a.FTR_GERANDO_FATURAS:
      return { ...state, gerandoFaturas: payload };
    case a.FTR_SHOW_MODAL_BAIXA_FATURA:
      return { ...state, showModalBaixaFatura: payload };
    case a.FTR_PROCESSANDO_BAIXA_FATURA:
      return { ...state, processandoBaixaFatura: payload };
    case a.FTR_BAIXA_COLETIVA_FATURAS:
      return { ...state, baixaColetivaFaturas: payload };
    case a.FATURAS_SELECIONADAS_CHECKED:
      return { ...state, faturasSelecionadasChecked: payload };
    default:
      return { ...state };
  }
};

import * as Yup from 'yup';

export const AuditoriaFaturasBaixadaSchema = Yup.object().shape({
  competenciaInicial: Yup.string().nullable().required('Campo requerido.'),
  competenciaFinal: Yup.string().nullable().required('Campo requerido.'),
});

export const ChecagemGeralSaldosSchema = Yup.object().shape({
  periodo: Yup.string().nullable().required('Campo requerido.'),
});

export const movLeituraConsumo = Yup.object().shape({
  unidadeId: Yup.string().required('Campo requerido.').nullable(),
  periodoInicial: Yup.string().required('Período Obrigatório.').nullable(),
  periodoFinal: Yup.string().required('Período Obrigatório.').nullable(),
  tipoDoConsumo: Yup.string().required('Campo requerido.').nullable(),
});

export const FormLeituraConsumo = Yup.object().shape({
  unidade: Yup.string().required('Campo requerido.').nullable(),
  dataDeReferencia: Yup.string().required('Período Obrigatório.').nullable(),
  leituraAtual: Yup.string().required('Campo Obrigatório.').nullable(),
  tipoDoConsumo: Yup.string().required('Campo requerido.').nullable(),
});

export const filtroLancamento = Yup.object().shape({
  dataInicial: Yup.string().required('Período Obrigatório.').nullable(),
  dataFinal: Yup.string().required('Período Obrigatório.').nullable(),
});

export const formLancamento = Yup.object().shape({
  dataDoLancamento: Yup.string().required('Período Obrigatório.').nullable(),
  documento: Yup.string().required('Campo requerido.').nullable(),
  valor: Yup.string().required('Campo requerido.').nullable(),
  tipoDoLancamento: Yup.string().required('Campo requerido.').nullable(),
  contaRecursoFinanceiroId: Yup.string().required('Campo requerido.').nullable(),
  contaId: Yup.string().required('Campo requerido.').nullable(),
});

export const formLancarTaxaAtraso = Yup.object().shape({
  descricao: Yup.string().required('Campo requerido.').nullable(),
  unidadeId: Yup.string().required('Campo requerido.').nullable(),
  dataReferencia: Yup.string().required('Período requerido.').nullable(),
  dataDoVencimento: Yup.string().required('Período requerido.').nullable(),
  valorDaFatura: Yup.string().required('Campo requerido.').nullable(),
});

export const modalLancarTaxaAtraso = Yup.object().shape({
  descricao: Yup.string().required('Campo requerido.').nullable(),
  taxaId: Yup.string().required('Campo requerido.').nullable(),
  historicoId: Yup.string().required('Campo requerido.').nullable(),
  contaId: Yup.string().required('Campo requerido.').nullable(),
  tipoDespesaReceita: Yup.string().required('Campo requerido.').nullable(),
  tituloAgrupamento: Yup.string().required('Campo requerido.').nullable(),
  valor: Yup.string().required('Campo requerido.').nullable(),
});

export const movGerarFatura = Yup.object().shape({
  periodo: Yup.string().required('Período Obrigatório.').nullable(),
  dataVencimento: Yup.string().required('Período Obrigatório.').nullable(),
});

export const filtroLancamentoPrevisao = Yup.object().shape({
  periodoInicial: Yup.string().required('Período Obrigatório.').nullable(),
  periodoFinal: Yup.string().required('Período Obrigatório.').nullable(),
});

export const acordoFiltro = Yup.object().shape({
  dataFinal: Yup.string().required('Período Obrigatório.').nullable(),
});

export const acordoForm = Yup.object().shape({
  descricao: Yup.string().required('Campo Obrigatório.').nullable(),
  dataAcordo: Yup.string().required('Período Obrigatório.').nullable(),
  dataPrimeiroVencimento: Yup.string().required('Período Obrigatório.').nullable(),
  quantidadeParcelas: Yup.number().required('Campo Obrigatório.').nullable(),

  unidadeId: Yup.string().required('Campo Obrigatório.').nullable(),

  valorAcordado: Yup.string().required('Campo Obrigatório.').nullable(),
  escritorioCobrancaId: Yup.string().required('Campo Obrigatório.').nullable(),
});

export const filtroConsultaBaixaFatura = Yup.object().shape({
  periodoCompetenciaInicial: Yup.string().required('Período Obrigatório.').nullable(),
  periodoCompetenciaFinal: Yup.string().required('Período Obrigatório.').nullable(),
});

export const formBaixaManual = Yup.object().shape({
  dataPagamento: Yup.string().required('Período Obrigatório.').nullable(),
});

export const formParcelamento = Yup.object().shape({
  quantidadeParcelas: Yup.string().required('Campo Obrigatório.').nullable(),
  valorTotalParcelamento: Yup.string().required('Campo Obrigatório.').nullable(),
  descricao: Yup.string().required('Campo Obrigatório.').nullable(),

  // documento: Yup.string()
  //   .required('Campo Obrigatório.')
  //   .nullable(),
  // tipoLancamentoDespesaReceita: Yup.string()
  //   .required('Campo Obrigatório.')
  //   .nullable(),
  // tipoDespesaReceita: Yup.string()
  //   .required('Campo Obrigatório.')
  //   .nullable(),
  // contasDespesa: Yup.string()
  //   .required('Campo Obrigatório.')
  //   .nullable(),
  // valorDoLancamento: Yup.string()
  //   .required('Campo Obrigatório.')
  //   .nullable(),

  // taxaId: Yup.string()
  //   .required('Campo Obrigatório.')
  //   .nullable(),
});

export const filtroPrevisao = Yup.object().shape({
  periodoInicial: Yup.string().required('Período Obrigatório.').nullable(),
  periodoFinal: Yup.string().required('Período Obrigatório.').nullable(),
});

export const filtroPrevisaoImportacao = Yup.object().shape({
  periodoInicial: Yup.string().required('Período Obrigatório.').nullable(),
  periodoFinal: Yup.string().required('Período Obrigatório.').nullable(),
});

export const gerarPorParcelamento = Yup.object().shape({
  periodoCompetencia: Yup.string().required('Período Obrigatório.').nullable(),
});
export const formPrevisao = Yup.object().shape({
  dataDoLancamento: Yup.string().required('Período Obrigatório.').nullable(),

  documento: Yup.string().required('Campo Obrigatório.').nullable(),
  tipoLancamentoDespesaReceita: Yup.string().required('Campo Obrigatório.').nullable(),
  tipoDespesaReceita: Yup.string().required('Campo Obrigatório.').nullable(),
  conta: Yup.string().required('Campo Obrigatório.').nullable(),
  valorDoLancamento: Yup.string().required('Campo Obrigatório.').nullable(),
  // descricaoContaSintetica: Yup.string()
  //   .required('Campo Obrigatório.')
  //   .nullable(),
  taxaId: Yup.string().required('Campo Obrigatório.').nullable(),
});

export const movDemonstrativoTaxaFixa = Yup.object().shape({
  dataPeriodo: Yup.string().required('Período Obrigatório.').nullable(),
});

export const movLeituraConsumoAutomatica = Yup.object().shape({
  dataDeReferencia: Yup.string().required('Período Obrigatório.').nullable(),
  tipoDoConsumo: Yup.string().required('Campo Obrigatório.').nullable(),
});

import * as Yup from 'yup';

export const EmpresaSchema = Yup.object().shape({
  tipoInscricao: Yup.string().notRequired(),
  inscricao: Yup.mixed()
    .when('tipoInscricao', {
      is: '1',
      then: Yup.string()
        .min(14, 'CPF deve conter 11 digitos')
        .required('Campo requerido.'),
    })
    .when('tipoInscricao', {
      is: '2',
      then: Yup.string()
        .min(18, 'CNPJ deve conter 14 digitos')
        .required('Campo requerido.'),
    })
    .when('tipoInscricao', {
      is: '3',
      then: Yup.string()
        .min(18, 'CAEPF deve conter 14 digitos')
        .required('Campo requerido.'),
    }),
  razaoSocial: Yup.string()
    .max(80, ({ max }) => `Razão Social não pode exceder ${max} caracteres.`)
    .required('Campo requerido.'),
  nomeFantasia: Yup.string().max(
    80,
    ({ max }) => `Nome fantasia não pode exceder ${max} caracteres.`,
  ),
  emailPrincipal: Yup.string().email('O e-mail informado é inválido.'),
  telefone: Yup.string().min(10, `Telefone deve conter 10 digitos. Exemplo: (99)9999-9999.`),
  celular: Yup.string().min(10, `Celular deve conter 10 digitos. Exemplo: (99)9999-9999.`),
});

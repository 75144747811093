import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  acordos: [],
  acordosById: null,
  processandoAcordo: false,
  parcelasSimuladas: [],
  valorTotalAcordado: 0,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.ACD_SET_ESTADO_INICIAL:
      return { ...state, processandoAcordo: false, acordos: [] };
    case a.ACD_GET_ACORDOS:
      return { ...state, acordos: payload };
    case a.ACD_GET_ACORDOS_TOTAL:
      return { ...state, valorTotalAcordado: payload };
    case a.ACD_GET_ACORDOS_BY_ID:
      return { ...state, acordosById: payload };
    case a.ACD_PROCESSANDO_ACORDO:
      return { ...state, processandoAcordo: payload };
    case a.ACD_SET_PARCELAS_SIMULADAS:
      return { ...state, parcelasSimuladas: payload };
    default:
      return { ...state };
  }
};

import moment from 'moment';
import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { formatarNumeroGeral } from '../utils/Functions';
import {
  createSuccessNotification,
  sendErroRequest,
  createInfoNotification,
} from '../utils/Notification';
import { setFaturasEmAberto, processandoFatura } from './faturaActions';

const { hashHistory } = hash;

export const getAcordosSuccess = (response) => ({
  type: a.ACD_GET_ACORDOS,
  payload: response,
});

export const processandoAcordo = (bool) => ({
  type: a.ACD_PROCESSANDO_ACORDO,
  payload: bool,
});

export const totalDoValorAcordado = (response) => ({
  type: a.ACD_GET_ACORDOS_TOTAL,
  payload: response,
});

export const setParcelasSimuladas = (response) => ({
  type: a.ACD_SET_PARCELAS_SIMULADAS,
  payload: response,
});

export const setcordoById = (data) => ({
  type: a.ACD_GET_ACORDOS_BY_ID,
  payload: data,
});

export const limpaTotalValorAcordado = () => (dispatch) => {
  dispatch(totalDoValorAcordado(0));
};

export const getAcordos = (values) => async (dispatch) => {
  try {
    dispatch(processandoAcordo(true));
    const { dataInicial, dataFinal, statusAcordo, unidade } = values;

    const filtroArray = [];

    if (dataInicial)
      filtroArray.push({
        campo: 'dataAcordo',
        valor: moment(dataInicial).startOf('day'),
        condicao: 'gte',
      });

    if (dataFinal)
      filtroArray.push({
        campo: 'dataAcordo',
        valor: moment(dataFinal).endOf('day'),
        condicao: 'lte',
      });

    if (statusAcordo >= 0 && statusAcordo !== '')
      filtroArray.push({ campo: 'statusAcordo', valor: statusAcordo });

    const params = {
      ...(filtroArray.length > 0 && { filtro: JSON.stringify(filtroArray) }),
      ...(unidade && unidade.id !== '' && { unidadeId: unidade.id }),
    };

    if (dataInicial > dataFinal) {
      return createInfoNotification('Acordo', 'Data Inicial deve ser menor que Data Final!');
    }
    if (dataInicial > dataFinal) {
      return sendErroRequest('Acordo', 'Data Inicial deve ser menor que Data Final!');
    }

    const { data } = await laris.get(`/acordos`, { params });

    const listaAcordos = data.map((acordo) => ({
      ...acordo,
      dataCancelamento: moment(acordo.dataCancelamento).format('DD/MM/YYYY'),
      dataAcordo: moment(acordo.dataAcordo).format('DD/MM/YYYY'),
    }));

    dispatch(getAcordosSuccess(listaAcordos));
    dispatch(processandoAcordo(false));
  } catch (error) {
    dispatch(processandoAcordo(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar as Acordos!');
  }
};

export const getAcordoById = (id) => async (dispatch) => {
  try {
    dispatch(processandoAcordo(true));
    dispatch(processandoFatura(true));

    const { data } = await laris.get(`/acordos/${id}`);

    const parcelas = !data.parcelas
      ? []
      : data.parcelas.map((item) => ({
          ...item,
          dataVencimento: moment(item.dataVencimento).format('L'),
        }));

    const faturas = !data.faturas
      ? []
      : data.faturas.map((item) => ({
          ...item,
          dataDoVencimento: moment(item.dataDoVencimento).format('L'),
        }));

    dispatch(setcordoById(data));
    dispatch(setParcelasSimuladas(parcelas));
    dispatch(setFaturasEmAberto(faturas));
    dispatch(processandoFatura(false));
    dispatch(processandoAcordo(false));
  } catch (err) {
    dispatch(processandoAcordo(false));
    dispatch(processandoFatura(false));
    sendErroRequest(err, 'Ocorreu um erro ao buscar a Acordo selecionado!');
  }
};

export const restaurarFaturaAcordo = (idAcordo, idFatura) => async (dispatch) => {
  try {
    await laris.post(`/acordos/restaurar-fatura-acordo/${idAcordo}/${idFatura}`);

    return createSuccessNotification('Fatura restaurada com sucesso!');
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao tentar recuperar a fatura do Acordo!');
  }
};

export const restaurarFaturaGeradaPeloAcordo = (idAcordo, idFatura) => async (dispatch) => {
  try {
    await laris.post(`/acordos/restaurar-fatura-gerada-pelo-acordo/${idAcordo}/${idFatura}`);

    return createSuccessNotification('Fatura  gerada pelo acordo restaurada com sucesso!');
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao tentar recuperar a fatura gerada pelo Acordo!');
  }
};

export const postAcordo = (props, faturasEmAberto, parcelasSimuladas) => async (dispatch) => {
  try {
    let parcelasEditadas = 0;
    for (let i = 0; i < parcelasSimuladas.length - 1; i++)
      parcelasEditadas += parcelasSimuladas[i].valor;

    parcelasEditadas = Math.round(parcelasEditadas * 100) / 100;

    const novasParcelas = parcelasSimuladas.map((item) => ({
      ...item,
      dataVencimento: moment(item.dataVencimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }));

    if (parcelasEditadas < props.valorTotal && parcelasEditadas > props.valorTotal) {
      return createInfoNotification('total das parcelas deve ser igual ao valor total do acordo');
    }

    if (faturasEmAberto.length === 0)
      return createInfoNotification('Não é possível fazer o acordo sem nenhuma fatura marcada!');

    if (formatarNumeroGeral(props.valorAcordado) === 0)
      return createInfoNotification(
        'O valor devido deverá ser maior que zero para que o acordo seja feito!',
      );

    dispatch(processandoAcordo(true));

    const acordo = {
      ...props,
      entradaAcordo: formatarNumeroGeral(props.entradaAcordo),
      honorariosAdvocaticios: formatarNumeroGeral(props.honorariosAdvocaticios),
      valorCredito: formatarNumeroGeral(props.valorCredito),
      valorDevido: formatarNumeroGeral(props.valorAcordado),
      unidade: { id: props.unidadeId },
      escritorioCobranca: { id: props.escritorioCobrancaId },
      faturas: faturasEmAberto,
      parcelasSimuladas: novasParcelas,
    };

    await laris.post(`/acordos`, acordo);

    dispatch(processandoAcordo(false));
    createSuccessNotification('Acordo cadastrado com sucesso');
    dispatch(totalDoValorAcordado(0));
    dispatch(setFaturasEmAberto([]));
    dispatch(setParcelasSimuladas([]));
    hashHistory.push('/acordos');
  } catch (err) {
    dispatch(processandoAcordo(false));
    sendErroRequest(
      err,
      'Ocorreu um erro ao registrar a Acordo! Verifique os dados e tente novamente!',
    );
  }
};

export const limparListaParcelasSimuladas = () => async (dispatch) => {
  dispatch(setParcelasSimuladas([]));
};

export const simularParcelamento = (props) => async (dispatch) => {
  try {
    if (!props.dataPrimeiroVencimento)
      return createInfoNotification(
        'É necessário que seja informada a data do primeiro vencimento!',
      );

    if (props.valorAcordado <= 0 || !props.valorAcordado || props.valorAcordado === 0)
      return createInfoNotification(
        'Não é possível fazer a simulação com o valor total igual ou menor que zero!',
      );
    const entrada = formatarNumeroGeral(props.entradaAcordo);

    const simulacao = {
      dataPrimeiroVencimento: moment(props.dataPrimeiroVencimento).format('YYYY-MM-DD'),
      quantidadeParcelas: props.quantidadeParcelas,
      valorTotal:
        formatarNumeroGeral(props.valorAcordado) -
        formatarNumeroGeral(props.valorCredito) +
        formatarNumeroGeral(props.honorariosAdvocaticios),
      aplicaJurosParcelamento: props.aplicaJurosParcelamento || false,
      entradaAcordo: entrada,
    };

    dispatch(processandoAcordo(true));

    const { data } = await laris.post(`/acordos/simularParcelamento`, simulacao);

    const parcelas = data.map((item) => ({
      ...item,
      dataVencimento: moment(item.dataVencimento).format('L'),
    }));

    const valorTotal =
      Math.round(parcelas.map((item) => item.valor).reduce((total, valor) => total + valor) * 100) /
      100;
    if (entrada > 0 && entrada === valorTotal)
      return createInfoNotification('A entrada não pode ser o valor total do parcelamento!');

    dispatch(setParcelasSimuladas(parcelas));
    dispatch(processandoAcordo(false));
    dispatch(totalDoValorAcordado(valorTotal));
  } catch (err) {
    dispatch(processandoAcordo(false));
    sendErroRequest(err, 'Ocorreu um ero ao tentar fazer a simulação de parcelamento');
  }
};

export const cancelarAcordo = (id) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar cancelamento!',
      text: 'Você tem certeza que deseja cancelar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja cancelar?',
      cancelButtonText: 'Cancelar',
    });
    if (res.value) {
      const retorno = await laris.put(`acordos/${id}`);
      if (retorno) {
        dispatch(getAcordos('', 0));
        createSuccessNotification('Acordo cancelado com sucesso!');
      }
    }
  } catch (error) {
    sendErroRequest(error, 'Erro ao cancelar acordo!');
  }
};

export const putEditarSimulacao = (data, dataSource, valorTotalAcordo) => async (dispatch) => {
  try {
    const { numeroParcela } = data;
    const penultimaParcela = dataSource.length - 1;

    if (numeroParcela === penultimaParcela) {
      let totalJaParcelado = 0;
      const parcelamentoAtualizado = dataSource.map((item) => {
        if (item.numeroParcela !== dataSource.length) totalJaParcelado += parseFloat(item.valor);

        if (item.numeroParcela === dataSource.length) {
          return {
            ...item,
            valor: valorTotalAcordo - totalJaParcelado,
          };
        }
        return item;
      });
      dispatch(setParcelasSimuladas(parcelamentoAtualizado));
    } else dispatch(setParcelasSimuladas(dataSource));
  } catch (error) {
    sendErroRequest(error, 'Erro no Acordo!, não é possível editar as parcelas.');
  }
};

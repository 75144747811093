import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { formatarNumeroGeral } from '../utils/Functions';
import {
  createSuccessNotification,
  sendErroRequest,
  createInfoNotification,
} from '../utils/Notification';

const { hashHistory } = hash;

export const processandoIndexadores = (bool) => ({
  type: a.IND_PROCESSANDO_INDEXADORES,
  payload: bool,
});

export const setIndexadores = (data) => ({
  type: a.IND_GET_INDEXADORES,
  payload: data,
});

export const setIndexadoById = (data) => ({
  type: a.IND_SET_INDEXADORES_BY_ID,
  payload: data,
});

const setEstadoInicial = () => ({
  type: a.IND_SET_ESTADO_INICIAL,
});

export const getEstadoInicial = () => async (dispatch) => {
  dispatch(setEstadoInicial());
};
export const getIndexadores = () => async (dispatch) => {
  try {
    dispatch(processandoIndexadores(true));
    const { data } = await laris.get('/indexadores');

    dispatch(setIndexadores(data));
    dispatch(processandoIndexadores(false));
  } catch (error) {
    dispatch(processandoIndexadores(false));
    sendErroRequest(error, 'Erro ao buscar os índices de correções');
  }
};

export const postIndexador = (values) => async (dispatch) => {
  try {
    dispatch(processandoIndexadores(true));
    const indexador = {
      ...(values.descricao && { descricao: values.descricao }),
      ...(values.valor && { valor: formatarNumeroGeral(values.valor) }),
    };
    await laris.post('/indexadores', indexador);
    createSuccessNotification('Indexador adicionado com sucesso!');
    hashHistory.push('/indexadores');

    dispatch(processandoIndexadores(false));
  } catch (error) {
    dispatch(processandoIndexadores(false));
    sendErroRequest(error, 'Erro ao buscar os indexadores');
  }
};

export const getIndexadorById = (id) => async (dispatch) => {
  try {
    dispatch(processandoIndexadores(true));
    const { data } = await laris.get(`/indexadores/${id}`);
    dispatch(setIndexadoById(data));

    dispatch(processandoIndexadores(false));
  } catch (error) {
    dispatch(processandoIndexadores(false));
    sendErroRequest(error, 'Erro ao buscar o Indexador selecionado');
  }
};

export const putIndexador = (values) => async (dispatch) => {
  try {
    dispatch(processandoIndexadores(true));
    const indexador = {
      ...(values.id && { id: values.id }),
      ...(values.descricao && { descricao: values.descricao }),
      ...(values.valor && { valor: formatarNumeroGeral(values.valor) }),
    };
    await laris.put('/indexadores', indexador);

    createSuccessNotification('Indexador editado com sucesso!');
    hashHistory.push('/indexadores');

    dispatch(processandoIndexadores(false));
  } catch (error) {
    dispatch(processandoIndexadores(false));
    sendErroRequest(error, 'Erro ao Editar Indexador');
  }
};

export const deleteIndexador = (id, lista) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      dispatch(processandoIndexadores(true));
      await laris.delete(`/indexadores/${id}`);
      const novaLista = lista.filter((li) => li.id !== id);
      createSuccessNotification('Indexador deletado com sucesso!');
      dispatch(setIndexadores(novaLista));
      dispatch(processandoIndexadores(false));
    }
  } catch (error) {
    dispatch(processandoIndexadores(false));
    sendErroRequest(error, 'Erro ao deletar o Indexador selecionado');
  }
};

import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { createSuccessNotification, sendErroRequest } from '../utils/Notification';

const { hashHistory } = hash;

export const getFechamentosPeriodoSuccess = (response) => ({
  type: a.FCP_GET_FECHAMENTOS_PERIODO,
  payload: response,
});

export const processandoFechamentoPeriodo = (bool) => ({
  type: a.FCP_PROCESSANDO_FECHAMENTO_PERIODO,
  payload: bool,
});

const setEstadoInicial = () => ({
  type: a.FCP_SET_ESTADO_INICIAL,
});

export const getFechamentosPeriodo = (values) => async (dispatch) => {
  try {
    if (!values)
      return sendErroRequest('Fechamento de Periodo', 'Informe o ano para fazer a pesquisa');

    const ano = values.periodo;
    dispatch(processandoFechamentoPeriodo(true));

    const { data } = await laris.get(`/fechamentos-periodo/ano/${ano}`);

    dispatch(getFechamentosPeriodoSuccess(data));
    dispatch(processandoFechamentoPeriodo(false));
  } catch (err) {
    dispatch(processandoFechamentoPeriodo(false));
    sendErroRequest(err, 'Ocorreu um erro ao tentar carregar os fechamentos de periodo!');
  }
};

export const abrirFecharPeriodo = (values) => async (dispatch) => {
  try {
    dispatch(processandoFechamentoPeriodo(true));
    const { fechamentoPeriodos } = values;
    const { id } = values;
    const tipo = values.abreFechaPeriodo;
    const AbreFechaPeriodo = values.tipo;
    const { competencia } = values;

    const { data } = await laris.put(`/fechamentos-periodo/abrirFecharPeriodo/${id}`, {
      tipo,
      AbreFechaPeriodo,
      competencia,
    });

    const novaLista = fechamentoPeriodos.map((fechamento) => {
      if (fechamento.id === data.fechamentoPeriodo.id) return data.fechamentoPeriodo;
      return fechamento;
    });

    dispatch(getFechamentosPeriodoSuccess(novaLista));
    dispatch(processandoFechamentoPeriodo(false));
  } catch (err) {
    dispatch(processandoFechamentoPeriodo(false));
    sendErroRequest(
      err,
      'Ocorreu um erro ao tentar atualizar o fechamento de periodo selecionado!',
    );
  }
};

import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import {
  createSuccessNotification,
  sendErroRequest,
  createInfoNotification,
} from '../utils/Notification';
// import { change } from 'redux-form';

const setEstadoInicial = () => (dispatch) => dispatch({ type: a.RET_SET_ESTADO_INICIAL });

export const setRetornoBancario = (response) => (dispatch) =>
  dispatch({ type: a.RET_SET_RETORNO_BANCARIO, payload: response });

export const processandoRetornoBancario = (bool) => (dispatch) =>
  dispatch({ type: a.RET_PROCESSANDO_RETORNO_BANCARIO, payload: bool });

export const addRetornoBancario = (file) => (dispatch) => {
  try {
    dispatch(setRetornoBancario([...[file]]));
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro ao fazer o upload do arquivo retorno. Confira o arquivo e tente novamente!',
    );
  }
};

export const importarRetornoBancario = (values) => async (dispatch) => {
  try {
    if (values.retornoBancario.length <= 0)
      return createInfoNotification('É necessário anexar um retorno bancário!');

    dispatch(processandoRetornoBancario(true));

    const formData = new FormData();
    formData.append('portador_Id', values.portador);
    formData.append('file', values.retornoBancario);
    createInfoNotification('Retorno importado ! Aguarde...');

    const { data } = await laris.post(`/retornos/importarRetornoBancario`, formData);

    let valorTotalFaturas = 0;

    data.faturasImportadas.map((fat) => (valorTotalFaturas += fat.valorPago));
    const retorno = {
      ...data,
      valorTotalFaturas,
    };
    dispatch(processandoRetornoBancario(false));
    return retorno;
  } catch (err) {
    dispatch(processandoRetornoBancario(false));
    sendErroRequest(err, 'Erro ao enviar o arquivo retorno!');
  }
};

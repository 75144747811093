import React from 'react';

export default ({ location, path, tree, icon, label }) => {
  // const rotaAtiva =
  //   location.includes(path.substring(1, path.length)) ||
  //   (location === '/' && path === '#/dashboards');

  return (
    <li>
      <a href={path}>
        {icon && <i className={`fa fa-${icon}`} />}
        {tree ? label : <span className="nav-label">{label}</span>}
      </a>
    </li>
  );
};

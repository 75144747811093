import moment from 'moment';
import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { guidEmpty } from '../utils/Functions';
import {
  createInfoNotification,
  createSuccessNotification,
  sendErroRequest,
} from '../utils/Notification';

const { hashHistory } = hash;

export const getSindicosSuccess = (response) => ({
  type: a.SDC_GET_SINDICOS,
  payload: response,
});

export const processandoSindico = (bool) => ({
  type: a.SDC_PROCESSANDO_SINDICO,
  payload: bool,
});
export const setUnidadesSindico = (data) => ({
  type: a.SDC_GET_UNIDADES_SINDICO,
  payload: data,
});

export const setUnidadesSubSindico = (response) => ({
  type: a.SDC_GET_UNIDADES_SUB_SINDICO,
  payload: response,
});

const setEstadoInicial = () => ({
  type: a.SDC_SET_ESTADO_INICIAL,
});

export const setSindicoById = (data) => ({
  type: a.SDC_GET_SINDICOS_BY_ID,
  payload: data,
});
export const getSindicos = () => async (dispatch) => {
  try {
    dispatch(processandoSindico(true));

    const { data } = await laris.get(`/sindicos`);
    dispatch(processandoSindico(false));
    const sindicos = data.map((sindico) => ({
      ...sindico,
      dataInicioMandato: moment(sindico.dataInicioMandato).format('L'),
      dataFimMandato: moment(sindico.dataFimMandato).format('L'),
    }));

    dispatch(getSindicosSuccess(sindicos));
  } catch (error) {
    dispatch(processandoSindico(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar as Sindicos!');
  }
};

export const getSindicoById = (id) => async (dispatch) => {
  try {
    dispatch(processandoSindico(true));

    const { data } = await laris.get(`/sindicos/${id}`);

    const sindico = {
      ...data,
      dataInicioMandato: moment(data.dataInicioMandato),
      dataFimMandato: moment(data.dataFimMandato),
    };

    dispatch(getUnidadesSindico(sindico.sindico_Id));

    if (sindico.subSindico_Id !== guidEmpty())
      dispatch(getUnidadesSubSindico(sindico.subSindico_Id));

    dispatch(setSindicoById(sindico));
    dispatch(processandoSindico(false));
  } catch (error) {
    dispatch(processandoSindico(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar o sindico selecionado!');
  }
};

// export const onChangePercentualIsencaoSindico = value => dispatch => {
//   let temPercentualIsencaoSindico;

//   if (value === 0 || value === undefined) temPercentualIsencaoSindico = false;
//   else temPercentualIsencaoSindico = true;

//   dispatch(change('sindicoForm', 'temPercentualIsencaoSindico', temPercentualIsencaoSindico));
//   /*
//     if (!temPercentualIsencaoSindico)
//         dispatch(change('sindicoForm', 'unidadeIsentaSindico', {}))
//     */
// };

// export const onChangePercentualIsencaoSubSindico = value => dispatch => {
//   let temPercentualIsencaoSubSindico;

//   if (value === 0 || value === undefined) temPercentualIsencaoSubSindico = false;
//   else temPercentualIsencaoSubSindico = true;

//   dispatch(change('sindicoForm', 'temPercentualIsencaoSubSindico', temPercentualIsencaoSubSindico));
//   /*
//     if (!temPercentualIsencaoSubSindico)
//         dispatch(change('sindicoForm', 'unidadeIsentaSubSindico', {}))
//     */
// };

export const getUnidadesSindico = (id) => async (dispatch) => {
  try {
    if (!id) return;

    const { data } = await laris.get(`/unidades/getUnidadesProprietario/${id}`);

    dispatch(setUnidadesSindico(data));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar as unidades do sindico!');
  }
};

export const getUnidadesSubSindico = (id) => async (dispatch) => {
  try {
    if (!id) return;

    const { data } = await laris.get(`/unidades/getUnidadesProprietario/${id}`);

    dispatch(setUnidadesSubSindico(data));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar as unidades do sub-sindico!');
  }
};

export const deleteSindico = (id) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      await laris.delete(`/sindicos/${id}`);
      dispatch(getSindicos());
      createSuccessNotification('Sindico excluído com sucesso!');
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao exluir a Sindico!');
  }
};

export const putSindico = (values) => async (dispatch) => {
  try {
    if (
      (values.temPercentualIsencaoSindico && values.unidadeIsentaSindico == null) ||
      values.unidadeIsentaSindico == ''
    ) {
      return createInfoNotification('É necessário inserir uma unidade para ter a isenção!');
    }
    if (
      (values.temPercentualIsencaoSubSindico && values.unidadeIsentaSubSindico == null) ||
      values.unidadeIsentaSubSindico == ''
    ) {
      return createInfoNotification('É necessário inserir uma unidade para ter a isenção!');
    }

    const sindico = {
      ...(values.id && { id: values.id }),
      // sindico
      ...(values.sindico_Id && { sindico_Id: values.sindico_Id }),
      ...(values.percentualIsencaoSindico && {
        percentualIsencaoSindico:
          values.percentualIsencaoSindico.toString().indexOf('%') >= 0
            ? parseFloat(values.percentualIsencaoSindico.replace('%', '').replace(',', '.'))
            : values.percentualIsencaoSindico,
      }),
      ...(values.unidadeIsentaSindico && {
        unidadeIsentaSindico: {
          id: values.unidadeIsentaSindico,
          unidade: { id: values.unidadeIsentaSindico },
        },
      }),
      ...(values.temPercentualIsencaoSindico && {
        temPercentualIsencaoSindico: values.temPercentualIsencaoSindico,
      }),
      // subsindico

      ...(values.subSindico_Id && { subSindico_Id: values.subSindico_Id }),
      ...(values.percentualIsencaoSubSindico && {
        percentualIsencaoSubSindico:
          values.percentualIsencaoSubSindico.toString().indexOf('%') >= 0
            ? parseFloat(values.percentualIsencaoSubSindico.replace('%', '').replace(',', '.'))
            : values.percentualIsencaoSubSindico,
      }),
      ...(values.unidadeIsentaSubSindico && {
        unidadeIsentaSubSindico: {
          id: values.unidadeIsentaSubSindico,
          unidade: { id: values.unidadeIsentaSubSindico },
        },
      }),
      ...(values.temPercentualIsencaoSubSindico && {
        temPercentualIsencaoSubSindico: values.temPercentualIsencaoSubSindico,
      }),
      // //outros
      ...(values.conselheiros && { conselheiros: values.conselheiros }),
      ...(values.dataInicioMandato && { dataInicioMandato: values.dataInicioMandato }),
      ...(values.dataFimMandato && { dataFimMandato: values.dataFimMandato }),
      presidenteId: values.presidenteId,
    };

    if (sindico.dataFimMandato < sindico.dataInicioMandato)
      return createSuccessNotification('A data fim de mandato deve ser maior que a data início!');

    dispatch(processandoSindico(true));

    await laris.put(`/sindicos`, sindico);

    dispatch(processandoSindico(false));

    createSuccessNotification('Sindico alterado com sucesso!');
    hashHistory.push('/sindicos');
  } catch (error) {
    dispatch(processandoSindico(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar as Sindicos!');
  }
};

export const postSindico = (values) => async (dispatch) => {
  try {
    const sindico = {
      ...(values.id && { id: values.id }),
      // sindico
      ...(values.sindico_Id && { sindico_Id: values.sindico_Id }),
      ...(values.percentualIsencaoSindico && {
        percentualIsencaoSindico:
          values.percentualIsencaoSindico.indexOf('%') >= 0
            ? parseFloat(values.percentualIsencaoSindico.replace('%', '').replace(',', '.'))
            : values.percentualIsencaoSindico,
      }),
      ...(values.unidadeIsentaSindico && {
        unidadeIsentaSindico: {
          id: values.unidadeIsentaSindico,
          unidade: { id: values.unidadeIsentaSindico },
        },
      }),
      ...(values.temPercentualIsencaoSindico && {
        temPercentualIsencaoSindico: values.temPercentualIsencaoSindico,
      }),
      // subsindico

      ...(values.subSindico_Id && { subSindico_Id: values.subSindico_Id }),
      ...(values.percentualIsencaoSubSindico && {
        percentualIsencaoSubSindico:
          values.percentualIsencaoSubSindico.indexOf('%') >= 0
            ? parseFloat(values.percentualIsencaoSubSindico.replace('%', '').replace(',', '.'))
            : values.percentualIsencaoSubSindico,
      }),
      ...(values.unidadeIsentaSubSindico && {
        unidadeIsentaSubSindico: {
          id: values.unidadeIsentaSubSindico,
          unidade: { id: values.unidadeIsentaSubSindico },
        },
      }),
      ...(values.temPercentualIsencaoSubSindico && {
        temPercentualIsencaoSubSindico: values.temPercentualIsencaoSubSindico,
      }),
      // outros
      ...(values.conselheiros && { conselheiros: values.conselheiros }),
      ...(values.dataInicioMandato && { dataInicioMandato: values.dataInicioMandato }),
      ...(values.dataFimMandato && { dataFimMandato: values.dataFimMandato }),
      presidenteId: values.presidenteId,
    };

    if (sindico.dataFimMandato < sindico.dataInicioMandato)
      return sendErroRequest('A data fim de mandato deve ser maior que a data início!');

    dispatch(processandoSindico(true));

    await laris.post('/sindicos', sindico);

    dispatch(processandoSindico(false));

    createSuccessNotification('Sindico cadastrado com sucesso!');
    hashHistory.push('/sindicos');
  } catch (error) {
    dispatch(processandoSindico(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar as Sindicos!');
  }
};

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  pessoaLogada: {
    nome: null,
    tipo: null,
    empresa: null,
    empresaId: null,
    email: null,
    grupoUsuarioId: null,
    pessoaUsuarioJaExiste: false,
  },
  pessoaJaLogada: false,
};

export default function(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case a.USR_GET_PESSOA_LOGADA:
      return {
        ...state,
        pessoaLogada: {
          id: payload.id,
          nome: payload.nome,
          tipo: payload.tipo,
          empresa: payload.empresa,
          empresaId: payload.empresaId,
          email: payload.email,
          grupoUsuarioId: payload.grupoUsuarioId,
          grupoUsuario: payload.grupoUsuario,
          pessoaUsuarioJaExiste: payload.pessoaUsuarioJaExiste,
        },
      };
    case a.USR_SET_ESTADO_INICIAL_USUARIO_LOGADO:
      return { ...state, pessoaLogada: {}, pessoaJaLogada: false };
    case a.USR_PESSOA_JA_LOGADA:
      return { ...state, pessoaJaLogada: payload };
    default:
      return { ...state };
  }
}

import React from 'react';

export const FormInputNumberInt = ({ field, form: { touched, errors }, ...props }) => {
  const { col, label, type, min } = props;
  const { value } = field;

  return (
    <div className={`col-lg-${col}`}>
      <div className={`form-group ${errors[field.name] ? 'has-error' : null}`}>
        <label style={{ fontWeight: 'bold' }}>{label}</label>
        <input
          type={type || 'number'}
          className="form-control required"
          {...field}
          {...props}
          value={value || ''}
          min={min || 0}
        />

        {touched[field.name] && errors[field.name] && (
          <span className="help-block m-b-none">{errors[field.name]}</span>
        )}
      </div>
    </div>
  );
};

import moment from 'moment';
import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { formatarNumeroDecimal, formatarNumeroGeral } from '../utils/Functions';
import { createSuccessNotification, sendErroRequest } from '../utils/Notification';

const { hashHistory } = hash;

export const getPatrimoniosSuccess = (response) => ({
  type: a.PTM_GET_PATRIMONIOS,
  payload: response,
});

export const processandoPatrimonio = (bool) => ({
  type: a.PTM_PROCESSANDO_PATRIMONIO,
  payload: bool,
});

export const setPatrimonioById = (data) => ({
  type: a.PTM_GET_PATRIMONIOS_BY_ID,
  payload: data,
});

const setEstadoInicial = () => ({
  type: a.PTM_SET_ESTADO_INICIAL,
});

export const getPatrimonios = () => async (dispatch) => {
  try {
    dispatch(processandoPatrimonio(true));
    const { data } = await laris.get(`/patrimonios`);

    dispatch(getPatrimoniosSuccess(data), processandoPatrimonio(false));
  } catch (err) {
    dispatch(processandoPatrimonio(false));
    sendErroRequest(err, 'Ocorreu um erro ao buscar as Patrimônios!');
  }
};

export const getPatrimonioById = (id) => async (dispatch) => {
  try {
    dispatch(processandoPatrimonio(true));

    const { data } = await laris.get(`/patrimonios/${id}`);
    dispatch(setPatrimonioById(data));
    dispatch(processandoPatrimonio(false));
  } catch (err) {
    dispatch(processandoPatrimonio(false));
    sendErroRequest(err, 'Ocorreu um erro ao buscar o Patrimônio selecionado!');
  }
};

export const deletePatrimonio = (id) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      await laris.delete(`/patrimonios/${id}`);
      dispatch(getPatrimonios('', 0));
      createSuccessNotification('Patrimônio excluído com sucesso!');
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao excluir o Patrimônio!');
  }
};

export const putPatrimonio = (values) => async (dispatch) => {
  try {
    dispatch(processandoPatrimonio(true));
    const params = {
      ...(values.tipo && {
        tipo: values.tipo,
      }),
      ...(values.natureza && {
        natureza: values.natureza,
      }),
      ...(values.descricao && {
        descricao: values.descricao,
      }),
      ...(values.numero && {
        numero: values.numero,
      }),
      ...(values.dataDeAquisicao && {
        dataDeAquisicao: moment(values.dataDeAquisicao),
        ...(values.valorDeAquisicao && {
          valorDeAquisicao: formatarNumeroGeral(values.valorDeAquisicao),
        }),
        ...(values.quantidade && { quantidade: parseInt(values.quantidade) }),
        ...(values.localId && { localId: values.localId }),
        ...(values.id && { id: values.id }),
        ...(values.observacao && { observacao: values.observacao }),
        ...(values.observacaoDebaixa && { observacaoDebaixa: values.observacaoDebaixa }),
        ...(values.valorDeBaixa && { valorDeBaixa: formatarNumeroGeral(values.valorDeBaixa) }),
        ...(values.dataDeBaixa && { dataDeBaixa: moment(values.dataDeBaixa) }),
      }),
    };

    await laris.put(`/patrimonios`, params);

    dispatch(processandoPatrimonio(false));
    createSuccessNotification('Patrimônio alterado com sucesso!');
    hashHistory.push('/patrimonios');
  } catch (err) {
    dispatch(processandoPatrimonio(false));
    sendErroRequest(err, 'Ocorreu um erro ao alterar o Patrimônio!');
  }
};

export const postPatrimonio = (values) => async (dispatch) => {
  try {
    const params = {
      tipo: values.tipo,

      natureza: values.natureza,

      ...(values.descricao && {
        descricao: values.descricao,
      }),
      ...(values.numero && {
        numero: values.numero,
      }),
      ...(values.dataDeAquisicao && {
        dataDeAquisicao: moment(values.dataDeAquisicao),
        ...(values.valorDeAquisicao && {
          valorDeAquisicao: formatarNumeroGeral(values.valorDeAquisicao),
        }),
        ...(values.quantidade && { quantidade: parseInt(values.quantidade) }),
        ...(values.localId && { localId: values.localId }),
        ...(values.id && { id: values.id }),
        ...(values.observacao && { observacao: values.observacao }),
        ...(values.observacaoDebaixa && { observacaoDebaixa: values.observacaoDebaixa }),
        ...(values.valorDeBaixa && { valorDeBaixa: formatarNumeroGeral(values.valorDeBaixa) }),
        ...(values.dataDeBaixa && { dataDeBaixa: moment(values.dataDeBaixa) }),
      }),
    };

    await laris.post(`/patrimonios`, params);

    dispatch(processandoPatrimonio(false));
    createSuccessNotification('Patrimônio cadastrado com sucesso');

    hashHistory.push('/patrimonios');
  } catch (err) {
    dispatch(processandoPatrimonio(false));
    sendErroRequest(err, 'Ocorreu um erro ao cadastrar o Patrimônio!');
  }
};

import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { createSuccessNotification, sendErroRequest } from '../utils/Notification';

const { hashHistory } = hash;

export const setLocais = (response) => ({
  type: a.LOC_GET_LOCAIS,
  payload: response,
});

export const processandoLocal = (bool) => ({
  type: a.LOC_PROCESSANDO_LOCAL,
  payload: bool,
});

const setEstadoInicial = () => (dispatch) =>
  dispatch({
    type: a.LOC_SET_ESTADO_INICIAL,
  });

export const seLocalById = (data) => (dispatch) =>
  dispatch({
    type: a.LOC_GET_LOCAL_BY_ID,
    payload: data,
  });

export const getLocais = () => async (dispatch) => {
  try {
    dispatch(processandoLocal(true));
    const { data } = await laris.get(`/locais`);

    dispatch(setLocais(data), processandoLocal(false));
  } catch (err) {
    dispatch(processandoLocal(false));
    sendErroRequest(err, 'Ocorreu um erro ao buscar os Locais!');
  }
};

export const getLocalById = (id) => async (dispatch) => {
  try {
    dispatch(processandoLocal(true));
    const { data } = await laris.get(`/locais/${id}`);
    dispatch(seLocalById(data));
    dispatch(processandoLocal(false));
  } catch (err) {
    dispatch(processandoLocal(false));
    sendErroRequest(err, 'Ocorreu um erro ao buscar o Local selecionado!');
  }
};

export const deleteLocal = (id) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      await laris.delete(`/locais/${id}`);

      dispatch(getLocais());
      createSuccessNotification('Local excluído com sucesso!');
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao excluir o Local!');
  }
};

export const putLocal = (values) => async (dispatch) => {
  try {
    dispatch(processandoLocal(true));
    await laris.put(`/locais`, values);

    dispatch(processandoLocal(false));
    createSuccessNotification('Local alterado com sucesso!');
    hashHistory.push('/locais');
  } catch (err) {
    dispatch(processandoLocal(false));
    sendErroRequest(err, 'Ocorreu um erro ao alterar o Local!');
  }
};

export const postLocal = (values) => async (dispatch) => {
  try {
    dispatch(processandoLocal(true));
    await laris.post(`/locais`, values);
    dispatch(processandoLocal(false));
    createSuccessNotification('Local cadastrado com sucesso');
    hashHistory.push('/locais');
  } catch (err) {
    dispatch(processandoLocal(false));
    sendErroRequest(err, 'Ocorreu um erro ao cadastrar o Local!');
  }
};

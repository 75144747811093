import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { formatarNumeroGeral, verIsNaN } from '../utils/Functions';
import {
  createSuccessNotification,
  sendErroRequest,
  createInfoNotification,
} from '../utils/Notification';
import { adicionarItemRelatorio } from './gerenciadorRelatorioActions';

const { hashHistory } = hash;

export const setEstadoInicialContas = () => (dispatch) =>
  dispatch({ type: a.CNT_SET_ESTADO_INICIAL });

export const getContasSuccess = (response) => ({
  type: a.CNT_GET_CONTAS,
  payload: response,
});

export const processandoConta = (bool) => ({
  type: a.CNT_PROCESSANDO_CONTA,
  payload: bool,
});
export const processandoUploadDaConta = (bool) => ({
  type: a.CNT_PROCESSANDO_UPLOAD_CONTA,
  payload: bool,
});

export const setContasReceita = (response) => ({
  type: a.CNT_GET_CONTAS_RECEITA,
  payload: response,
});

export const setContasDespesa = (response) => ({
  type: a.CNT_GET_CONTAS_DESPESA,
  payload: response,
});

export const setContasContraPartida = (response) => ({
  type: a.CNT_GET_CONTAS_CONTRA_PARTIDA,
  payload: response,
});

export const setContasSaldo = (response) => ({
  type: a.CNT_GET_CONTAS_SALDO,
  payload: response,
});

export const setContasSelecionadas = (contas) => (dispatch) =>
  dispatch({
    type: a.CNT_SET_CONTAS_SELECIONADAS,
    payload: contas,
  });

export const setContasNivel = (nivel, response) => {
  switch (nivel) {
    case 1:
      return {
        type: a.CNT_GET_CONTAS_NIVEL_UM,
        payload: response,
      };
    case 2:
      return {
        type: a.CNT_GET_CONTAS_NIVEL_DOIS,
        payload: response,
      };
    case 3:
      return {
        type: a.CNT_GET_CONTAS_NIVEL_TRES,
        payload: response,
      };
    default:
      break;
  }
};

export const setContasRecursoFinanceiro = (response) => ({
  type: a.CNT_SET_CONTAS_RECURSO_FINANCEIRO,
  payload: response,
});

export const adicionandoConta = (bool) => ({
  type: a.CNT_ADICIONANDO_CONTA,
  payload: bool,
});

export const setListaConta = (lista) => ({
  type: a.CNT_SET_LISTA_CONTAS,
  payload: lista,
});

export const getContas = () => async (dispatch) => {
  try {
    dispatch(processandoConta(true));
    const { data } = await laris.get(`/contas`);
    dispatch(getContasSuccess(data));
    dispatch(processandoConta(false));
  } catch (error) {
    dispatch(processandoConta(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar as contas!');
  }
};

export const adicionarContaAnalitica = (idConta) => (dispatch, getState) => {
  try {
    if (!idConta) return createSuccessNotification('Conta', 'Selecione uma conta!');

    const {
      conta: { contasNivelTres, contasSelecionadas },
    } = getState();

    if (contasSelecionadas.findIndex((item) => item.id == idConta) > -1)
      return createSuccessNotification(
        'Conta',
        'Não é possível adicionar a mesma conta duas vezes.',
      );

    const novaLista = [
      ...contasSelecionadas,
      ...contasNivelTres.filter((item) => item.id == idConta),
    ];

    dispatch(setContasSelecionadas(novaLista));
  } catch (err) {
    console.log(err);
  }
};

export const removerContaSelecionada = (idConta) => (dispatch, getState) => {
  try {
    const {
      conta: { contasSelecionadas },
    } = getState();

    dispatch(setContasSelecionadas(contasSelecionadas.filter((item) => item.id !== idConta)));
  } catch (err) {
    console.log(err);
  }
};

export const getContaById = (id) => async (dispatch) => {
  try {
    dispatch(processandoConta(true));

    const { data } = await laris.get(`/contas/${id}`);
    dispatch(getContaSaldoId(data));
    dispatch(processandoConta(false));
  } catch (error) {
    dispatch(processandoConta(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar a conta selecionada!');
  }
};

export const getContasRecursoFinanceiro = () => async (dispatch) => {
  try {
    const { data } = await laris.get(`/contas-recurso-financeiro/getContasRecursoFinanceiro`);

    dispatch(setContasRecursoFinanceiro(data));
  } catch (error) {
    sendErroRequest(error, 'Erro ao carregar as contas de recurso financeiro!');
  }
};

export const getContasNivel = (nivel) => async (dispatch) => {
  try {
    const { data } = await laris.get(`/contas/getContasNivel/${nivel}`);

    data.sort((a, b) => (a.descricao < b.descricao ? -1 : a.descricao > b.descricao ? 1 : 0));

    dispatch(setContasNivel(nivel, data));
  } catch (err) {
    sendErroRequest(err, `Ocorreu um erro ao buscar as contas de nivel ${nivel}!`);
  }
};

export const getContasReceita = () => async (dispatch) => {
  try {
    dispatch(processandoConta(true));

    const { data } = await laris.get(`/contas/getContasReceita`);

    dispatch(setContasReceita(data));
    dispatch(processandoConta(false));
  } catch (error) {
    dispatch(processandoConta(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar as contas de receita!');
  }
};

export const getContasContraPartidaById = (id) => (dispatch) => {};

export const getContasSaldo = () => async (dispatch) => {
  try {
    dispatch(processandoConta(true));

    const {
      data: { contasSaldo },
    } = await laris.get(`/contas/getContasSaldo`);

    dispatch(setContasSaldo(contasSaldo));
    dispatch(processandoConta(false));
  } catch (err) {
    dispatch(processandoConta(false));
    sendErroRequest(err, 'Ocorreu um erro ao carregar as contas de saldo!');
  }
};

export const setContaRecursoFinanceiro = (cnt, checked, contasSaldo) => async (dispatch) => {
  try {
    dispatch(processandoConta(true));
    const { data } = await laris.post(
      `/contas-recurso-financeiro/setContaRecursoFinanceiro/${cnt.id}&${checked}`,
      {},
    );

    const novaLista = contasSaldo.map((item) => ({
      ...item,
      ehContaDeRecursoFinanceiro: item.id === cnt.id ? checked : item.ehContaDeRecursoFinanceiro,
    }));

    dispatch(setContasSaldo(novaLista));
    dispatch(processandoConta(false));
    createSuccessNotification(data.chave, data.mensagem);
  } catch (error) {
    dispatch(processandoConta(false));
    sendErroRequest(error, `Ocorreu um erro ao definir a conta como conta de recurso financeiro!`);
  }
};

export const getContasContraPartida = () => async (dispatch) => {
  try {
    dispatch(processandoConta(true));

    const { data } = await laris.get(`/contas-contra-partida/getContasContraPartida`);

    if (data) {
      dispatch(setContasContraPartida(data));
      dispatch(processandoConta(false));
    }
  } catch (err) {
    dispatch(processandoConta(false));
    sendErroRequest(err, `Ocorreu um erro ao buscar as contas de contra partida!`);
  }
};

export const postContaContraPartida = (targetKeys, contaContraPartida) => async (dispatch) => {
  try {
    if (!contaContraPartida)
      return sendErroRequest('Conta', 'Selecione uma conta para contra partida');

    if (!targetKeys) return;

    const contas = contaContraPartida.map((conta) => conta.id);

    await laris.post(`/contas-contra-partida/setContasContraPartida/${targetKeys}`, contas);

    createSuccessNotification('As contas foram atualizadas com sucesso!');
    hashHistory.push('contas-contra-partida');
  } catch (error) {
    sendErroRequest(
      error,
      `Ocorreu um erro ao definir as contas de contra partida! Tente novamente mais tarde!`,
    );
  }
};
export const getContaSaldoId = (response) => ({
  type: a.CNT_GET_CONTA_ID_SALDO,
  payload: response,
});

export const putContaContraPartida = (targetKeys, contaContraPartida) => async (dispatch) => {
  try {
    if (!contaContraPartida) return sendErroRequest('Selecione uma conta para contra partida');

    if (!targetKeys) return;

    const contas = contaContraPartida.map((conta) => conta.id);

    await laris.put(`/contas-contra-partida/setContasContraPartida/${targetKeys}`, contas);

    createSuccessNotification(`Foram atualizadas contas  com sucesso!`);
    hashHistory.push('/contas-contra-partida');
  } catch (error) {
    sendErroRequest(
      error,
      `Ocorreu um erro ao definir as contas de contra partida! Tente novamente mais tarde!`,
    );
  }
};

export const getContasDespesa = () => async (dispatch) => {
  try {
    dispatch(processandoConta(true));

    const { data } = await laris.get(`/contas/getContasDespesa`);

    dispatch(setContasDespesa(data));
    dispatch(processandoConta(false));
  } catch (err) {
    dispatch(processandoConta(false));
    sendErroRequest(err, 'Ocorreu um erro inesperado ao tentar buscar as contas de despesa!');
  }
};

export const putConta = (values) => async (dispatch) => {
  try {
    dispatch(processandoConta(true));
    await laris.put(`/contas`, values);
    dispatch(processandoConta(false));
    hashHistory.push('/contas');
    createSuccessNotification('Conta alterada com sucesso!');
  } catch (error) {
    dispatch(processandoConta(false));
    sendErroRequest(error, 'Ocorreu um erro ao alterar a conta!');
  }
};

export const postConta = (values) => async (dispatch) => {
  try {
    dispatch(processandoConta(true));

    await laris.post(`/contas`, values);

    createSuccessNotification('Conta cadastrada com sucesso!');
    dispatch(getContas());
    dispatch(processandoConta(false));
    hashHistory.push('/contas');
  } catch (error) {
    dispatch(processandoConta(false));
    sendErroRequest(error, 'Ocorreu um erro ao alterar a conta!');
  }
};

export const buscarSaldoConta = (values) => async (dispatch) => {
  try {
    dispatch(processandoConta(true));
    const { data } = await laris.get(`/contas/get-saldo-inicial-conta/${values.id}`);

    dispatch(getContaSaldoId(data));
    dispatch(processandoConta(false));
    return data;
  } catch (error) {
    dispatch(processandoConta(true));
    sendErroRequest(error, 'Ocorreu um erro ao buscar o saldo da conta!');
  }
};

export const editarSaldoConta = (values) => async (dispatch) => {
  try {
    const { id } = values;
    const saldo = formatarNumeroGeral(values.saldo);

    await laris.put(`/contas/atualizar-saldo-inicial-conta/${id}&${saldo}`);
    dispatch(getContaSaldoId(null));
    createSuccessNotification('Conta alterado com sucesso!');
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao atualizar o saldo da conta!');
  }
};

export const deleteConta = (id) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmação de exclusão',
      text: 'Você tem certeza que deseja deletar essa conta?',
      footer:
        '<small style="text-align: center;"><strong>IMPORTANTE</strong>:<div>Caso a conta possua lançamentos ou previsões a mesma será apenas desativada.</div></small>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
    });
    if (res.value) {
      createInfoNotification('Se houver movimentação  a conta será apenas desativada!');
      await laris.delete(`/contas/${id}`);
      dispatch(getContas());
      createSuccessNotification('Conta excluída ou desativada com sucesso!');
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao exluir a conta!');
  }
};

export const addContaLista = (value, lista) => (dispatch) => {
  try {
    if (value === null) return createInfoNotification('É necessário selecionar uma conta !');

    if (lista.filter((conta) => conta.id === value.id).length > 0)
      return createInfoNotification('É necessário uma conta diferente!');
    const novaDescricao = `${value.codigoNormal} - ${value.descricao}`;
    dispatch(adicionandoConta(true));

    const itemLista = {
      novaDescicao: novaDescricao,
      ...value,
    };

    const novaLista = [...lista, itemLista];

    dispatch(setListaConta(novaLista));
    dispatch(adicionandoConta(false));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao adicionar a conta!');
  }
};

export const removeContaLista = (value, lista) => (dispatch) => {
  try {
    dispatch(adicionandoConta(true));
    const novaLista = [...lista.filter((contas) => contas.id !== value)];

    dispatch(setListaConta(novaLista));
    setTimeout(() => dispatch(adicionandoConta(false)), 1);
  } catch (error) {
    dispatch(adicionandoConta(false));
    sendErroRequest(error, 'Erro ao remover uma conta da lista!');
  }
};

export const getContasPromiseByIdConta = (idConta) => async (dispatch) => {
  try {
    if (idConta !== null) {
      const { data } = await laris.get(`/contas-contra-partida/contas-disponiveis/${idConta}`);

      return data;
    }
  } catch (error) {
    sendErroRequest(error, 'erro ao buscar as contas selecionadas!');
  }
};

export const getImportarPlanilhaContas = (resp) => async (dispatch) => {
  try {
    dispatch(processandoUploadDaConta(true));
    dispatch(processandoConta(true));
    const arrayrows = resp.rows.slice(5);
    const novoArray = arrayrows.filter((li) => li.length > 0 && li.indexOf('limite') < 0);
    const quantidadeLinha = novoArray.length;
    const listaDadosImportados = [];
    for (let index = 0; index < quantidadeLinha; index += 1) {
      const arrayLinhasPreparada = arrayrows[index];
      const dados = {
        codigoNormal: arrayLinhasPreparada[1],
        descricao: arrayLinhasPreparada[2],
        tipoConta: arrayLinhasPreparada[3],
        saldoInicialConta: isNaN(arrayLinhasPreparada[4])
          ? undefined
          : formatarNumeroGeral(arrayLinhasPreparada[4]),
      };
      listaDadosImportados.push(dados);
    }

    const verificaCodigo = verIsNaN(listaDadosImportados);

    if (verificaCodigo === false) {
      dispatch(processandoUploadDaConta(false));
      dispatch(processandoConta(false));
      return createInfoNotification(
        'O Código da Conta está fora do padrão, verifique as descrições da planilha !',
      );
    }
    const { data } = await laris.post(`/contas/upload-planilha-plano-conta`, listaDadosImportados);
    dispatch(getContasSuccess(data));
    dispatch(processandoUploadDaConta(false));
    dispatch(processandoConta(false));
    createSuccessNotification('Contas importadas com Sucesso da planilha!');
  } catch (error) {
    sendErroRequest(error, 'Erro ao fazer o upload da planilha ou dados incorretos!');
    dispatch(processandoUploadDaConta(false));
    dispatch(processandoConta(false));
  }
};

export const postExportarContas = (contas) => async (dispatch) => {
  try {
    createInfoNotification('Solicitação feita com sucesso! aguarde a planilha.');
    const rowsValues = [];
    let rowInicial = 5;
    for (let index = 0; index < contas.length; index += 1) {
      const conta = contas[index];
      rowInicial += 1;
      const linha = {
        row: rowInicial,
        action: 2,
        columns: [
          {
            col: 2,
            value: conta.codigoNormal,
          },
          {
            col: 3,
            value: conta.descricao,
          },
          {
            col: 4,
            value: conta.ehContaAnalitica === true ? 'Analitica' : 'Sintetica',
          },
        ],
      };
      rowsValues.push(linha);
    }

    const json = {
      callback_error: 'https://webhook.site/12cfa114-8961-442a-8a26-7bf71549d6a0',
      identificationInResponse: '4554',
      sheets: [
        {
          position: 0,
          rowsValues,
        },
      ],
    };

    const { data } = await laris.post('/contas/post-export-upload-planilha', json);

    const item = {
      dataEmissao: new Date(),
      nome: 'Planilha_Contas_Padrao',
      url: data.retorno,
    };

    dispatch(adicionarItemRelatorio(item));
  } catch (error) {
    sendErroRequest(error, 'Erro ao fazer ao exportar a planilha de contas!');
  }
};

export const alteraStatusConta = (id, status) => async (dispatch, getState) => {
  try {
    const { data } = await laris.get(`/contas/altera-status/${id}/${status}`);
    if (data.sucesso) {
      createSuccessNotification('Status da Conta atualizado com sucesso.');
      const { contas } = getState().conta;
      const newContasList = contas.map((conta) => {
        if (conta.id === id) return { ...conta, status, deletado: !status };
        return conta;
      });

      dispatch(getContasSuccess(newContasList));
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao atualizar o status da conta');
  }
};

import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const RouteWithSubRoutes = ({ component: Component, ...rest }) => {

  return (
    <Route
      path={rest.path}
      render={props =>
        rest.permitido && Component !== undefined ? (
          <Component {...props} routes={rest} />
        ) : (
          <Redirect to={{ pathname: '/acesso-nao-autorizado', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default RouteWithSubRoutes;

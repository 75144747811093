import { CheckBox } from 'devextreme-react/check-box';
import React from 'react';

export const FormCheckBox = ({ field, form: { touched, errors, setFieldValue }, ...props }) => {
  const { col, label, width, onChange, onValueChanged } = props;
  const { name } = field;

  return (
    <div className={`col-lg-${col}`}>
      <div className={`form-group ${errors[field.name] ? 'has-error' : null}`}>
        <CheckBox
          {...props}
          name={name}
          text={label}
          width={width}
          onValueChanged={option => {
            setFieldValue(field.name, option ? option.value : '');
            onChange && onChange(option);
            onValueChanged && onValueChanged(option);
          }}
        />

        {touched[field.name] && errors[field.name] && (
          <span className="help-block m-b-none">{errors[field.name]}</span>
        )}
      </div>
    </div>
  );
};

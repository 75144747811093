import React from 'react';
import { RadioGroup } from 'devextreme-react';

export const FormInputRadio = ({
  field,
  form: { touched, errors, setFieldValue },
  onChange,
  ...props
}) => {
  const { col, label, items, layout, defaultValue, valueExpr, onValueChanged } = props;

  return (
    <div className={`col-lg-${col}`}>
      <div className={`form-group ${errors[field.name] ? 'has-error' : null}`}>
        <label style={{ fontWeight: 'bold' }}>{label}</label>
        <RadioGroup
          valueExpr={valueExpr || 'value'}
          items={items}
          defaultValue={defaultValue}
          layout={layout || 'horizontal'}
          onValueChanged={option => {
            setFieldValue(field.name, option ? option.value : '');
            onValueChanged && onValueChanged(option);
            onChange && onChange(option);
          }}
        />

        {touched[field.name] && errors[field.name] && (
          <span className="help-block m-b-none">{errors[field.name]}</span>
        )}
      </div>
    </div>
  );
};

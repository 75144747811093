import jQuery from 'jquery';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import MetisMenu from 'metismenujs';

import MenuItem from './menuItem';
import MenuTree from './menuTree';
import { correctHeight } from '../helpers';

import miniLogo from '../../assets/images/img/logo-laris.png';

class MM extends React.Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    this.mm('dispose');
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="nav metismenu" ref={el => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const Navigation = ({ location: { pathname }, rota, pessoaLogada }) => {
  useEffect(() => {
    jQuery('.metismenu a').click(() => {
      setTimeout(() => {
        correctHeight();
      }, 300);
    });

    if (jQuery('body').hasClass('fixed-sidebar')) {
      jQuery('.sidebar-collapse').slimscroll({
        height: '100%',
      });
    }
  });

  const renderMenuItemTree = menu => (
    <MenuItem
      key={menu.funcaoId}
      location={pathname}
      path={`#${menu.rota}`}
      icon={menu.icone}
      label={menu.funcaoDescricao}
      tree
    />
  );

  const renderMenuItem = menu => (
    <MenuItem
      key={menu.funcaoId}
      location={pathname}
      path={`#${menu.rota}`}
      icon={menu.icone}
      label={menu.funcaoDescricao}
    />
  );

  const renderMenuTree = menu => (
    <MenuTree key={menu.funcaoId} icon={menu.icone} label={menu.funcaoDescricao}>
      {menu.menus.map(item => {
        if (item.menus && item.menus.length > 0) {
          return renderMenuTree(item);
        }
        return renderMenuItemTree(item);
      })}
    </MenuTree>
  );

  return (
    <nav className="navbar-default navbar-static-side" role="navigation" style={{ zIndex: 500 }}>
      <div className="sidebar-collapse">
        <nav
          className="navbar-default navbar-static-side"
          role="navigation"
          style={{ zIndex: 500 }}
        >
          <div className="sidebar-collapse">
            <MM>
              <li className="nav-header">
                <div className="dropdown profile-element" style={{ textAlign: 'center' }}>
                  <img
                    alt="profile"
                    className="rounded-circle"
                    style={{ width: 63.99, height: 63.99 }}
                    src={miniLogo}
                  />

                  <a
                    data-toggle="dropdown"
                    className="dropdown-toggle"
                    href="#"
                    style={{ textDecoration: 'none', cursor: 'default' }}
                  >
                    {' '}
                    <span className="clear">
                      <span className="block m-t-xs">
                        <strong className="font-bold">{pessoaLogada.nome}</strong>
                      </span>
                    </span>
                  </a>

                  <b style={{ color: 'white', fontWeight: '13px bold' }}>
                    <span className="clear">
                      <span className="block m-t-xs">
                        <small>{pessoaLogada.empresa}</small>
                      </span>
                    </span>
                  </b>
                </div>
                <div className="logo-element">
                  <img alt="mini-logo" className="img-sm" src={miniLogo} />
                </div>
              </li>
              <MenuItem path="#/home" location="/home" icon="home" label="Home" />

              {rota &&
                rota.menus.map(menu => {
                  if (menu.permitido) {
                    if (menu.menus && menu.menus.length > 0) {
                      return renderMenuTree(menu);
                    }
                    return renderMenuItem(menu);
                  }

                  return undefined;
                })}
            </MM>
          </div>
        </nav>
      </div>
    </nav>
  );
};

const mapStateToProps = state => ({
  rota: state.rota,
  pessoaLogada: state.usuario.pessoaLogada,
});

export default withRouter(connect(mapStateToProps)(Navigation));

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  dadosFatura: {
    labels: [],
    values: [],
    coresPrimarias: [],
    coresSecundarias: [],
  },
  processandoFatura: false,
  dadosUtilizacaoPortal: {
    labels: [],
    values: [],
    coresPrimarias: [],
    coresSecundarias: [],
  },
  processandoUtilizacaoPortal: false,
  dadosVisualizacaoBoleto: {
    labels: [],
    values: [],
    coresPrimarias: [],
    coresSecundarias: [],
  },
  processandoVisualizacaoBoleto: false,
  dadosEvolucaoTaxas: {
    labels: [],
    values: [],
    coresPrimarias: [],
    coresSecundarias: [],
  },
  processandoEvolucaoTaxas: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.DASH_SET_ESTADO_INICIAL:
      return {
        ...state,
        dadosFatura: {
          labels: [],
          values: [],
          coresPrimarias: [],
          coresSecundarias: [],
        },
        processandoFatura: false,
        dadosUtilizacaoPortal: {
          labels: [],
          values: [],
          coresPrimarias: [],
          coresSecundarias: [],
        },
        processandoUtilizacaoPortal: false,
        dadosVisualizacaoBoleto: {
          labels: [],
          values: [],
          coresPrimarias: [],
          coresSecundarias: [],
        },
        processandoVisualizacaoBoleto: false,
        dadosEvolucaoTaxas: {
          labels: [],
          values: [],
          coresPrimarias: [],
          coresSecundarias: [],
        },
        processandoEvolucaoTaxas: false,
      };
    case a.DASH_FATURA_PROCESSANDO:
      return { ...state, processandoFatura: payload };
    case a.DASH_FATURA_SET_DADOS:
      return { ...state, dadosFatura: payload };
    case a.DASH_UTILIZACAO_PORTAL_PROCESSANDO:
      return { ...state, processandoUtilizacaoPortal: payload };
    case a.DASH_UTILIZACAO_PORTAL_SET_DADOS:
      return { ...state, dadosUtilizacaoPortal: payload };
    case a.DASH_VISUALIZACAO_BOLETOS_PROCESSANDO:
      return { ...state, processandoVisualizacaoBoleto: payload };
    case a.DASH_VISUALIZACAO_BOLETOS_SET_DADOS:
      return { ...state, dadosVisualizacaoBoleto: payload };
    case a.DASH_EVOLUCAO_TAXAS_PROCESSANDO:
      return { ...state, processandoEvolucaoTaxas: payload };
    case a.DASH_EVOLUCAO_TAXAS_SET_DADOS:
      return { ...state, dadosEvolucaoTaxas: payload };
    default:
      return { ...state };
  }
};

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  patrimonios: [],
  processandoPatrimonio: false,
  patrimonioById: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.PTM_SET_ESTADO_INICIAL:
      return { ...state, processandoPatrimonio: false, patrimonios: [] };
    case a.PTM_GET_PATRIMONIOS:
      return { ...state, patrimonios: payload };
    case a.PTM_PROCESSANDO_PATRIMONIO:
      return { ...state, processandoPatrimonio: payload };
    case a.PTM_GET_PATRIMONIOS_BY_ID:
      return { ...state, patrimonioById: payload };
    default:
      return { ...state };
  }
};

import React from 'react';
import ReactLoading from 'react-loading';

import { Article, Section, Title } from '../../utils/generic';

export default ({ title, type }) => (
  <Section className="react-loading">
    <Title>{title}</Title>
    <Article>
      <ReactLoading type={type} width="30%" />
    </Article>
  </Section>
);

import axios from 'axios';
import hash from 'react-router-history';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { newGuid, formatarNumeroGeral } from '../utils/Functions';
import {
  createSuccessNotification,
  sendErroRequest,
  createInfoNotification,
} from '../utils/Notification';
import { setCidades, getCidadeByIdEstado } from './comumActions';

const { hashHistory } = hash;
export const getParametrosSuccess = response => ({
  type: a.PRM_GET_PARAMETROS,
  payload: response,
});

export const processandoParametro = bool => ({
  type: a.PRM_PROCESSANDO_PARAMETRO,
  payload: bool,
});

const setListaEmails = emails => ({
  type: a.PRM_SET_LISTA_EMAILS_CONDOMINIO,
  payload: emails,
});

export const getParametros = () => async (dispatch) => {
  try {
    dispatch(processandoParametro(true));

    const { data } = await laris.get(`/parametros`);
    dispatch(getParametrosSuccess(data));
    if (data.condominio && data.condominio.emails.length > 0)
      dispatch(setListaEmails(data.condominio.emails));

    dispatch(processandoParametro(false));
  } catch (error) {
    dispatch(processandoParametro(false));
    sendErroRequest(error, 'Ocorreu um erro ao carregar os Parametros!');
  }
};

// const buscarCidadePorIdUf = id => {
//   return new Promise((resolve, reject) => {
//     if (id) {
//       axios
//         .get(`http://servicodados.ibge.gov.br/api/v1/localidades/estados/${id}/municipios`)
//         .then(res => {
//           resolve(res);
//         })
//         .catch(err => {
//           reject(err);
//         });
//     } else {
//       reject(null);
//     }
//   });
// };

export const validaEmail = (email) => {
  if (!email) return false;

  if (email.indexOf('@') > -1 && email.indexOf('.') > -1) return true;

  return false;
};
// export const getDadosCep = (values) => async (dispatch) => {
//   try {
//     if (!values) return createInfoNotification('É necessário informar um CEP válido.');

//     const cep = values.replace(/[^0-9]/g, '');

//     const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

//     if (data.erro) {
//       return sendErroRequest('Não foi possível encontrar o endereço pelo cep informado.');
//     }
//     return data;
//   } catch (error) {
//     sendErroRequest(error, 'Não foi possível encontrar o endereço pelo cep informado.');
//   }
// };

export const addEmailCondominio = (value, listaEmails) => async (dispatch) => {
  try {
    if (!value) return createInfoNotification('Informe um email para adicionalo a lista');

    if (!validaEmail(value))
      return createInfoNotification('Erro ao tentar adicionar um email. Email inválido!');

    const novoEmail = {
      id: newGuid,
      descricao: value,
    };

    const novaLista = [...listaEmails, novoEmail];

    dispatch(setListaEmails(novaLista));
  } catch (error) {
    sendErroRequest(error, 'Erro ao adicionar email!');
  }
};

export const removeEmailCondominio = (email, listaEmails) => (dispatch) => {
  const novaLista = listaEmails.filter(item => item.id !== email.id);

  dispatch(setListaEmails(novaLista));
};

export const putParametro = (parametroForm, listaEmails, cidades, estados) => async (dispatch) => {
  try {
    if (listaEmails.length === 0)
      return createInfoNotification(
        'É necessário inserir pelo menos um email no condomínio para concluir o cadastro dos parâmetros!',
      );

    const param = {
      id: parametroForm.id,
      condominio: {
        descricao: parametroForm.condominioDescricao,
        endereco: parametroForm.condominioEndereco,
        bairro: parametroForm.condominioBairro,
        cep: parametroForm.condominioCep,
        telefoneFixo: parametroForm.condominioTelefoneFixo,
        telefoneCelular: parametroForm.condominioTelefoneCelular,
        qtdDiasReserva: parametroForm.condominioQtdDiasReserva,
      },
      ...(parametroForm.portadorId !== '' && { portador_Id: parametroForm.portadorId }),
      contaGas: parametroForm.contaGasId && { id: parametroForm.contaGasId },
      contaEnergia: parametroForm.contaEnergiaId && { id: parametroForm.contaEnergiaId },
      taxaCondominio: { id: parametroForm.taxaCondominioId },
      taxaFixa: parametroForm.taxaFixa && { id: parametroForm?.taxaFixa },
      taxaAcordo: parametroForm.taxaAcordoId && { id: parametroForm.taxaAcordoId },
      contaTaxaFixa: parametroForm.contaTaxaFixaId && { id: parametroForm.contaTaxaFixaId },
      logoTipo: parametroForm.logotipo,
      quantidadeDeDiasParaInadimplencia: parametroForm.quantidadeDeDiasParaInadimplencia,
      contaGrupoReceita: { id: parametroForm.contaGrupoReceitaId },
      contaGrupoDespesa: { id: parametroForm.contaGrupoDespesaId },
      contaGrupoSaldo: { id: parametroForm.contaGrupoSaldoId },
      ...(parametroForm.sindicoId !== '' && { sindico_Id: parametroForm.sindicoId }),
      contaCreditoUnidade: { id: parametroForm.contaCreditoUnidadeId },
      contaReceitaJuro: { id: parametroForm.contaReceitaJuroId },
      contaReceitaMulta: { id: parametroForm.contaReceitaMultaId },
      contaReceitaDesconto: { id: parametroForm.contaReceitaDescontoId },
      contaReceitaAcrescimo: { id: parametroForm.contaReceitaAcrescimoId },
      contaReceitaFundoReserva: { id: parametroForm.contaReceitaFundoReservaId },
      contaAcordo: parametroForm.contaAcordo && { id: parametroForm.contaAcordo },
      contaTaxaMudanca: parametroForm.contaTaxaMudancaId && {
        id: parametroForm.contaTaxaMudancaId,
      },
      contaTaxaSalaoFesta: parametroForm.contaTaxaSalaoFestaId && {
        id: parametroForm.contaTaxaSalaoFestaId,
      },
      descricaoDePrevisaoNoBoleto: parametroForm.descricaoDePrevisaoNoBoleto,
      prazoMaximoEmDiasParaCondominoRecalcularFatura:
        parametroForm.prazoMaximoEmDiasParaCondominoRecalcularFatura,
      mostreDividaNoBoleto: parametroForm.mostreDividaNoBoleto,
      enviaInadimplencia: parametroForm.enviaInadimplencia,
      diaVencimento: parametroForm.diaVencimento,
      tituloDoResponsavelDoCondominio: parametroForm.tituloDoResponsavelDoCondominio,
      tituloDoSubResponsavelDoCondominio: parametroForm.tituloDoSubResponsavelDoCondominio,
      cienteDoTermoDeResponsabilidadeParaEmissaoDoBoleto:
        parametroForm.cienteDoTermoDeResponsabilidadeParaEmissaoDoBoleto,
      considereDespesasOrdinariasParaCalculoDoFundoDeReserva:
        parametroForm.considereDespesasOrdinariasParaCalculoDoFundoDeReserva,
      considereDespesasExtraordinariasParaCalculoDoFundoDeReserva:
        parametroForm.considereDespesasExtraordinariasParaCalculoDoFundoDeReserva,
      considereLeiturasDeConsumoParaCalculoDoFundoDeReserva:
        parametroForm.considereLeiturasDeConsumoParaCalculoDoFundoDeReserva,
      instrucaoBancariaQueSubstituiTodasEmFaturaRecalculada:
        parametroForm.instrucaoBancariaQueSubstituiTodasEmFaturaRecalculada,
      contaGrupoTransferenciaCredito: { id: parametroForm.contaGrupoTransferenciaCreditoId },
      contaGrupoTransferenciaDebito: { id: parametroForm.contaGrupoTransferenciaDebitoId },
      considereDespesasOrdinariasParaCalculoDeUnidadesIsentas:
        parametroForm.considereDespesasOrdinariasParaCalculoDeUnidadesIsentas,
      considereDespesasExtraordinariasParaCalculoDeUnidadesIsentas:
        parametroForm.considereDespesasExtraordinariasParaCalculoDeUnidadesIsentas,
      cobraTaxaFixa: parametroForm.cobraTaxaFixa,
      valorTaxaAreaUnidade: parametroForm.valorTaxaAreaUnidade,
      mostreDividaDaUnidadeNoBoleto: parametroForm.mostreDividaDaUnidadeNoBoleto,
      emitirRecebimentosNoBoleto: parametroForm.emitirRecebimentosNoBoleto,
      modeloBoleto: parametroForm.modeloBoleto,
      enviaTextoEmailPersonalizado: parametroForm.enviaTextoEmailPersonalizado,
      detalharDespesasDaUnidadeNoBoleto: parametroForm.detalharDespesasDaUnidadeNoBoleto,
      contaHonorariosAdvocaticios: parametroForm.contaHonorariosAdvocaticiosId && {
        id: parametroForm.contaHonorariosAdvocaticiosId,
      },
      somaHonorarioAdvocatacioNaFatura: parametroForm.somaHonorarioAdvocatacioNaFatura,
      textoEnvioEmail: parametroForm.textoEnvioEmail,
      tituloDetalhamentoDespesasBoleto: parametroForm.tituloDetalhamentoDespesasBoleto,
      textoAssuntoEmail: parametroForm.textoAssuntoEmail,
      tituloDespesasExtraordinarias: parametroForm.tituloDespesasExtraordinarias,
      tipoValorFundoReserva: parametroForm.tipoValorFundoReserva,
      valorFundoReserva: formatarNumeroGeral(parametroForm.valorFundoReserva),
      Contador: {
        nome: parametroForm.contadorNome,
        ufCrc: parametroForm.contadorUf,
        crc: parametroForm.contadorCrc,
      },
      fusoHorario: parametroForm.fusoHorario,
      calcularFundoReservaSobre: parametroForm.calcularFundoReservaSobre,
      permiteAlteracaoDoValorDasParcelasSimuladasNoAcordo:
        parametroForm.permiteAlteracaoDoValorDasParcelasSimuladasNoAcordo,
      emitirDescricaoDaunidadeJunto: parametroForm.emitirDescricaoDaunidadeJunto,
      contaAgua: parametroForm.contaAguaId && { id: parametroForm.contaAguaId },
      emails: listaEmails,
    };

    await dispatch(getCidadeByIdEstado(parametroForm.condominioUf));

    const cidadeSelecionada = cidades.find(
      ci => ci.id === parametroForm.condominioCidade,
    );
    const estadoSelecionado = estados.find(estado => estado.id === parametroForm.condominioUf);

    param.condominio.cidade = {
      id: cidadeSelecionada.id,
      nome: cidadeSelecionada.nome,
      uf: {
        id: estadoSelecionado.id,
        sigla: estadoSelecionado.sigla,
        nome: estadoSelecionado.nome,
      },
    };

    const form = {
      ...param,
      condominio: {
        ...param.condominio,
        emails: listaEmails,
      },
    };

    dispatch(processandoParametro(true));

    await laris.put(`/parametros`, form);

    createSuccessNotification(
      'Parâmetros alterado! Atualize a página para atualizar as informações',
    );
    dispatch(processandoParametro(false));
    dispatch(getParametros());
    hashHistory.push('/parametros');
  } catch (error) {
    dispatch(processandoParametro(false));
    sendErroRequest(error, 'Ocorreu um erro ao alterar o Parametro!');
  }
};

// export const setLogoTipo = logoUrl => dispatch =>
//   dispatch(change('parametroForm', 'logoTipo', logoUrl));

export const salvarImagem = imagem => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append('files', imagem);

    const { data } = await laris.post('/parametros/upload-logo', formData);
    if (data) {
      createSuccessNotification('Imagem adicionado com sucesso!');
      return data;
    }
  } catch (error) {
    sendErroRequest(error, 'Erro ao fazer upload da imagem');
  }
};

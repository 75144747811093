import React from 'react';
import { Formik, Field } from 'formik';
import { FormSelect, FormDate } from '../../utils/Inspinia';
import { movLeituraConsumo } from '../../utils/validations';

export default function FiltroLeituraConsumo({ onSubmit, tiposConsumo, unidades }) {
  const initialValues = {
    unidadeId: null,
    periodoInicial: new Date(),
    periodoFinal: new Date(),
    tipoDoConsumo: '',
  };

  function handleSubmit(values) {
    onSubmit(values);
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={movLeituraConsumo}
    >
      {props => (
        <>
          <div className="row">
            <Field
              placeholder="Pesquisar unidade..."
              name="unidadeId"
              label="Unidade:"
              component={FormSelect}
              col="4"
              options={unidades}
            />
            <div className="col-md-2">
              <div className="form-group">
                <Field
                  component={FormSelect}
                  label="Tipo consumo:"
                  options={tiposConsumo}
                  name="tipoDoConsumo"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <Field component={FormDate} name="periodoInicial" label="Período Inicial:" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <Field component={FormDate} name="periodoFinal" label="Período Final:" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <button className="btn btn-primary " type="button" onClick={props.handleSubmit}>
                  Buscar Leituras de Consumo
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}

import React from 'react';
import Select from 'react-select';

export const FormSelect = ({ field, form: { touched, errors, setFieldValue }, ...props }) => {
  const {
    col,
    label,
    options,
    onChange,
    placeholder,
    isClearable,
    dataName = 'label',
    dataValue = 'value',
  } = props;

  const colorNavy = '#4585d3';
  const colourStyles = {
    control: (base, state) => ({
      ...base,
      '&:hover': { borderColor: state.isFocused ? colorNavy : '' },
      border: state.isFocused ? '1px solid #d3d3d3' : '',
      boxShadow: 'none',
    }),
    option: (base, state) => ({
      ...base,
      '&:hover': { backgroundColor: state.isSelected ? colorNavy : '#d3d3d3' },
      backgroundColor: state.isSelected ? colorNavy : 'transparent',
    }),
  };
  return (
    <div className={`col-lg-${col}`}>
      <div className={`form-group ${errors[field.name] ? 'has-error' : null}`}>
        <label className="control-label">{label}</label>
        <Select
          {...field}
          {...props}
          getOptionLabel={e => e[dataName]}
          getOptionValue={e => e[dataValue]}
          isClearable={isClearable || true}
          placeholder={placeholder || 'Selecione...'}
          styles={colourStyles}
          value={
            options && field.value !== ''
              ? options.find(option => option[dataValue] === field.value)
              : ''
          }
          onChange={option => {
            setFieldValue(field.name, option ? option[dataValue] : '');
            onChange && option && onChange(option);
          }}
          options={options}
          onBlur={field.onBlur}
        />
        {touched[field.name] && errors[field.name] && (
          <span className="help-block m-b-none has-error control-label">{errors[field.name]}</span>
        )}
      </div>
    </div>
  );
};

import * as types from '../actionsTypes/actionTypes';
import RouteComponents from '../router/RouteComponents';
import sentinela from '../api/sentinela';
import { sendErroRequest } from '../utils/Notification';

const ROTAS_DEFAULT = [
  {
    path: '/acesso-nao-autorizado',
    component: RouteComponents['/acesso-nao-autorizado'],
    permitido: true,
    exact: true,
  },
  {
    path: '/home',
    component: RouteComponents['/home'],
    permitido: true,
    exact: true,
  },
  {
    path: '/perfil-usuario',
    component: RouteComponents['/perfil-usuario'],
    exact: true,
    permitido: true,
  },
  {
    path: '/gerenciador-relatorios',
    component: RouteComponents['/gerenciador-relatorios'],
    permitido: true,
    exact: true,
  },
];

export const setRotasPermitidasSuccess = rotas => ({
  type: types.ROT_SET_ROTAS_MENUS_SUCCESS,
  payload: rotas,
});

export const setRotasPermitidasFail = () => ({
  type: types.ROT_SET_ROTAS_MENUS_FAIL,
});

export const getRotasPermitidas = () => async dispatch => {
  try {
    const { data } = await sentinela.get('/rotas/rotas-permitidas/2');

    const listaRotas = data.rotas.map(rota => ({
      path: rota.path,
      component: RouteComponents[rota.path],
      permitido: rota.permitido,
      exact: true,
    }));

    const rotaIndex = [
      {
        path: '/',
        component: RouteComponents['/dashboards'],
        permitido: true,
        exact: true,
      },
    ];

    dispatch(
      setRotasPermitidasSuccess({
        rotas: [...ROTAS_DEFAULT, ...listaRotas, ...rotaIndex],
        menus: data.menus,
      }),
    );
  } catch (error) {
    sendErroRequest(error, 'Não foi possível buscar as Rotas Permitidas');

    dispatch(setRotasPermitidasFail());
  }
};

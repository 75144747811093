import moment from 'moment';
import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { formatarNumeroGeral } from '../utils/Functions';
import {
  createSuccessNotification,
  sendErroRequest,
  createInfoNotification,
} from '../utils/Notification';
import LeituraConsumo from '../views/leituraDeConsumo';
import { adicionarItemRelatorio, processandoRelatorio } from './gerenciadorRelatorioActions';
import { getUnidades } from './unidadeActions';

const { hashHistory } = hash;
const setLeiturasConsumo = (response) => ({
  type: a.LDC_GET_LEITURAS_DE_CONSUMO,
  payload: response,
});

const processandoLeituraDeConsumo = (bool) => ({
  type: a.LDC_PROCESSANDO_LEITURA_DE_CONSUMO,
  payload: bool,
});

export const limparListaLeituraConsumo = () => (dispatch) => {
  dispatch(processandoLeituraDeConsumo(true));

  dispatch(setLeiturasConsumo([]));

  dispatch(processandoLeituraDeConsumo(false));
};

export const getLeiturasDeConsumo = (values, unidades) => async (dispatch) => {
  try {
    const filtro = {
      ...(values.periodoInicial && { dataInicial: values.periodoInicial }),
      ...(values.periodoFinal && { dataFinal: values.periodoFinal }),

      ...(values.tipoDoConsumo && { tipoDoConsumo: values.tipoDoConsumo }),
      unidadeId: values.unidadeId,
    };

    dispatch(processandoLeituraDeConsumo(true));
    const { data } = await laris.post(`/leituras-de-consumo/getLeiturasDeConsumoComFiltro`, filtro);

    dispatch(
      setLeiturasConsumo(
        data.map((item) => ({
          ...item,
          dataDeReferencia: moment(item.dataDeReferencia).format('L'),
        })),
      ),
    );
    dispatch(processandoLeituraDeConsumo(false));
  } catch (error) {
    dispatch(processandoLeituraDeConsumo(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar as Leituras De Consumo!');
  }
};

// export const getLeituraDeConsumoById = id => async dispatch => {
//   try {
//     dispatch(processandoLeituraDeConsumo(true));

//     const { data } = await laris.get(`/leituras-de-consumo/${id}`);

//     // dispatch([initialize('leituraDeConsumoForm', data), processandoLeituraDeConsumo(false)]);
//   } catch (error) {
//     dispatch(processandoLeituraDeConsumo(false));
//     sendErroRequest(error, 'Ocorreu um erro ao buscar a Leitura De Consumo selecionada!');
//   }
// };

export const deleteLeituraDeConsumo = (id, dataSource) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });
    if (!res) return;
    if (res.value) {
      const { data } = await laris.delete(`/leituras-de-consumo/${id}`);

      if (data.sucesso) {
        const listaLeituraConsumo = dataSource.filter((l) => l.id !== id);

        dispatch(setLeiturasConsumo(listaLeituraConsumo));
        createSuccessNotification('Leitura De Consumo excluído com sucesso!');
      } else {
        sendErroRequest('Leitura de consumo', 'Ocorreu um erro ao excluir a Leitura De Consumo!');
      }
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao excluir a Leitura De Consumo!');
  }
};

// export const putLeituraDeConsumo = () => async (dispatch, getState) => {
//   try {
//     const {
//       form: {
//         leituraConsumoForm: { values: leituraDeConsumo },
//       },
//     } = getState();

//     dispatch(processandoLeituraDeConsumo(true));

//     await laris.put(`/leituras-de-consumo`, leituraDeConsumo);

//     dispatch(processandoLeituraDeConsumo(false));
//     createSuccessNotification('Leitura De Consumo alterada com sucesso!');
//     hashHistory.push('/leituras-de-consumo');
//   } catch (error) {
//     dispatch(processandoLeituraDeConsumo(false));
//     sendErroRequest(error, 'Ocorreu um erro ao alterar a Leitura De Consumo!');
//   }
// };

export const postLeituraDeConsumo = (values) => async (dispatch) => {
  try {
    const medidorUnidade = values.unidade.medidores.filter(
      (m) => m.tipoDoLeitor === values.tipoDoConsumo,
    );
    if (medidorUnidade.length <= 0)
      return createInfoNotification(
        'É necessário ter medidor cadastrado para esse tipo de consumo',
      );
    const medidorNovo = values.medidor_Id !== undefined ? values.medidor_Id : medidorUnidade[0];

    const leitura = {
      ...(values.id && { id: values.id }),
      ...(values.unidade && { unidade: values.unidade }),
      ...(values.tipoDoConsumo && { tipoDoConsumo: values.tipoDoConsumo }),
      ...(medidorNovo && { medidor_Id: medidorNovo.id }),
      ...(values.lancaConsumo && { lancaConsumo: values.lancaConsumo }),
      ...(values.dataDeReferencia && { dataDeReferencia: values.dataDeReferencia }),
      ...(values.leituraAtual && { leituraAtual: formatarNumeroGeral(values.leituraAtual) }),
      ...(values.medidor_Id && {
        leituraAnterior: formatarNumeroGeral(values.medidor_Id.leituraAtual),
      }),
      ...(values.consumo && { consumo: formatarNumeroGeral(values.consumo) }),
    };

    if (values.leituraAtual < 0 || values.consumo < 0)
      return createInfoNotification('Não é possível registrar um consumo com valor negativo!');

    dispatch(processandoLeituraDeConsumo(true));
    await laris.post(`/leituras-de-consumo/podeInserir`, leitura);

    await laris.post(`/leituras-de-consumo`, leitura);

    dispatch(processandoLeituraDeConsumo(false));
    hashHistory.push('/leituras-de-consumo');
    createSuccessNotification('Leitura De Consumo cadastrada com sucesso');
  } catch (error) {
    dispatch(processandoLeituraDeConsumo(false));
    sendErroRequest(error, 'Ocorreu um erro ao cadastrar a Leitura De Consumo!');
  }
};

export const ExportLeituraConsumo = (lista) => async (dispatch) => {
  try {
    if (lista == null || lista.length <= 0)
      return createInfoNotification(
        'Lista vazia , é necessária filtrar as leituras para importação',
      );
    const rowsValues = [];
    let rowInicial = 5;
    for (let index = 0; index < lista.length; index += 1) {
      const boletoSelecionado = lista[index];
      rowInicial += 1;
      const linha = {
        row: rowInicial,
        action: 2,
        columns: [
          {
            col: 2,
            value: boletoSelecionado.unidade.id,
          },
          {
            col: 3,
            value: boletoSelecionado.unidade.unidadeBasica,
          },
          {
            col: 4,
            value: boletoSelecionado.condomino,
          },

          {
            col: 5,
            value: boletoSelecionado.leituraAtual,
          },
        ],
      };
      rowsValues.push(linha);
    }

    const json = {
      callback_error: 'https://webhook.site/12cfa114-8961-442a-8a26-7bf71549d6a0',
      identificationInResponse: '4554',
      sheets: [
        {
          position: 0,
          rowsValues,
        },
      ],
    };

    createSuccessNotification(
      'Requisição feita com sucesso ,Aguarde até que seja gerado a Planilha',
    );
    dispatch(processandoLeituraDeConsumo(false));

    const { data } = await laris.post('/leituras-de-consumo/export-planilha-leitura-consumo', json);

    const item = {
      id: data.chave,
      dataEmissao: new Date(),
      nome: 'Planilha_Leituras_Consumo',
      url: data.retorno,
    };
    dispatch(adicionarItemRelatorio(item));
    dispatch(processandoRelatorio(false));
  } catch (error) {
    dispatch(processandoLeituraDeConsumo(false));
    sendErroRequest(error, 'Ocorreu um erro exportar as Leituras de Consumo!');
  }
};

export const importLeituraConsumo = (values) => async (dispatch) => {
  try {
    if (values.upload === null) return createInfoNotification('Anexo não poder ser vazio!');
    dispatch(processandoLeituraDeConsumo(true));
    const arrayrows = values.upload.rows.slice(5);

    let novoArray = arrayrows.filter((li) => li.length > 0 && li.indexOf('limite') < 0);
    novoArray = arrayrows.filter((li) => li[1] !== undefined);
    const quantidadeLinha = novoArray.length;
    const listaLeituraConsumo = [];
    for (let index = 0; index < quantidadeLinha; index += 1) {
      const arrayLinhasPreparada = arrayrows[index];

      const dados = {
        dataDeReferencia: values.dataDeReferencia,
        tipoDoConsumo: values.tipoDoConsumo,
        unidade: { id: arrayLinhasPreparada[1] },
        leituraAnterior: formatarNumeroGeral(arrayLinhasPreparada[4]),
        leituraAtual:
          arrayLinhasPreparada[5] !== undefined ? formatarNumeroGeral(arrayLinhasPreparada[5]) : 0,
        consumo: Math.abs(
          (arrayLinhasPreparada[5] !== undefined
            ? formatarNumeroGeral(arrayLinhasPreparada[5])
            : 0) - formatarNumeroGeral(arrayLinhasPreparada[4]),
        ),
      };

      listaLeituraConsumo.push(dados);
    }

    const leitura = { listaLeituraConsumo };
    await laris.post(`/leituras-de-consumo/podeInserir`, leitura);

    const { data } = await laris.post(`/leituras-de-consumo`, leitura);
    createSuccessNotification(data.mensagem);
    dispatch(processandoLeituraDeConsumo(false));
    return true;
  } catch (error) {
    dispatch(processandoLeituraDeConsumo(false));
    sendErroRequest(error, 'Ocorreu um erro importar! Verifique a Panilha');
    return true;
  }
};
// export const onChangeValue = (field, value) => (dispatch, getState) => {
//   if (field === 'lancaConsumo') {
//     return dispatch(change('leituraDeConsumoForm', field, value));
//   } else {
//     const { form, unidade } = getState();

//     var leituraConsumoForm = form.leituraDeConsumoForm.values;

//     if (leituraConsumoForm.unidade.id !== '' && leituraConsumoForm.tipoDoConsumo > 0) {
//       var unidadeSelecionada = {};
//       var medidor = {};

//       _.forEach(unidade.unidadesComMedidor, item => {
//         if (item.id == leituraConsumoForm.unidade.id) {
//           unidadeSelecionada = item;

//           var dataMaior = '';

//           _.forEach(unidadeSelecionada.medidores, m => {
//             if (leituraConsumoForm.tipoDoConsumo == m.tipoDoLeitor) {
//               if (dataMaior == '') {
//                 dataMaior = m.dataDeInstalacao;
//                 medidor = m;
//               } else {
//                 if (moment(m.dataDeInstalacao) > moment(dataMaior)) {
//                   dataMaior = m.dataDeInstalacao;
//                   medidor = m;
//                 }
//               }
//             }
//           });
//         }
//       });

//       if (medidor !== {}) {
//         if (leituraConsumoForm.lancaConsumo) {
//           var valorLeitura = medidor.leituraAtual + value;

//           dispatch([
//             change('leituraDeConsumoForm', 'leituraAtual', valorLeitura),
//             change('leituraDeConsumoForm', 'leituraAnterior', medidor.leituraAtual),
//           ]);
//         } else {
//           var valorConsumo = value - medidor.leituraAtual;

//           dispatch([
//             change('leituraDeConsumoForm', 'consumo', valorConsumo),
//             change('leituraDeConsumoForm', 'leituraAnterior', medidor.leituraAtual),
//           ]);
//         }
//         dispatch(change('leituraDeConsumoForm', 'medidor_id', medidor.id));
//       }
//     }
//   }
// };

import moment from 'moment';
import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { guidEmpty, formatarNumeroDecimal, formatarNumeroGeral } from '../utils/Functions';
import {
  createSuccessNotification,
  sendErroRequest,
  createErrorNotification,
  createInfoNotification,
} from '../utils/Notification';

const { hashHistory } = hash;

export const setTabelasPreco = (response) => ({
  type: a.TPC_GET_TABELAS_PRECO,
  payload: response,
});

export const setTabelasPrecoById = (data) => ({
  type: a.TPC_GET_TABELAS_PRECO_BY_ID,
  payload: data,
});

export const processandoTabelaPreco = (bool) => ({
  type: a.TPC_PROCESSANDO_TABELA_PRECO,
  payload: bool,
});

const setEstadoInicial = () => ({
  type: a.TPC_SET_ESTADO_INICIAL,
});

export const getTabelasPreco = () => async (dispatch) => {
  try {
    dispatch(processandoTabelaPreco(true));

    const { data } = await laris.get(`/tabelas-preco`);

    const lista = data.map((tabela) => ({
      ...tabela,
      dataDeInicioDaVigencia: moment(tabela.dataDeInicioDaVigencia).format('L'),
    }));

    dispatch(setTabelasPreco(lista));
    dispatch(processandoTabelaPreco(false));
  } catch (error) {
    dispatch(processandoTabelaPreco(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar as Tabelas de Preço!');
  }
};

export const getTabelaPrecoById = (id) => async (dispatch) => {
  try {
    dispatch(processandoTabelaPreco(true));

    const { data } = await laris.get(`/tabelas-preco/${id}`);

    const tabela = {
      ...data,
      dataDeInicioDaVigencia: data.dataDeInicioDaVigencia
        ? moment(data.dataDeInicioDaVigencia)
        : data.dataDeInicioDaVigencia,
    };

    dispatch(setTabelasPrecoById(tabela));
    dispatch(processandoTabelaPreco(false));
  } catch (error) {
    dispatch(processandoTabelaPreco(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar as Tabelas de Preço!');
  }
};

export const deleteTabelaPreco = (id) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      await laris.delete(`/tabelas-preco/${id}`);

      dispatch(getTabelasPreco());
      createSuccessNotification('Tabela Preço excluída com sucesso!');
    }
  } catch (error) {
    sendErroRequest(error, 'Tabela Preço excluída com sucesso!');
  }
};

export const putTabelaPreco = (values) => async (dispatch) => {
  try {
    if (values.tipoDoConsumo === 0) {
      return createInfoNotification('O Tipo de consumo não pode ser indefinido');
    }
    const tabelaPreco = {
      conta: { id: values.conta.id },
      id: values.id,
      tipoDoConsumo: values.tipoDoConsumo,
      dataDeInicioDaVigencia: moment(values.dataDeInicioDaVigencia),
      ...(values.valorUnitario && {
        valorUnitario: formatarNumeroGeral(values.valorUnitario),
      }),
    };

    dispatch(processandoTabelaPreco(true));
    await laris.put(`/tabelas-preco`, tabelaPreco);
    hashHistory.push('/tabelas-preco');
    createSuccessNotification('Tabela de Preço alterada com sucesso!');

    dispatch(processandoTabelaPreco(false));
  } catch (error) {
    dispatch(processandoTabelaPreco(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar as Tabelas de Preço!');
  }
};

export const postTabelaPreco = (values) => async (dispatch) => {
  try {
    if (values.tipoDoConsumo === 0) {
      return createErrorNotification('O Tipo de consumo não pode ser indefinido');
    }
    const tabelaPreco = {
      conta: { id: values.conta.id },
      id: values.id,
      tipoDoConsumo: values.tipoDoConsumo,
      dataDeInicioDaVigencia: moment(values.dataDeInicioDaVigencia),
      ...(values.valorUnitario && {
        valorUnitario: formatarNumeroGeral(values.valorUnitario),
      }),
    };
    dispatch(processandoTabelaPreco(true));

    await laris.post(`/tabelas-preco`, tabelaPreco);

    dispatch(processandoTabelaPreco(false));
    createSuccessNotification('Tabela de Preço cadastrada com sucesso!');
    hashHistory.push('/tabelas-preco');
  } catch (error) {
    dispatch(processandoTabelaPreco(false));
    sendErroRequest(error, 'Ocorreu um erro ao cadastrar a Tabela de Preço!');
  }
};

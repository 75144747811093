import React from 'react';
import { TextArea } from 'devextreme-react';

import { Tooltip } from '@material-ui/core';

export const FormTextArea = ({ field, form: { touched, errors, setFieldValue }, ...props }) => {
  const { col, label, type, height, maxLength, onChange, tooltip } = props;
  const { value } = field;

  const Question = title => (
    <Tooltip title={title} style={{ fontWeight: 'bold', marginLeft: '5px' }}>
      <i className="fa fa-question-circle" />
    </Tooltip>
  );

  return (
    <div className={`col-lg-${col}`}>
      <div className={`form-group ${errors[field.name] ? 'has-error' : null}`}>
        <label style={{ fontWeight: 'bold' }}>
          {label}
          {tooltip !== '' && (tooltip !== undefined && Question(tooltip))}
        </label>
        <TextArea
          type={type || 'text'}
          className="form-control required"
          {...field}
          {...props}
          value={value || ''}
          height={height}
          maxLength={maxLength}
          onValueChanged={option => {
            setFieldValue(field.name, option ? option.value : '');

            onChange && onChange(option);
          }}
        />
        {touched[field.name] && errors[field.name] && (
          <span className="help-block m-b-none">{errors[field.name]}</span>
        )}
      </div>
    </div>
  );
};

import lodash from 'lodash';
import Swal from 'sweetalert2';

const NOTIFICATION_ICONS = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
};

const ToastMixin = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 5000,
});

export const sendErroRequest = (error, mensagemDefault) => {
  if (error.response && error.response.data) {
    if (lodash.isArray(error.response.data.erro)) {
      lodash.forEach(error.response.data.erro, (erro) =>
        createErrorNotification(erro.message || erro.mensagem),
      );
    } else if (lodash.isArray(error.response.data.erros)) {
      lodash.forEach(error.response.data.erros, (erro) =>
        createErrorNotification(erro.message || erro.mensagem),
      );
    } else {
      createErrorNotification(error.response.data.mensagem);
    }
  } else {
    createErrorNotification(mensagemDefault);
  }
};

const createNotification = (icon, message) => {
  ToastMixin.fire({
    icon,
    title: message,
  });
};

export const createErrorNotification = (message) => {
  createNotification(NOTIFICATION_ICONS.ERROR, message);
};

export const createSuccessNotification = (message) => {
  createNotification(NOTIFICATION_ICONS.SUCCESS, message);
};

export const createInfoNotification = (message) => {
  createNotification(NOTIFICATION_ICONS.INFO, message);
};

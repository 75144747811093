import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { formatarNumeroGeral } from '../utils/Functions';
import {
  createSuccessNotification,
  sendErroRequest,
  createErrorNotification,
  createInfoNotification,
} from '../utils/Notification';

const { hashHistory } = hash;

const setRateios = (response) => ({
  type: a.RAT_GET_RATEIOS,
  payload: response,
});

export const processandoRateio = (bool) => ({
  type: a.RAT_PROCESSANDO_RATEIO,
  payload: bool,
});

const setEstadoInicial = () => ({ type: a.RAT_SET_ESTADO_INICIAL });

export const setPercentuaisRateio = (response) => (dispatch) =>
  dispatch({
    type: a.RAT_SET_PERCENTUAIS_RATEIO,
    payload: response,
  });

export const setRateioById = (data) => (dispatch) =>
  dispatch({
    type: a.RAT_SET_RATEIO_BY_ID,
    payload: data,
  });

export const getRateios = () => async (dispatch) => {
  try {
    dispatch(processandoRateio(true));

    const { data } = await laris.get(`/rateios`);

    dispatch(setRateios(data));
    dispatch(processandoRateio(false));
  } catch (err) {
    dispatch(processandoRateio(false));
    sendErroRequest(err, 'Ocorreu um erro ao buscar as Rateios!');
  }
};
export const limparGridRateios = () => async (dispatch) => {
  dispatch(setPercentuaisRateio([]));
};

export const getRateioById = (id) => async (dispatch) => {
  try {
    dispatch(processandoRateio(true));
    const { data } = await laris.get(`/rateios/${id}`);

    dispatch(setRateioById(data));
    dispatch(processandoRateio(false));
    dispatch(setPercentuaisRateio(data.percentuaisRateioUnidade));
  } catch (err) {
    dispatch(processandoRateio(false));
    sendErroRequest(err, 'Ocorreu um erro ao buscar as Rateios!');
  }
};

export const getPercentuaisRateioFracaoIdeal = (values) => async (dispatch) => {
  try {
    dispatch(processandoRateio(true));

    const { data } = await laris.get(
      `/rateios/${
        values?.value === 2
          ? 'getPercentuaisDeRateiComBaseNaDistribuicao'
          : 'getPercentuaisDeRateiComBaseNaFracaoIdeal'
      }`,
    );

    dispatch(setPercentuaisRateio(data));
    dispatch(processandoRateio(false));
  } catch (err) {
    dispatch(processandoRateio(false));
    sendErroRequest(err, 'Ocorreu um erro ao carregar os percentuais de rateio!');
  }
};

export const getPercentuaisRateioDistribuicao = () => async (dispatch) => {
  try {
    dispatch(processandoRateio(true));

    const { data } = await laris.get(`/rateios/getPercentuaisDeRateiComBaseNaDistribuicao`);

    dispatch(setPercentuaisRateio(data));
    dispatch(processandoRateio(false));
  } catch (err) {
    dispatch(processandoRateio(false));
    sendErroRequest(err, 'Ocorreu um erro ao carregar os percentuais de rateio!');
  }
};

export const onChangeTipoRateio = (values) => (dispatch) => {
  if (values.value === 1) {
    dispatch(getPercentuaisRateioFracaoIdeal(values));
  } else if (values.value === 2) {
    dispatch(getPercentuaisRateioDistribuicao(values));
  }
};

export const removerPercentualRateio =
  (item, percentuais, rateioSelecionado) => async (dispatch) => {
    try {
      const res = await swal.fire({
        title: 'Confirmar deleção!',
        text: 'Você tem certeza que deseja deletar esse item?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Realmente deseja deletar?',
        cancelButtonText: 'Cancelar',
      });

      if (res.value) {
        const listaParcentuais = percentuais.filter((p) => p.id !== item.id);
        if (rateioSelecionado === 1) {
          if (listaParcentuais.length > 0) {
            dispatch(processandoRateio(true));
            const { data } = await laris.post(
              `/rateios/recalcularPercentaisRateio`,
              listaParcentuais,
            );
            dispatch(setPercentuaisRateio(data));
            dispatch(processandoRateio(false));
            createSuccessNotification('Rateio excluído com sucesso!');
          }
        } else if (rateioSelecionado === 2) {
          dispatch(processandoRateio(true));
          dispatch(setPercentuaisRateio(listaParcentuais));
          setTimeout(() => dispatch(processandoRateio(false)), 200);
          createSuccessNotification('Rateio excluído com sucesso!');
        }
      }
    } catch (err) {
      dispatch(processandoRateio(false));
      sendErroRequest(err, 'Ocorreu um erro ao recalcular os percentuais de rateio');
    }
  };

export const atualizarDistribuicao = (values) => async (dispatch) => {
  try {
    const { rateioPercentual, rateioSelecionado } = values;

    if (rateioSelecionado === 1) {
      if (rateioPercentual.length > 0) {
        dispatch(processandoRateio(true));
        const { data } = await laris.post(`/rateios/recalcularPercentaisRateio`, rateioPercentual);
        dispatch(setPercentuaisRateio(data));
        dispatch(processandoRateio(false));
      }
    } else if (rateioSelecionado === 2) {
      dispatch(processandoRateio(true));
      const { data } = await laris.get(`/rateios/getPercentuaisDeRateiComBaseNaDistribuicao`);
      dispatch(setPercentuaisRateio(data));
      dispatch(processandoRateio(false));
    }
  } catch (err) {
    dispatch(processandoRateio(false));
    sendErroRequest(err, 'Ocorreu um erro ao recalcular os percentuais de rateio');
  }
};

export const deleteRateio = (id) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      await laris.delete(`/rateios/${id}`);
      dispatch(getRateios('', 0));
      createSuccessNotification('Rateio excluído com sucesso!');
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao excluir a Rateio!');
  }
};

export const putRateio = (values, percentuaisRateioUnidade) => async (dispatch) => {
  try {
    const { id, descricao, tipoDoRateio } = values;

    const formularioRateio = {
      id,
      descricao,
      tipoDoRateio,
      percentuaisRateioUnidade,
    };

    if (tipoDoRateio === 1) {
      const totalPercentuais = percentuaisRateioUnidade
        .map((item) => item.percentualRateio)
        .reduce((total, valor) => (total += valor));
      const totalArrendodado = Math.round(totalPercentuais, 2);

      if (totalArrendodado > 100)
        return createErrorNotification(
          'O total de rateio atinge um valor maior que 100%. Verifique os dados e tente novamente!',
        );
    } else if (percentuaisRateioUnidade.length === 0)
      return createErrorNotification(
        'Não pode ser gravado um rateio sem percentuais! Verifique os dados e tente novamente!',
      );

    dispatch(processandoRateio(true));
    await laris.put(`/rateios`, formularioRateio);
    dispatch(processandoRateio(false));
    hashHistory.push('/rateios');
    createSuccessNotification('Rateio alterada com sucesso!');
  } catch (err) {
    dispatch(processandoRateio(false));
    sendErroRequest(err, 'Ocorreu um erro ao alterar a Rateio!');
  }
};

export const postRateio = (values, PercentuaisRateioUnidade) => async (dispatch) => {
  try {
    const { id, descricao, tipoDoRateio } = values;
    const formularioRateio = {
      id,
      descricao,
      tipoDoRateio,
      PercentuaisRateioUnidade,
    };
    dispatch(processandoRateio(true));
    await laris.post(`/rateios`, formularioRateio);

    dispatch(processandoRateio(false));
    hashHistory.push('/rateios');
    createSuccessNotification('Rateio cadastrada com sucesso!');
  } catch (err) {
    dispatch(processandoRateio(false));
    sendErroRequest(err, 'Ocorreu um erro ao cadastrar o Rateio!');
  }
};

export const putRateioEditarPercentual =
  (rateioNovo, percentuais, idAtual, rateioSelecionado, dataEdit) => async (dispatch) => {
    try {
      dispatch(processandoRateio(true));
      if (percentuais.length === 0)
        return createInfoNotification(
          'Não pode ser gravado um rateio sem percentuais! Verifique os dados e tente novamente!',
        );

      if (rateioSelecionado === 1) {
        const totalPercentuais = percentuais
          .map((item) => item.percentualRateio)
          .reduce((total, valor) => (total += valor));
        const totalArrendodado = Math.round(totalPercentuais, 2);

        if (totalArrendodado > 100) {
          return createInfoNotification(
            'O total de rateio atinge um valor maior que 100%. Verifique os dados e tente novamente!',
          );
        }
      }

      const rateioEditado = formatarNumeroGeral(rateioNovo);

      const percentuaisRateioNovo = await percentuais.map((item) => {
        if (item.id === idAtual.id) {
          return { ...item, percentualRateio: rateioEditado };
        }
        return item;
      });

      const formularioRateio = {
        id: dataEdit.id,
        descricao: dataEdit.descricao,
        TipoDoRateio: rateioSelecionado,
        percentuaisRateioUnidade: percentuaisRateioNovo,
      };

      const { data } = await laris.put(`/rateios`, formularioRateio);
      if (data) {
        dispatch(setPercentuaisRateio(data.rateio.percentuaisRateioUnidade));
        dispatch(processandoRateio(false));
        createSuccessNotification('Rateio alterada com sucesso');
      }
    } catch (error) {
      dispatch(processandoRateio(false));
      sendErroRequest(error, 'Ocorreu um erro ao alterar a Rateio!');
    }
  };

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  contas: [],
  processandoConta: false,
  processandoUploadConta: false,
  contasNivelUm: [],
  contasNivelDois: [],
  contasNivelTres: [],
  contasReceita: [],
  contasDespesa: [],
  contasContraPartida: [],
  contasSaldo: [],
  contaSaldoId: null,
  contasRecursoFinanceiro: [],
  contasSelecionadas: [],
  adicionandoConta: false,
  listaContas: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case a.CNT_SET_ESTADO_INICIAL:
      return {
        ...state,
        contas: [],
        processandoConta: false,
        processandoUploadConta: false,
        contasNivelUm: [],
        contasNivelDois: [],
        contasNivelTres: [],
        contasReceita: [],
        contasDespesa: [],
        contasContraPartida: [],
        contasSaldo: [],
        contaSaldoId: null,
        contasRecursoFinanceiro: [],
        contasSelecionadas: [],
        adicionandoConta: false,
        listaContas: [],
      };
    case a.CNT_GET_CONTAS:
      return { ...state, contas: payload };
    case a.CNT_PROCESSANDO_CONTA:
      return { ...state, processandoConta: payload };
    case a.CNT_PROCESSANDO_UPLOAD_CONTA:
      return { ...state, processandoUploadConta: payload };
    case a.CNT_GET_CONTAS_NIVEL_UM:
      return { ...state, contasNivelUm: payload };
    case a.CNT_GET_CONTAS_NIVEL_DOIS:
      return { ...state, contasNivelDois: payload };
    case a.CNT_GET_CONTAS_NIVEL_TRES:
      return { ...state, contasNivelTres: payload };
    case a.CNT_GET_CONTAS_RECEITA:
      return { ...state, contasReceita: payload };
    case a.CNT_GET_CONTAS_DESPESA:
      return { ...state, contasDespesa: payload };
    case a.CNT_GET_CONTAS_CONTRA_PARTIDA:
      return { ...state, contasContraPartida: payload };
    case a.CNT_GET_CONTAS_SALDO:
      return { ...state, contasSaldo: payload };
    case a.CNT_GET_CONTA_ID_SALDO:
      return { ...state, contaSaldoId: payload };
    case a.CNT_SET_CONTAS_RECURSO_FINANCEIRO:
      return { ...state, contasRecursoFinanceiro: payload };
    case a.CNT_SET_CONTAS_SELECIONADAS:
      return { ...state, contasSelecionadas: payload };
    case a.CNT_ADICIONANDO_CONTA:
      return { ...state, adicionandoConta: payload };
    case a.CNT_SET_LISTA_CONTAS:
      return { ...state, listaContas: payload };
    default:
      return { ...state };
  }
};

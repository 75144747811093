import React from 'react';

export default ({ icon, label, children }) => {
  return (
    <li>
      <a href="#" className="has-arrow" aria-expanded="false">
        <i className={`fa fa-${icon}`} />
        <span className="nav-label">{label}</span>
      </a>
      <ul className="nav nav-second-level">{children}</ul>
    </li>
  );
};

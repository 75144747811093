import moment from 'moment';
import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { newGuid, formatarNumeroGeral } from '../utils/Functions';
import { createSuccessNotification, sendErroRequest } from '../utils/Notification';

const { hashHistory } = hash;

const setEstadoInicial = () => ({
  type: a.UND_SET_ESTADO_INICIAL,
});
export const setUnidades = (response) => ({
  type: a.UND_GET_UNIDADES,
  payload: response,
});

const setUnidadesComMedidor = (response) => ({
  type: a.UND_GET_UNIDADES_COM_MEDIDOR,
  payload: response,
});

const processandoUnidade = (bool) => ({
  type: a.UND_PROCESSANDO_UNIDADE,
  payload: bool,
});

const setUnidadesbyId = (response) => ({
  type: a.UND_GET_UNIDADES_BY_ID,
  payload: response,
});
export const setMedidoresUnidade = (response) => (dispatch) =>
  dispatch({
    type: a.UND_ADD_MEDIDORES,
    payload: response,
  });

export const adicionandoMedidor = (bool) => ({
  type: a.UND_ADICIONANDO_MEDIDOR,
  payload: bool,
});

export const setListaUnidadesEditar = (response) => ({
  type: a.UND_SET_UNIDADES_EDITAR,
  payload: response,
});

// export const marcarDesmarcarUnidade = (idUnidade, value) => (dispatch, getState) => {
//   try {
//     const {
//       unidade: { unidades },
//     } = getState();

//     const novaLista = unidades.map(item => ({
//       ...item,
//       checked: item.id === idUnidade ? value : item.checked,
//     }));

//     dispatch(setUnidades(novaLista));
//   } catch (err) {
//     console.log(err);
//   }
// };

// export const marcarDesmarcarTodasUnidades = value => (dispatch, getState) => {
//   try {
//     const {
//       unidade: { unidades },
//     } = getState();

//     const novaLista = unidades.map(item => ({
//       ...item,
//       checked: value,
//     }));

//     dispatch(setUnidades(novaLista));
//   } catch (err) {
//     console.log(err);
//   }
// };

export const getUnidades = () => async (dispatch, getState) => {
  try {
    dispatch(processandoUnidade(true));
    const { data } = await laris.get('/unidades');

    const unidades = data.map((u) => ({
      ...u,
      unidadeEditada: false,
    }));
    localStorage.setItem(
      `laris-unidades-${getState().usuario.pessoaLogada.id}`,
      JSON.stringify(unidades),
    );
    dispatch(setUnidades(unidades));

    dispatch(processandoUnidade(false));
  } catch (error) {
    dispatch(processandoUnidade(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar as Unidades!');
  }
};

export const getUnidadesComMedidor = () => async (dispatch) => {
  try {
    dispatch(processandoUnidade(true));
    const { data } = await laris.get(`/unidades/getUnidadesComMedidor`);

    dispatch(setUnidadesComMedidor(data));
    dispatch(processandoUnidade(false));
  } catch (error) {
    dispatch(processandoUnidade(false));
    sendErroRequest(error, 'Ocorreu um erro ao carregar as Unidades!');
  }
};

export const getUnidadeById = (id) => async (dispatch) => {
  try {
    dispatch(processandoUnidade(true));

    const { data } = await laris.get(`/unidades/${id}`);

    const leitoresArray = [
      { value: 1, label: 'Gás' },
      { value: 2, label: 'Água' },
      { value: 3, label: 'Energia Elétrica' },
    ];

    let medidorEdit = [];

    medidorEdit = data?.medidores.map((c) => {
      const res = leitoresArray.filter((t) => t.value === c.tipoDoLeitor);
      const leitura = res[0];
      return { ...c, leitura };
    });
    dispatch(addMedidorEditado(medidorEdit));

    dispatch(setUnidadesbyId(data));

    dispatch(processandoUnidade(false));
  } catch (error) {
    dispatch(processandoUnidade(false));
    sendErroRequest(
      error,
      'Ocorreu um erro ao tentar visualizar a unidade. Tente novamente mais tarde.',
    );
  }
};

// export const getUnidadesPrevisao = () => async dispatch => {
//   try {
//     dispatch(processandoUnidade(true));

//     const { data } = await laris.get(`/unidades/unidades-previsao`);

//     dispatch([setUnidades(data), processandoUnidade(false)]);
//   } catch (error) {
//     dispatch(processandoUnidade(false));
//     tratamentoErroRequest(
//       error,
//       'Unidade',
//       'Ocorreu um erro ao tentar buscar as unidades da previsão.',
//     );
//   }
// };
// export const permissaoRemoverMedidor = async (medidor, idUnidade) => {
//   try {
//     if (!idUnidade) return true;

//     if (!medidor.id) return false;

//     const { data } = await laris.get(`/unidades/permissao-remover-medidor/${medidor.id}`);

//     return data;
//   } catch (error) {
//     console.log(error);
//     return false;
//   }
// };

export const deleteUnidade = (id) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Você tem certeza que deseja deletar esse item?',
      html: '<span style="color:#f44336"> </br>A exclusão desta unidade impactará as previsões e faturas que ainda não têm remessa gerada.</br> Ao excluir deve obrigatoriamente fazer a atualização das suas Previsões e Faturas para não ocorrer problemas nas gerações de remessas.<span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Deletar',
      cancelButtonText: 'Cancelar',
    });
    if (res.value) {
      await laris.delete(`/unidades/${id}`);

      dispatch(getUnidades());
      createSuccessNotification('Unidade excluído com sucesso!');
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao excluir a Unidade');
  }
};

export const addMedidor = (values, medidores) => async (dispatch) => {
  try {
    dispatch(adicionandoMedidor(true));
    const medidor = {
      id: newGuid(),
      ...(values.numero && { numero: values.numero }),
      ...(values.leituraAtual && { leituraAtual: values.leituraAtual }),
      ...(values.tipoLeitura && { tipoDoLeitor: values.tipoLeitura.value }),
      ...(values.tipoLeitura && { leitura: values.tipoLeitura }),
      ...(values.dataInstalacao && {
        dataDeInstalacao: moment(values.dataInstalacao).format('YYYY-MM-DD'),
      }),
    };

    dispatch(setMedidoresUnidade([...medidores, medidor]));
    dispatch(adicionandoMedidor(false));
  } catch (error) {
    sendErroRequest(error, 'ocorreu um erro ao buscar os medidores');
  }
};

export const addMedidorEditado = (values) => async (dispatch) => {
  try {
    dispatch(adicionandoMedidor(true));
    const medidores = values.map((item) => ({
      id: item.id,
      numero: item.numero,
      leituraAtual: item.leituraAtual,
      tipoDoLeitor: item.tipoDoLeitor,
      leitura: item.leitura,
      dataDeInstalacao: moment(item.dataDeInstalacao).format('YYYY-MM-DD'),
    }));

    dispatch(setMedidoresUnidade(medidores));
    dispatch(adicionandoMedidor(false));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao buscar os medidores');
    dispatch(adicionandoMedidor(false));
  }
};

export const removeMedidor = (medidor, medidores) => async (dispatch) => {
  try {
    dispatch(adicionandoMedidor(true));
    // const data = await laris.get(`/unidades/permissao-remover-medidor/${medidor}`);
    const novaLista = medidores.filter((item) => item.id !== medidor);

    dispatch(setMedidoresUnidade(novaLista));
    dispatch(adicionandoMedidor(false));
  } catch (error) {
    dispatch(adicionandoMedidor(false));
    sendErroRequest(error, 'Ocorreu um erro ao excluir o medidor!');
  }
};

export const putUnidade = (values, listaMedidores, unidades) => async (dispatch) => {
  try {
    const medidores = listaMedidores.map((medidor) => ({
      ...medidor,
      leituraAtual: formatarNumeroGeral(medidor.leituraAtual),
    }));

    const novaUnidade = {
      ...(values.id && { id: values.id }),
      ...(values.condominioSelecionado && { condomino: values.condominioSelecionado[0] }),
      ...(values.proprietarioSelecionado && { proprietario: values.proprietarioSelecionado[0] }),

      ...(values.unidadeBasica && { unidadeBasica: values.unidadeBasica }),
      ...(values.condomino_Id && { condomino_Id: values.condomino_Id }),
      ...(values.valorFixoUnidade && {
        valorFixoUnidade: formatarNumeroGeral(values.valorFixoUnidade),
      }),
      ...(values.proprietario_Id && { proprietario_Id: values.proprietario_Id }),
      ...(values.areaDaUnidade && {
        areaDaUnidade: formatarNumeroGeral(values.areaDaUnidade),
      }),
      ...(values.percentualDeDesconto && {
        percentualDeDesconto: formatarNumeroGeral(values.percentualDeDesconto),
      }),

      ...(values.percentualDeAcrescimo && {
        percentualDeAcrescimo: formatarNumeroGeral(values.percentualDeAcrescimo),
      }),

      ...(values.fracaoIdeal && {
        fracaoIdeal: isNaN(values.fracaoIdeal)
          ? formatarNumeroGeral(values.fracaoIdeal)
          : values.fracaoIdeal,
      }),
      ...(values.unidadeCentralSelecionada && {
        unidadesCentrais: values.unidadeCentralSelecionada,
      }),
      ...(values.observacao && { observacao: values.observacao }),
      medidores,
      unidadeEditada: true,
    };
    dispatch(processandoUnidade(true));
    await laris.put(`/unidades`, novaUnidade);

    const indx = unidades.map((u) => u.id).indexOf(novaUnidade.id);

    const novaListaUnidades = unidades.filter((u) => u.id !== novaUnidade.id);

    novaListaUnidades.splice(indx, 0, novaUnidade);

    dispatch(processandoUnidade(false));
    dispatch(setMedidoresUnidade([]));
    dispatch(setUnidades(novaListaUnidades));
    createSuccessNotification('Unidade alterada com sucesso!');
    hashHistory.push('/unidades');
  } catch (error) {
    dispatch(processandoUnidade(false));
    sendErroRequest(error, 'Ocorreu um erro ao alterar a Unidade!');
  }
};

export const postUnidade = (values, listaMedidores) => async (dispatch, getState) => {
  try {
    const medidores = listaMedidores.map((medidor) => ({
      ...medidor,
      leituraAtual: formatarNumeroGeral(medidor.leituraAtual),
    }));
    const novaUnidade = {
      ...(values.id && { id: values.id }),
      ...(values.condominioSelecionado && { condomino: values.condominioSelecionado[0] }),
      ...(values.proprietarioSelecionado && { proprietario: values.proprietarioSelecionado[0] }),

      ...(values.unidadeBasica && { unidadeBasica: values.unidadeBasica }),
      ...(values.condomino_Id && { condomino_Id: values.condomino_Id }),
      ...(values.valorFixoUnidade && {
        valorFixoUnidade: formatarNumeroGeral(values.valorFixoUnidade),
      }),
      ...(values.proprietario_Id && { proprietario_Id: values.proprietario_Id }),
      ...(values.areaDaUnidade && {
        areaDaUnidade: formatarNumeroGeral(values.areaDaUnidade),
      }),
      ...(values.percentualDeDesconto && {
        percentualDeDesconto: formatarNumeroGeral(values.percentualDeDesconto),
      }),
      ...(values.percentualDeAcrescimo && {
        percentualDeAcrescimo: formatarNumeroGeral(values.percentualDeAcrescimo),
      }),
      ...(values.fracaoIdeal && {
        fracaoIdeal: isNaN(values.fracaoIdeal)
          ? formatarNumeroGeral(values.fracaoIdeal)
          : values.fracaoIdeal,
      }),

      ...(values.unidadeCentralSelecionada && {
        unidadesCentrais: values.unidadeCentralSelecionada,
      }),
      ...(values.observacao && { observacao: values.observacao }),
      medidores,
      unidadeEditada: false,
    };
    dispatch(processandoUnidade(true));
    const { data } = await laris.post(`/unidades`, novaUnidade);

    dispatch(processandoUnidade(false));
    dispatch(setMedidoresUnidade([]));
    hashHistory.push('/unidades');
    if (data && data.unidade) {
      const { unidades } = getState().unidade;
      dispatch(setUnidades([...unidades, data.unidade]));
    }
    createSuccessNotification('Unidade cadastrada com sucesso');
  } catch (error) {
    dispatch(processandoUnidade(false));
    sendErroRequest(error, 'Ocorreu um erro ao cadastrar a Unidade!');
  }
};

export async function getImportarPlanilhaUnidades(resp) {
  try {
    const { data } = await laris.post('/unidades/upload-planilha-unidade', resp);

    return data;
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao cadastrar a Unidade');
    return undefined;
  }
}

import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { createSuccessNotification, sendErroRequest } from '../utils/Notification';

const { hashHistory } = hash;

const setHistoricos = (response) => ({
  type: a.HST_GET_HISTORICOS,
  payload: response,
});

const processandoHistorico = (bool) => ({
  type: a.HST_PROCESSANDO_HISTORICO,
  payload: bool,
});

const setEstadoInicial = () => ({
  type: a.HST_SET_ESTADO_INICIAL,
});
export const setHistoricoById = (data) => ({
  type: a.HST_GET_HISTORICOS_BY_ID,
  payload: data,
});

export const getHistoricos = () => async (dispatch) => {
  try {
    dispatch(processandoHistorico(true));

    const { data } = await laris.get(`/historicos`);

    dispatch(setHistoricos(data), processandoHistorico(false));
  } catch (err) {
    dispatch(processandoHistorico(false));
    sendErroRequest('Ocorreu um erro ao buscar as Históricos!');
  }
};

export const getHistoricoById = (id) => async (dispatch) => {
  try {
    dispatch(processandoHistorico(true));

    const { data } = await laris.get(`/historicos/${id}`);
    dispatch(setHistoricoById(data));
    dispatch(processandoHistorico(false));
  } catch (err) {
    dispatch(processandoHistorico(false));
    sendErroRequest('Ocorreu um erro ao buscar a Histórico selecionado!');
  }
};

export const deleteHistorico = (id) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      await laris.delete(`/historicos/${id}`);
      dispatch(getHistoricos());
      createSuccessNotification('Historico excluído com sucesso!');
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao excluir o Histórico!');
  }
};

export const putHistorico = (values) => async (dispatch) => {
  try {
    dispatch(processandoHistorico(true));
    await laris.put(`/historicos`, values);

    dispatch(processandoHistorico(false));
    createSuccessNotification('Histórico alterado com sucesso');
    hashHistory.push('/historicos');
  } catch (err) {
    dispatch(processandoHistorico(false));
    sendErroRequest(err, 'Ocorreu um erro ao alterar o Histórico!');
  }
};

export const postHistorico = (values) => async (dispatch) => {
  try {
    dispatch(processandoHistorico(true));
    await laris.post('/historicos', values);
    dispatch(processandoHistorico(false));
    createSuccessNotification('Histórico cadastrado com sucesso');
    hashHistory.push('/historicos');
  } catch (err) {
    dispatch(processandoHistorico(false));
    sendErroRequest(err, 'Ocorreu um erro ao alterar o Histórico!');
  }
};

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './styles.scss';
import 'jquery/dist/jquery';
import 'bootstrap/dist/js/bootstrap.min';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'moment/locale/pt-br';
import { loadMessages, locale } from 'devextreme/localization';
import ptMessages from 'devextreme/localization/messages/pt.json';
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR';
import * as serviceWorker from './serviceWorker';
import Components from './components';
import store from './store';

registerLocale('pt-BR', ptBr);
setDefaultLocale('pt-BR');
loadMessages(ptMessages);
locale(navigator.language || navigator.browserLanguage);

ReactDOM.render(
  <Provider store={store}>
    <Components />
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  remessas: [],
  processandoRemessa: false,
  numeroRemessa: 0,
  faturaRemessaId: [],
  processandoFaturaRemessa: false,
  dadosFaturaRemessaAtualizados: {
    juro: 0,
    multa: 0,
    valorDiferenca: 0,
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case a.RMS_SET_ESTADO_INICIAL:
      return { ...INITIAL_STATE };
    case a.RMS_SET_REMESSAS:
      return { ...state, remessas: payload };
    case a.RMS_SET_NUMERO_REMESSA:
      return { ...state, numeroRemessa: payload };
    case a.RMS_SET_FATURA_REMESSA:
      return { ...state, faturaRemessaId: payload };
    case a.RMS_PROCESSANDO_REMESSA:
      return { ...state, processandoRemessa: payload };
    case a.RMS_PROCESSANDO_FATURA_REMESSA:
      return { ...state, processandoFaturaRemessa: payload };
    case a.RMS_SET_DADOS_FATURA_REMESSA:
      return { ...state, dadosFaturaRemessaAtualizados: payload };
    default:
      return { ...state };
  }
};

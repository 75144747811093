import { combineReducers } from 'redux';

import RotaReducer from './rotaReducer';
import UsuarioReducer from './usuarioReducer';
import ContaReducer from './contaReducer';
import RateioReducer from './rateioReducer';
import RelatorioReducer from './gerenciadorRelatorioReducer';
import EscritorioCobrancaReducer from './escritorioCobrancaReducer';
import ComumReducer from './comumReducer';
import CondominosReducer from './condominoReducer';
import HistoricoReducer from './historicoReducer';
import InstrucaoBancariaReducer from './instrucaoBancaria';
import LocalReducer from './localReducer';
import PatrimonioReducer from './patrimonioReducer';
import EnumeradorReducer from './enumeradoresReducer';
import PortadorReducer from './portadorReducer';
import UnidadeReducer from './unidadeReducer';
import UnidadeCentralReducer from './unidadeCentralReducer';
import SindicoReducer from './sindicoReducer';
import UnidadeIsentaReducer from './unidadeIsentaReducer';
import TabelaPrecoReducer from './tabelaReducer';
import TaxaReducer from './taxaReducer';
import FaturaReducer from './faturaReducer';
import FechamentoPeriodoReducer from './fechamentoPeriodoReducer';
import RemessaReducer from './remessaReducer';
import LeituraConsumoReducer from './leituraConsumoReducer';
import LancamentoReducer from './lancamentoReducer';
import PrevisaoReducer from './previsaoReducer';
import AcordoReducer from './acordoReducer';
import DemonstrativoTaxaFixaReducer from './demonstrativoTaxaFixaReducer';
import ParcelamentoReducer from './parcelamentoReducer';
import RetornoBancarioReducer from './retornoBancarioReducer';
import DashboardReducer from './dashboardReducer';
import ParametroReducer from './parametroReducer';
import ProtocoloReducer from './protocoloReducer';
import ObservacaoReducer from './observacaoReducer';
import IndexadorReducer from './indexadoresReducer';
import LogsReducer from './logsReducer';
import analiseReceitasReducer from './analiseReceitasReducer';

const rootReducer = combineReducers({
  rota: RotaReducer,
  usuario: UsuarioReducer,
  conta: ContaReducer,
  rateio: RateioReducer,
  relatorio: RelatorioReducer,
  escritorioCobranca: EscritorioCobrancaReducer,
  comum: ComumReducer,
  condomino: CondominosReducer,
  historico: HistoricoReducer,
  instrucaoBancaria: InstrucaoBancariaReducer,
  local: LocalReducer,
  patrimonio: PatrimonioReducer,
  enumerador: EnumeradorReducer,
  portador: PortadorReducer,
  unidade: UnidadeReducer,
  sindico: SindicoReducer,
  unidadeCentral: UnidadeCentralReducer,
  unidadeIsenta: UnidadeIsentaReducer,
  tabelaPreco: TabelaPrecoReducer,
  taxa: TaxaReducer,
  fatura: FaturaReducer,
  fechamentoPeriodo: FechamentoPeriodoReducer,
  remessa: RemessaReducer,
  leituraConsumo: LeituraConsumoReducer,
  lancamento: LancamentoReducer,
  previsao: PrevisaoReducer,
  parcelamento: ParcelamentoReducer,
  acordo: AcordoReducer,
  demonstrativoTaxaFixa: DemonstrativoTaxaFixaReducer,
  retornoBancario: RetornoBancarioReducer,
  dashboard: DashboardReducer,
  parametro: ParametroReducer,
  protocolo: ProtocoloReducer,
  observacao: ObservacaoReducer,
  indexador: IndexadorReducer,
  logs: LogsReducer,
  analiseReceitas: analiseReceitasReducer,
});

export default rootReducer;

import React, { ReactNode, Fragment } from 'react';

type HeaderProps = {
  title?: string;
  children?: ReactNode;
  father?: string;
  fatherLink?: string;
}

function Header({ title, children, father, fatherLink }: HeaderProps) {
  return (
    <div className="row wrapper border-bottom white-bg page-heading">
      <div className="col-sm-6">
        <h2>{title}</h2>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#/home">Home</a>
          </li>
          {father && (
            <li className="breadcrumb-item">
              <a href={`#${fatherLink}`}>{father}</a>
            </li>
          )}
          <li className="breadcrumb-item active">
            <strong>{title}</strong>
          </li>
        </ol>
      </div>
      <div className="col-sm-6">
        <div className="title-action">{children}</div>
      </div>
    </div>
  )
}

Header.defaultProps = {
  title: '',
  children: Fragment,
  father: '',
  fatherLink: ''
};

export default Header;

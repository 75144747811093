import axios from 'axios';
import SentinelaURL from './SentinelaURL';

const sentinela = () => {
  const defaultOptions = {
    baseURL: `${process.env.REACT_APP_SENTINELA_API}/api`,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(config => {
    const token = `Bearer ${sessionStorage.getItem('token')}` || '';
    const contrato = sessionStorage.getItem('contratoId') || '';
    const tenant = sessionStorage.getItem('tenant') || '';

    const configuration = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: token,
        ContractIdentify: contrato,
        ServiceIdentify: tenant,
      },
    };

    return configuration;
  });

  instance.interceptors.response.use(
    response => response,
    err => {
      if (err.response.status === 401) {
        sessionStorage.clear();
        return window.location.assign(`${process.env.REACT_APP_SENTINELA_API}/api`);
      }

      return Promise.reject(err);
    },
  );

  return instance;
};

export default sentinela();

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  historicos: [],
  processandoHistorico: false,
  historicosById: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.HST_SET_ESTADO_INICIAL:
      return { ...state, processandoHistorico: false, historicos: [] };
    case a.HST_GET_HISTORICOS:
      return { ...state, historicos: payload };
    case a.HST_PROCESSANDO_HISTORICO:
      return { ...state, processandoHistorico: payload };
    case a.HST_GET_HISTORICOS_BY_ID:
      return { ...state, historicosById: payload };
    default:
      return { ...state };
  }
};

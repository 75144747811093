import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  lancamentos: [],
  processandoLancamento: false,
  lancamentosById: null,
  lancamentosChecked: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.LCM_SET_ESTADO_INICIAL:
      return { ...state, processandoLancamento: false, lancamentos: [] };
    case a.LCM_GET_LANCAMENTOS:
      return { ...state, lancamentos: payload };
    case a.LCM_GET_LANCAMENTOS_BY_ID:
      return { ...state, lancamentosById: payload };
    case a.LCM_PROCESSANDO_LANCAMENTO:
      return { ...state, processandoLancamento: payload };
    case a.LCM__LANCAMENTOS_CHECKED:
      return { ...state, lancamentosChecked: payload };
    default:
      return { ...state };
  }
};

import * as Yup from 'yup';

export const PessoaSchema = Yup.object().shape({
  nome: Yup.string().notRequired(),
  cpf: Yup.string()
    .min(11, 'CPF deve conter 11 digitos')
    .required('Campo requerido.'),
  logradouro: Yup.string().max(80, ({ max }) => `Logradouro não pode exceder ${max} caracteres.`),
  telefone: Yup.string().min(10, `Telefone deve conter 10 digitos. Exemplo: (99)9999-9999.`),
  celular: Yup.string().min(10, `Celular deve conter 10 digitos. Exemplo: (99)9999-9999.`),
});

import { TextBox } from 'devextreme-react';
import React from 'react';

export const FormInput = ({
  field,
  form: { touched, errors, setFieldValue, setErrors },
  ...props
}) => {
  const { col, label, onChange, onValueChanged, type } = props;

  return (
    <div className={`col-lg-${col}`}>
      <div className={`form-group ${errors[field.name] ? 'has-error' : null}`}>
        <label style={{ fontWeight: 'bold' }}>{label}</label>

        <TextBox
          // style={{ border: `${errors[field.name] ? '1px solid #ed5565' : 'null'}` }}
          {...field}
          {...props}
          isValid={!errors[field.name]}
          type={'text' || type}
          onValueChanged={option => {
            setFieldValue(field.name, option ? option.value : '');
            onChange && onChange(option);
            onValueChanged && onValueChanged(option);
          }}
        />

        {touched[field.name] && errors[field.name] && (
          <span className="help-block m-b-none has-error control-label">{errors[field.name]}</span>
        )}
      </div>
    </div>
  );
};

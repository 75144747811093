import * as a from '../actionsTypes/actionTypes';
import { sendErroRequest } from '../utils/Notification';
import laris from '../api/laris';

export const setLogs = response => ({
  type: a.LOG_GET_LOGS,
  payload: response,
});
const processandoLogs = bool => ({
  type: a.LOG_PROCESSANDO_LOGS,
  payload: bool,
});
export const getLogs = values => async dispatch => {
  try {
    dispatch(processandoLogs(true));
    const monthYear = new Date(values.monthYear);

    var filter = {};

    if (values.Descricao != 'todos') {
      filter.Descricao = values.Descricao;
    }
    if (values.tipoOperacao != '0' || parseInt(values.tipoOperacao)!=0) {
      filter.TipoOperacao = parseInt(values.tipoOperacao);
    }
    if (values.Email != '' && values.Email.trim() != '') {
      var usuario = 'Usuario.Email';
      filter[usuario] = values.Email.trim();
    }

    const params = {
      month: monthYear.getMonth() + 1,
      year: monthYear.getFullYear(),
      filter: filter,
    };
    const { data } = await laris.get(`/logs-generic/filter`, { params });
    dispatch(setLogs(data));
    dispatch(processandoLogs(false));
  } catch (err) {
    dispatch(processandoLogs(false));
    sendErroRequest('Ocorreu um erro ao buscar os logs!');
  }
};
export const getModulosLogs = async ()  => {
  try {   
    const { data } = await laris.get(`/logs-generic/parametros-logs`);
    return data;
  } catch (err) {
    sendErroRequest('Ocorreu um erro ao buscar os logs!');
  }
};

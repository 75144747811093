import swal from 'sweetalert2';
import hash from 'react-router-history';
import moment from 'moment';
import { adicionarItemRelatorio } from './gerenciadorRelatorioActions';
import * as a from '../actionsTypes/actionTypes';
import {
  createInfoNotification,
  createSuccessNotification,
  sendErroRequest,
} from '../utils/Notification';
import laris from '../api/laris';

const { hashHistory } = hash;

export const setObservacoes = response => ({
  type: a.OBS_GET_OBSERVACOES,
  payload: response,
});

export const processandoObservacoes = bool => ({
  type: a.OBS_PROCESSANDO_OBSERVACOES,
  payload: bool,
});

export const setObservacoesById = response => ({
  type: a.OBS_GET_OBSERVACOES_BY_ID,
  payload: response,
});

export const deleteObservacao = id => async dispatch => {
  try {
    dispatch(processandoObservacoes(true));
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar essa observação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      if (id == null) return createInfoNotification('É necessário ter um id válido!');
      await laris.delete(`/observacoes/delete-observacao/${id}`);
      dispatch(setObservacoes([]));
      createSuccessNotification('Observação excluído com sucesso!');
      dispatch(processandoObservacoes(false));
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao excluir uma observação!');
    dispatch(processandoObservacoes(false));
  }
};

export const getObservacao = values => async dispatch => {
  try {
    dispatch(processandoObservacoes(true));

    const { data } = await laris.post(`/observacoes`, values);
    dispatch(processandoObservacoes(false));

    if (!data) return createInfoNotification('Não foi encontrada nenhuma observação!');
    dispatch(setObservacoes(data));
  } catch (err) {
    sendErroRequest(err, 'Ocorreu um erro ao buscar a Observação!');
    dispatch(processandoObservacoes(false));
  }
};

export const cadastrarObservacoes = values => async dispatch => {
  try {
    dispatch(processandoObservacoes(true));
    const { data } = await laris.post(`/observacoes/cadastro-observacoes`, values);
    if (data) {
      createSuccessNotification('Observação cadastrada com sucesso!');
      dispatch(processandoObservacoes(false));
      hashHistory.push('/observacoes');
    }
  } catch (err) {
    sendErroRequest(err, 'Ocorreu um erro ao cadastrar a observação!');
    dispatch(processandoObservacoes(false));
  }
};

export const getObservacaoById = id => async dispatch => {
  try {
    dispatch(processandoObservacoes(true));
    const { data } = await laris.get(`observacoes/buscar-observacao/${id}`);
    dispatch(setObservacoesById(data));
    dispatch(processandoObservacoes(false));
  } catch (err) {
    dispatch(processandoObservacoes(false));
    sendErroRequest(err, 'Ocorreu um erro ao buscar a observação selecionada!');
  }
};

export const putObservacoes = values => async dispatch => {
  try {
    const edit = { ...values, dataObservacao: moment(values.dataObservacao) };
    dispatch(processandoObservacoes(true));
    const { data } = await laris.put(`/observacoes/editar-Observacoes`, edit);
    if (data) {
      dispatch(setObservacoes([]));
      createSuccessNotification('Observação editada com sucesso!');
      dispatch(processandoObservacoes(false));
      hashHistory.push('/observacoes');
    }
  } catch (err) {
    sendErroRequest(err, 'Ocorreu um erro ao editar a observação!');
    dispatch(processandoObservacoes(false));
  }
};

import $ from 'jquery';

export function correctHeight() {
  const pageWrapper = $('#page-wrapper');

  const navbarHeight = $('nav.navbar-default').height();

  const wrapperHeight = pageWrapper.height();

  if (navbarHeight > wrapperHeight) {
    pageWrapper.css('min-height', `${navbarHeight}px`);
  }

  if (navbarHeight <= wrapperHeight) {
    if (navbarHeight < $(window).height()) {
      pageWrapper.css('min-height', `${$(window).height()}px`);
    } else {
      pageWrapper.css('min-height', `${navbarHeight}px`);
    }
  }

  if ($('body').hasClass('fixed-nav')) {
    if (navbarHeight > wrapperHeight) {
      pageWrapper.css('min-height', `${navbarHeight}px`);
    } else {
      pageWrapper.css('min-height', `${$(window).height() - 60}px`);
    }
  }
}

export function detectBody() {
  if ($(document).width() < 769) {
    $('body').addClass('body-small');
  } else {
    $('body').removeClass('body-small');
  }
}

export function smoothlyMenu() {
  if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
    // Hide menu in order to smoothly turn on when maximize menu
    $('#side-menu').hide();
    // For smoothly turn on menu
    setTimeout(() => {
      $('#side-menu').fadeIn(400);
    }, 200);
  } else if ($('body').hasClass('fixed-sidebar')) {
    $('#side-menu').hide();
    setTimeout(() => {
      $('#side-menu').fadeIn(400);
    }, 100);
  } else {
    // Remove all inline style from $ fadeIn function to reset menu state
    $('#side-menu').removeAttr('style');
  }
}

function findSpecificParent(parent, parentClassName) {
  if (parent && parent.parentNode && !parent.classList.contains('ibox'))
    return findSpecificParent(parent.parentNode, parentClassName);

  return parent;
}

export function collapseIbox(e) {
  e.persist();
  const { target } = e;

  const parent = findSpecificParent(target.parentNode, 'ibox');

  const ibox = $(parent);
  const button = $(target);
  const content = ibox.children('.ibox-content');
  content.slideToggle(200);
  button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
  ibox.toggleClass('').toggleClass('border-bottom');
  setTimeout(() => {
    ibox.resize();
    ibox.find('[id^=map-]').resize();
  }, 50);
}

export function loadingContent() {
  $('.ibox-content').toggleClass('sk-loading');
}

export function tenantsOpen() {
  $('#right-sidebar').toggleClass('sidebar-open');
}

export function modalOpen(modal) {
  $(modal).modal('show');
  $(modal).fadeIn();
}

export function modalClose(modal) {
  $(modal).removeClass('in');
  $('.modal-backdrop').remove();
  $('body').removeClass('modal-open');
  $('body').css('padding-right', '');
  $(modal).hide();
}

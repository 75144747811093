import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { createSuccessNotification, sendErroRequest } from '../utils/Notification';

const { hashHistory } = hash;

export const carregaToken = (token) => {
  sessionStorage.setItem('token', token);
};

export const carregaTenant = (tenant) => {
  sessionStorage.setItem('tenant', tenant);
};

const setDadosFatura = (dados) => ({
  type: a.DASH_FATURA_SET_DADOS,
  payload: dados,
});

const processandoDadosFatura = (bool) => ({
  type: a.DASH_FATURA_PROCESSANDO,
  payload: bool,
});

const setDadosUtilizacaoPortal = (dados) => ({
  type: a.DASH_UTILIZACAO_PORTAL_SET_DADOS,
  payload: dados,
});

const processandoDadosUtilizacaoPorta = (bool) => ({
  type: a.DASH_UTILIZACAO_PORTAL_PROCESSANDO,
  payload: bool,
});

const setDadosVisualizacaoBoleto = (dados) => ({
  type: a.DASH_VISUALIZACAO_BOLETOS_SET_DADOS,
  payload: dados,
});

const processandoDadosVisualizacaoBoleto = (bool) => ({
  type: a.DASH_VISUALIZACAO_BOLETOS_PROCESSANDO,
  payload: bool,
});

const setDadosEvolucaoTaxas = (dados) => ({
  type: a.DASH_EVOLUCAO_TAXAS_SET_DADOS,
  payload: dados,
});

const processandoDadosEvolucaoTaxas = (bool) => ({
  type: a.DASH_EVOLUCAO_TAXAS_PROCESSANDO,
  payload: bool,
});

export const buscarDadosFaturas = () => async (dispatch) => {
  try {
    dispatch(processandoDadosFatura(true));

    const { data } = await laris.get(`/dashboard/faturas`);

    dispatch(setDadosFatura(data));
    dispatch(processandoDadosFatura(false));
  } catch (error) {
    dispatch(processandoDadosFatura(false));
    sendErroRequest(
      error,
      'Ocorreu um erro inexperado ao tentar buscar dos dados das faturas para preencher o dashboard!',
    );
  }
};

export const buscarDadosUtilizacaoPortal = () => async (dispatch) => {
  try {
    dispatch(processandoDadosUtilizacaoPorta(true));

    const { data } = await laris.get(`/dashboard/utilizacao-portal`);

    dispatch(setDadosUtilizacaoPortal(data));
    dispatch(processandoDadosUtilizacaoPorta(false));
  } catch (error) {
    dispatch(processandoDadosUtilizacaoPorta(false));
    sendErroRequest(
      error,
      'Ocorreu um erro inexperado ao tentar buscar dos dados de utilização do portal!',
    );
  }
};

export const buscarDadosVisualizacaoBoleto = (forcarAtualizacao) => async (dispatch) => {
  try {
    dispatch(processandoDadosVisualizacaoBoleto(true));

    const { data } = await laris.get(`/dashboard/visualizacao-boleto/${forcarAtualizacao}`);

    dispatch(setDadosVisualizacaoBoleto(data));
    dispatch(processandoDadosVisualizacaoBoleto(false));
  } catch (error) {
    dispatch(processandoDadosVisualizacaoBoleto(false));
    sendErroRequest(
      error,
      'Ocorreu um erro inexperado ao tentar buscar dos dados de visualizacao dos boletos!',
    );
  }
};

export const buscarDadosEvolucaoTaxas = () => async (dispatch) => {
  try {
    dispatch(processandoDadosEvolucaoTaxas(true));

    const { data } = await laris.get(`/dashboard/evolucao-taxas`);

    dispatch(setDadosEvolucaoTaxas(data));
    dispatch(processandoDadosEvolucaoTaxas(false));
  } catch (error) {
    dispatch(processandoDadosEvolucaoTaxas(false));
    sendErroRequest(
      error,
      'Ocorreu um erro inexperado ao tentar buscar dos dados de evolução de taxas para preencher o dashboard!',
    );
  }
};

import { createSuccessNotification, sendErroRequest } from '../utils/Notification';
import laris from '../api/laris';
import * as a from '../actionsTypes/actionTypes';

export const carregaAutenticacao = async (token, tenant) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('tenant', tenant);
};

export const logOutUser = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('tenant');
};

export const processandoCondomino = bool => {
  return {
    type: a.CDM_PROCESSANDO_CONDOMINO,
    payload: bool,
  };
};

export const integracaoAutomatica = () => async dispatch => {
  try {
    dispatch(processandoCondomino(true));
    const { data } = await laris.post('integracao/assembleia');
    createSuccessNotification('Integração feita com sucesso!');
    dispatch(processandoCondomino(false));
    if (data) return data.result;
  } catch (error) {
    sendErroRequest(error, 'Erro ao fazer integração com Assembléia digital!');
    dispatch(processandoCondomino(false));
  }
};

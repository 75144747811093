import moment from 'moment';
import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import {
  createSuccessNotification,
  sendErroRequest,
  createInfoNotification,
} from '../utils/Notification';

const { hashHistory } = hash;

export const setTaxas = (response) => ({
  type: a.TXA_GET_TAXAS,
  payload: response,
});

export const setTaxasMarcadas = (response) => ({
  type: a.TXA_GET_TAXAS_MARCADAS,
  payload: response,
});
export const setTaxasById = (data) => ({
  type: a.TXA_GET_TAXAS_BY_ID,
  payload: data,
});

const processandoTaxa = (bool) => ({
  type: a.TXA_PROCESSANDO_TAXA,
  payload: bool,
});

const setEstadoInicial = () => ({
  type: a.TXA_SET_ESTADO_INICIAL,
});

export const getTaxas = () => async (dispatch) => {
  try {
    dispatch(processandoTaxa(true));

    const { data } = await laris.get('/taxas');

    dispatch(
      setTaxas(
        data.map((item) => ({
          ...item,
          checked: false,
        })),
      ),
    );

    dispatch(processandoTaxa(false));
  } catch (err) {
    dispatch(processandoTaxa(false));
    sendErroRequest(err, 'Taxa');
  }
};

// export const limparListaTaxas = () => (dispatch, getState) => {
//   const {
//     taxa: { taxas },
//   } = getState();

//   if (taxas.length < 1) return;

//   dispatch([processandoTaxa(true), setTaxas([])]);
//   setTimeout(() => dispatch(processandoTaxa(false)), 300);
// };
export const limparGridTaxas = () => async (dispatch) => {
  dispatch(setTaxas([]));
};
export const getTaxasComBaseNaPrevisao = (values) => async (dispatch) => {
  try {
    const dataPeriodo = values.periodo;

    const mes = parseInt(moment(dataPeriodo).format('M'));
    const ano = parseInt(moment(dataPeriodo).year());

    dispatch(processandoTaxa(true));

    const { data } = await laris.get(`/taxas/getTaxasComBaseNaPrevisao/${mes}/${ano}`);

    if (data.length === 0) {
      createInfoNotification('Não existem taxas validas para geração de faturas!');
    }
    const taxas = data.map((item) => ({
      ...item,
      checked: false,
    }));

    dispatch(setTaxas(taxas));
    dispatch(processandoTaxa(false));
  } catch (error) {
    dispatch(processandoTaxa(false));
    sendErroRequest(
      error,
      'Ocorreu um erro ao buscar as taxas da previsão. Verifique os dados e tente novamente!',
    );
  }
};

export const marcarDesmarcarTaxa = (rowMarked, taxas) => async (dispatch) => {
  try {
    if (rowMarked.length === 0) {
      dispatch(setTaxasMarcadas([]));
    } else {
      const taxasAtualizadas = await taxas.map((taxa) => {
        const taxaNova = rowMarked.find((c) => c.id === taxa.id);

        if (!taxaNova) return taxa;
        return { ...taxaNova, checked: true };
      });
      dispatch(setTaxasMarcadas(taxasAtualizadas));
    }
  } catch (error) {
    sendErroRequest(error, 'Erro ao marcar Taxas');
  }
};

// export const marcarDesmarcarTodasTaxas = value => (dispatch, getState) => {
//   const {
//     taxa: { taxas },
//   } = getState();

//   dispatch(processandoTaxa(true));

//   const listaTaxas = taxas.map(item => ({ ...item, checked: value }));

//   dispatch([setTaxas(listaTaxas)]);

//   setTimeout(() => dispatch(processandoTaxa(false)), 1);
// };

export const getTaxaById = (id) => async (dispatch) => {
  try {
    dispatch(processandoTaxa(true));

    const { data } = await laris.get(`/taxas/${id}`);

    dispatch(setTaxasById(data));
    dispatch(processandoTaxa(false));
  } catch (err) {
    dispatch(processandoTaxa(false));
    sendErroRequest(err, 'Ocorreu um erro ao buscar a taxa selecionada!');
  }
};

export const deleteTaxa = (id) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });
    if (res.value) {
      await laris.delete(`/taxas/${id}`);

      dispatch(getTaxas());
      createSuccessNotification('Taxa excluída com sucesso!');
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao excluir a taxa!');
  }
};

export const putTaxa = (values) => async (dispatch) => {
  try {
    await laris.put(`/taxas`, values);

    dispatch(processandoTaxa(false));
    createSuccessNotification('Taxa alterada com sucesso!');
    hashHistory.push('/taxas');
  } catch (error) {
    dispatch(processandoTaxa(false));
    sendErroRequest(error, 'Ocorreu um erro ao alterar a Taxa!');
  }
};

export const postTaxa = (values) => async (dispatch) => {
  try {
    dispatch(processandoTaxa(true));
    await laris.post('/taxas', values);

    dispatch(processandoTaxa(false));
    createSuccessNotification('Taxa adicionada com sucesso!');
    hashHistory.push('/taxas');
  } catch (error) {
    dispatch(processandoTaxa(false));
    sendErroRequest(error, 'Ocorreu um erro ao alterar a Taxa!');
  }
};

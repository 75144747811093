import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  retornosBancarios: [],
  processandoRetornoBancario: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.RET_SET_ESTADO_INICIAL:
      return { ...state, retornosBancarios: [], processandoRetornoBancario: false };
    case a.RET_PROCESSANDO_RETORNO_BANCARIO:
      return { ...state, processandoRetornoBancario: payload };
    case a.RET_SET_RETORNO_BANCARIO:
      return { ...state, retornosBancarios: payload };
    default:
      return { ...state };
  }
};

import React from 'react';
import NumberFormat from 'react-number-format';

export const FormInputNumber = ({ field, form: { touched, errors, setFieldValue }, ...props }) => {
  const {
    col,
    label,
    onChange,
    suffix,
    prefix,
    allowNegative,
    disabled,
    precision,
    allowLeadingZeros,
    fixedDecimalScale,
    isAllowed,
    type,
    title,
    onValueChange,
  } = props;
  return (
    <div className={`col-lg-${col}`}>
      <div className={`form-group ${errors[field.name] ? 'has-error' : ''}`}>
        <label style={{ fontWeight: 'bold' }}>
          {label}
          {title && (
            <i
              className="fa fa-question-circle"
              title={title}
              data-toggle="tooltip"
              data-placement="top"
            />
          )}
        </label>
        <NumberFormat
          // style={{
          //   borderBottomLeftRadius: 4,
          //   borderTopLeftRadius: 4,
          //   borderBottomRightRadius: 4,
          //   borderTopRightRadius: 4,
          //   width: '100%',
          //   height: 38,
          //   borderColor: '#c9d3dd',
          //   borderWidth: 1,
          //   fontSize: 13,
          //   lineHeight: 1.5,
          //   outlineColor: '#18a689',
          //   paddingTop: 4,
          //   paddingBottom: 4,
          //   paddingLeft: 11,
          //   paddingRight: 11,
          //   backgroundColor: '#fff',
          //   backgroundImage: 'none',
          //   color: '#676a6c',
          //   position: 'relative',
          //   display: 'inline-block',
          //   transitionProperty: 'transform, opacity',
          //   transitionDuration: '0.15s',
          //   transitionTimingFunction: 'ease, step-end',
          // }}
          className="form-control required"
          allowNegative={allowNegative}
          suffix={suffix}
          prefix={prefix}
          type={type || ''}
          thousandSeparator="."
          decimalSeparator=","
          disabled={disabled}
          decimalScale={precision}
          allowLeadingZeros={allowLeadingZeros}
          fixedDecimalScale={fixedDecimalScale || 2}
          isAllowed={isAllowed}
          {...field}
          {...props}
          onValueChange={option => {
            const { value, floatValue } = option;
            setFieldValue(field.name, !allowLeadingZeros ? floatValue : value || '');
            onValueChange && onValueChange(option);
            onChange && onChange(floatValue);
          }}
        />

        {touched[field.name] && errors[field.name] && (
          <span className="help-block m-b-none">{errors[field.name]}</span>
        )}
      </div>
    </div>
  );
};

import * as Yup from 'yup';

export const FiltroRemessaSchema = Yup.object().shape({
  periodoInicial: Yup.string()
    .nullable()
    .required('Período inicial requerido.'),
  periodoFinal: Yup.string()
    .nullable()
    .required('Período final requerido.'),
});

export const FiltroFaturaSchema = Yup.object().shape({
  periodo: Yup.string()
    .nullable()
    .required('Período requerido.'),
});

export const retornoBancarioSchema = Yup.object().shape({
  portadorId: Yup.string().required('Campo requerido.'),
});

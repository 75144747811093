import React from 'react';

export const SaveButton = ({ loading, label }) => (
  <button type="submit" className="btn btn-primary pull-right" disabled={loading}>
    {loading && (
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
    )}

    {loading ? label : 'Salvar'}
  </button>
);

export const SearchButton = ({ loading }) => (
  <button type="submit" className="btn btn-primary pull-right" disabled={loading}>
    {loading && (
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
    )}

    {loading ? ' Buscando...' : 'Buscar'}
  </button>
);

export const GridButton = ({ color, icon, tooltip, onClick }) => (
  <button
    type="submit"
    className={`btn btn-outline btn-${color} btn-xs`}
    title={tooltip}
    onClick={onClick}
  >
    <i className={`fa fa-${icon}`} />
  </button>
);

export const DownloadButton = ({ color, icon, tooltip, loading, title, onClick }) => (
  <button
    className={`btn btn-${color} m-t`}
    type="button"
    title={tooltip}
    onClick={onClick}
    disabled={loading}
  >
    {loading ? (
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
    ) : (
      <i className={`fa fa-${icon || 'download'}`} />
    )}
    &nbsp;&nbsp;<span className="bold">{loading ? 'Baixando...' : title}</span>
  </button>
);

export const UploadButton = ({ icon, tooltip, uploading, uploadingTitle, onClick }) => (
  <button
    className="btn btn-primary btn-block"
    type="button"
    title={tooltip}
    onClick={onClick}
    disabled={uploading}
  >
    {uploading ? (
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
    ) : (
      <i className={`fa fa-${icon || 'cloud-upload'}`} />
    )}
    &nbsp;&nbsp;
    <span className="bold">{uploading ? uploadingTitle || 'Enviando...' : 'Enviar'}</span>
  </button>
);

export const GenericButton = ({
  className,
  icon,
  tooltip,
  loading,
  title,
  loadingTitle,
  onClick,
  disabled,
  style,
}) => (
  <button
    className={className}
    type="button"
    title={tooltip}
    onClick={onClick}
    disabled={loading || disabled}
    style={style}
  >
    {loading ? (
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
    ) : (
      <i className={`fa fa-${icon}`} />
    )}
    &nbsp;&nbsp;<span className="bold">{loading ? loadingTitle : title}</span>
  </button>
);

export const EditarButton = ({
  className,
  icon,
  tooltip,
  loading,
  title,
  loadingTitle,
  onClick,
}) => (
  <button className={className} type="button" title={tooltip} onClick={onClick} disabled={loading}>
    {loading ? (
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
    ) : (
      <i className={`fa fa-${icon}`} />
    )}
    &nbsp;&nbsp;<span className="bold">{loading ? loadingTitle : title}</span>
  </button>
);

import moment from 'moment';
import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { formatarNumeroGeral, formatarNumeroMoeda, GUID_EMPTY } from '../utils/Functions';
import {
  createSuccessNotification,
  sendErroRequest,
  createInfoNotification,
} from '../utils/Notification';
import { adicionarItemRelatorio } from './gerenciadorRelatorioActions';

const { hashHistory } = hash;

export const setLancamentos = (response) => ({
  type: a.LCM_GET_LANCAMENTOS,
  payload: response,
});

export const processandoLancamento = (bool) => ({
  type: a.LCM_PROCESSANDO_LANCAMENTO,
  payload: bool,
});

export const setLancamentoById = (data) => ({
  type: a.LCM_GET_LANCAMENTOS_BY_ID,
  payload: data,
});

export const setLancamentosMarcados = (data) => ({
  type: a.LCM__LANCAMENTOS_CHECKED,
  payload: data,
});

export const limparLancamentosMarcados = (data) => ({
  type: a.LCM__LANCAMENTOS_CHECKED,
  payload: data,
});

const setEstadoInicial = () => ({ type: a.LCM_SET_ESTADO_INICIAL });

export const limparListaLancamentos = () => (dispatch) => {
  dispatch(processandoLancamento(true));

  dispatch(setLancamentos([]));
  dispatch(processandoLancamento(false));
};

export const getLancamentosDespesasReceitas = (values) => async (dispatch) => {
  try {
    if (!values) return createInfoNotification('Selecione as datas para buscar os lançamentos!');

    if (values.dataInicial > values.dataFinal)
      return createInfoNotification('Data Inicial deve ser menor que a Data Final!');

    if (!values.dataInicial || !values.dataFinal)
      return createInfoNotification('Data Inicial e Data Final devem ser preenchidas!');

    const filtro = {
      ...(values.dataInicial && { dataInicial: moment(values.dataInicial).startOf('day') }),
      ...(values.dataFinal && { dataFinal: moment(values.dataFinal).startOf('day') }),
    };

    dispatch(processandoLancamento(true));
    const { data } = await laris.post(`/lancamentos/lancamentos-despesas-receitas`, filtro);
    dispatch(setLancamentos(data));
    dispatch(processandoLancamento(false));
  } catch (err) {
    dispatch(processandoLancamento(false));
    sendErroRequest(
      err,
      'Ocorreu um erro inesperado ao tentar buscar os lançamentos. Verifique os dados e tente novamente!',
    );
  }
};

export const getLancamentos = (values) => async (dispatch) => {
  try {
    if (values.dataInicial > values.dataFinal) {
      return createInfoNotification('Data Inicial deve ser menor que Data Final!');
    }
    const filtro = {
      ...(values.dataInicial && { dataInicial: moment(values.dataInicial).startOf('day') }),
      ...(values.dataFinal && { dataFinal: moment(values.dataFinal).startOf('day') }),
      ...(values.valor && { valor: formatarNumeroGeral(values.valor) }),
      ...(values.conta_Id && { conta_Id: values.conta_Id }),
      documento: values.documento || '',
    };

    dispatch(processandoLancamento(true));
    const { data } = await laris.post(`/lancamentos/getLancamentosComFiltro`, filtro);

    const lancamentos = data.map((item) => ({
      ...item,
      dataDoLancamento: moment(item.dataDoLancamento).format('L'),
      lancamentoEditado: false,
    }));

    dispatch(setLancamentos(lancamentos));
    dispatch(processandoLancamento(false));
  } catch (err) {
    dispatch(processandoLancamento(false));
    sendErroRequest(err, 'Lancamento', 'Ocorreu um erro ao carregar as Lancamentos!');
  }
};

export const getLancamentoById = (id) => async (dispatch) => {
  try {
    dispatch(processandoLancamento(true));
    const { data } = await laris.get(`/lancamentos/${id}`);
    dispatch(setLancamentoById(data));
    dispatch(processandoLancamento(false));
  } catch (err) {
    dispatch(processandoLancamento(false));
    sendErroRequest(err, 'Ocorreu um erro ao carregar a Lancamento!');
  }
};

export const deleteLancamento = (item, lancamentos) => async (dispatch) => {
  try {
    if (item.previsao_Id !== GUID_EMPTY) {
      createInfoNotification(
        'Esse lançamento foi gerado apartir de uma previsão, tem certeza que deseja excluí-lo?',
      );
    }
    if (item.fatura_Id !== GUID_EMPTY) {
      createInfoNotification(
        'Esse lançamento foi gerado automaticamente. A exclusão dele pode fazer com que o balancete fique incorreto. Deseja continuar?',
      );
    }
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });
    if (res.value) {
      const resp = await laris.delete(`/lancamentos/${item.id}`);
      if (resp) {
        createSuccessNotification('Lancamento excluído com sucesso!');

        const listaAtualizadaLancamentos = lancamentos.filter((l) => l.id !== item.id);

        dispatch(setLancamentos(listaAtualizadaLancamentos));
      }
    }
  } catch (err) {
    sendErroRequest(err, 'Ocorreu um erro ao excluir um Lançamento!');
  }
};

export const putLancamento =
  (lancamentoForm, lancamentos, tiposLancamento, contasNivelTres) => async (dispatch) => {
    try {
      let res = {};
      if (lancamentoForm.previsao_Id !== GUID_EMPTY) {
        res = await swal.fire({
          title: 'Confirmar alteração!',
          text: 'Esse lançamento foi gerado apartir de uma previsão, tem certeza que deseja altera-lo',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Realmente deseja alterar?',
          cancelButtonText: 'Cancelar',
        });
      } else if (lancamentoForm.fatura_Id !== GUID_EMPTY) {
        res = await swal.fire({
          title: 'Confirmar alteração!',
          text: 'Esse lançamento foi gerado automaticamente. A alteração dele pode fazer com que o balancete fique incorreto. Deseja continuar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Realmente deseja alterar?',
          cancelButtonText: 'Cancelar',
        });
      }
      if (lancamentoForm.fatura_Id === GUID_EMPTY && lancamentoForm.previsao_Id === GUID_EMPTY) {
        res = { value: true };
      }

      const lancamentoEdit = {
        ...(lancamentoForm.id && {
          id: lancamentoForm.id,
        }),
        ...(lancamentoForm.dataDoLancamento && {
          dataDoLancamento: moment(lancamentoForm.dataDoLancamento).format('YYYY-MM-DD'),
        }),
        ...(lancamentoForm.marcadaConciliacao && {
          marcadaConciliacao: lancamentoForm.marcadaConciliacao,
        }),
        ...(lancamentoForm.marcadaDemonstrativo && {
          marcadaDemonstrativo: lancamentoForm.marcadaDemonstrativo,
        }),
        ...(lancamentoForm.cheque && { cheque: lancamentoForm.cheque }),
        ...(lancamentoForm.complemento && { complemento: lancamentoForm.complemento }),
        ...(lancamentoForm.contaId && { conta: { id: lancamentoForm.contaId } }),
        ...(lancamentoForm.contaRecursoFinanceiroId && {
          contaRecursoFinanceiro: { id: lancamentoForm.contaRecursoFinanceiroId },
        }),
        ...(lancamentoForm.anexo && { anexo: lancamentoForm.anexo }),
        ...(lancamentoForm.historicoId && { historico: { id: lancamentoForm.historicoId } }),
        ...(lancamentoForm.valor && { valor: formatarNumeroGeral(lancamentoForm.valor) }),
        ...(lancamentoForm.documento && { documento: lancamentoForm.documento }),
        ...(lancamentoForm.tipoDoLancamento && {
          tipoDoLancamento: lancamentoForm.tipoDoLancamento,
        }),
        ...(lancamentoForm.previsao_Id && { previsao_Id: lancamentoForm.previsao_Id }),
        ...(lancamentoForm.fatura_Id && { fatura_Id: lancamentoForm.fatura_Id }),
      };

      if (res.value) {
        dispatch(processandoLancamento(true));

        await laris.put(`/lancamentos`, lancamentoEdit);

        if (lancamentoForm.arquivo !== null && lancamentoForm.arquivo !== undefined) {
          const formData = new FormData();
          formData.append('id', lancamentoForm.id);
          formData.append('arquivo', lancamentoForm.arquivo[0].file);
          await laris.post(`/lancamentos/adicionarAnexo`, formData);
        }
        dispatch(processandoLancamento(false));
        createSuccessNotification('Lancamento alterada com sucesso!');

        const indx = lancamentos.map((la) => la.id).indexOf(lancamentoForm.id);

        const listaAtualizada = lancamentos.filter((la) => la.id !== lancamentoForm.id);
        const conta = contasNivelTres.find((c) => c.id === lancamentoForm.contaId);

        const itemAtualizadoLancamento = {
          ...lancamentoForm,
          dataDoLancamento: moment(lancamentoForm.dataDoLancamento).format('L'),
          descricaoTipoLancamento: tiposLancamento.find(
            (t) => t.id === lancamentoForm.tipoDoLancamento,
          ).descricao,
          conta: { ...conta, codigoDescricao: conta.descricao },
          lancamentoAtualizado: true,
        };

        listaAtualizada.splice(indx, 0, itemAtualizadoLancamento);

        dispatch(setLancamentos(listaAtualizada));
        hashHistory.push('/lancamentos');
      }
    } catch (err) {
      dispatch(processandoLancamento(false));
      sendErroRequest(err, 'Ocorreu um erro ao alterar a Lancamento!');
    }
  };

export const postLancamento = (lancamentoForm) => async (dispatch) => {
  try {
    dispatch(processandoLancamento(true));

    const lancamentoEdit = {
      ...(lancamentoForm.id && {
        id: lancamentoForm.id,
      }),
      ...(lancamentoForm.dataDoLancamento && {
        dataDoLancamento: moment(lancamentoForm.dataDoLancamento).format('YYYY-MM-DD'),
      }),
      ...(lancamentoForm.marcadaConciliacao && {
        marcadaConciliacao: lancamentoForm.marcadaConciliacao,
      }),
      ...(lancamentoForm.marcadaDemonstrativo && {
        marcadaDemonstrativo: lancamentoForm.marcadaDemonstrativo,
      }),
      ...(lancamentoForm.cheque && { cheque: lancamentoForm.cheque }),
      ...(lancamentoForm.complemento && { complemento: lancamentoForm.complemento }),
      ...(lancamentoForm.contaId && { conta: { id: lancamentoForm.contaId } }),
      ...(lancamentoForm.contaRecursoFinanceiroId && {
        contaRecursoFinanceiro: { id: lancamentoForm.contaRecursoFinanceiroId },
      }),
      ...(lancamentoForm.historicoId && { historico: { id: lancamentoForm.historicoId } }),
      ...(lancamentoForm.valor && { valor: formatarNumeroGeral(lancamentoForm.valor) }),
      ...(lancamentoForm.documento && { documento: lancamentoForm.documento }),
      ...(lancamentoForm.tipoDoLancamento && { tipoDoLancamento: lancamentoForm.tipoDoLancamento }),
      ...(lancamentoForm.previsao_Id && { previsao_Id: lancamentoForm.previsao_Id }),
      ...(lancamentoForm.anexo && { anexo: lancamentoForm.anexo }),
      ...(lancamentoForm.fatura_Id && { fatura_Id: lancamentoForm.fatura_Id }),
    };

    const { data } = await laris.post('/lancamentos', lancamentoEdit);

    if (lancamentoForm.arquivo !== null && lancamentoForm.arquivo !== undefined) {
      const formData = new FormData();
      formData.append('id', data.lancamento.id);
      formData.append('arquivo', lancamentoForm.arquivo[0].file);
      await laris.post(`/lancamentos/adicionarAnexo`, formData);
    }

    dispatch(processandoLancamento(false));
    createSuccessNotification('Lancamento cadastrado com sucesso!');
  } catch (err) {
    dispatch(processandoLancamento(false));
    sendErroRequest(err, 'Ocorreu um erro ao cadastrar a Lancamento!');
  }
};

const preencherFormData = (lancamentoForm) => {
  const valorFormatado = formatarNumeroGeral(lancamentoForm.valor);

  const formData = new FormData();
  formData.append('documento', lancamentoForm.documento || '');
  formData.append('valor', valorFormatado);
  formData.append('tipoDoLancamento', lancamentoForm.tipoDoLancamento);
  formData.append('complemento', lancamentoForm.complemento || '');
  formData.append('cheque', lancamentoForm.cheque || '');
  formData.append('marcadaConciliacao', lancamentoForm.marcadaConciliacao);
  formData.append('marcadaDemonstrativo', lancamentoForm.marcadaDemonstrativo);

  if (lancamentoForm.id) formData.append('id', lancamentoForm.id);

  if (lancamentoForm.anexo) formData.append('anexo', lancamentoForm.anexo);

  formData.append(
    'contaRecursoFinanceiro.id',
    lancamentoForm.contaRecursoFinanceiroId !== null
      ? lancamentoForm.contaRecursoFinanceiroId
      : null,
  );

  formData.append(
    'conta.id',
    lancamentoForm.contaId.length !== null ? lancamentoForm.contaId : null,
  );
  lancamentoForm.historicoId !== undefined &&
    lancamentoForm.historicoId !== null &&
    formData.append('historico.id', lancamentoForm.historicoId);

  if (lancamentoForm.previsao_Id) formData.append('previsao_Id', lancamentoForm.previsao_Id);

  formData.append(
    'dataDoLancamento',
    new Date(moment(lancamentoForm.dataDoLancamento)).toDateString(),
  );

  if (lancamentoForm.arquivo) formData.append('arquivo', lancamentoForm.arquivo[0].file);

  return formData;
};

export const marcarLancamentosSelecionados =
  (lancamentos, arrayLancamentos) => async (dispatch) => {
    try {
      if (lancamentos.length === 0) {
        dispatch(setLancamentosMarcados([]));
      } else {
        const lancamentosAtualizados = await arrayLancamentos.map((l) => {
          const lancamentoNovo = lancamentos.find((c) => c.id === l.id);

          if (!lancamentoNovo) return l;
          return { ...lancamentoNovo, checked: true };
        });

        dispatch(setLancamentosMarcados(lancamentosAtualizados));
      }
    } catch (error) {
      sendErroRequest(error, 'Erro ao fazer ao marcar os Lançamentos!');
    }
  };
export const excluirLancamentosSelecionados = (lancamentos) => async (dispatch) => {
  try {
    if (lancamentos.length === 0)
      return createInfoNotification('Ocorreu um erro ao carregar os lancamentos ');

    const res = await swal.fire({
      title: 'Confirmar exclusão coletiva de Lançamentos!',
      text: 'Você tem certeza que deseja excluir os Lançamentos selecionados ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      const lancamentosSelecionados = lancamentos.filter((lan) => lan.checked).map((lan) => lan.id);

      dispatch(processandoLancamento(true));
      const { data } = await laris.post(`lancamentos/excluirLancamentos`, lancamentosSelecionados);

      if (data.sucesso) {
        const lancamentosNaoMarcados = lancamentos.filter((fat) => !fat.checked);
        dispatch(setLancamentos(lancamentosNaoMarcados));
        createSuccessNotification('Lançamentos excluídos com sucesso!');
      }
    }
    dispatch(processandoLancamento(false));
  } catch (error) {
    dispatch(processandoLancamento(false));
    sendErroRequest(error, 'Erro ao excluir os lançamentos selecionados');
  }
};

export const exportLancamentos = (lancamentos) => async (dispatch) => {
  try {
    if (lancamentos.length <= 0)
      return createInfoNotification('É necessário selecionar lançamentos!');
    createInfoNotification('Solicitação feita com sucesso! aguarde a planilha.');
    const rowsValues = [];
    let rowInicial = 5;
    for (let index = 0; index < lancamentos.length; index += 1) {
      const lancamento = lancamentos[index];
      rowInicial += 1;
      const linha = {
        row: rowInicial,
        action: 2,
        columns: [
          {
            col: 2,
            value: lancamento.dataDoLancamento,
          },
          {
            col: 3,
            value: lancamento.documento,
          },
          {
            col: 4,
            value: lancamento.cheque != null ? lancamento.cheque : '',
          },
          {
            col: 5,
            value: lancamento.complemento != null ? lancamento.complemento : '',
          },
          {
            col: 6,
            value: lancamento.descricaoTipoLancamento,
          },
          {
            col: 7,
            value: lancamento.historico != null ? lancamento.historico.descricao : '',
          },
          {
            col: 8,
            value:
              lancamento?.conta?.codigoDescricao != null ? lancamento.conta.codigoDescricao : '',
          },

          {
            col: 9,
            value:
              lancamento?.contaRecursoFinanceiro?.codigoDescricao != null
                ? lancamento.contaRecursoFinanceiro.codigoDescricao
                : '',
          },
          {
            col: 10,
            value: lancamento.valor,
          },
        ],
      };
      rowsValues.push(linha);
    }

    const json = {
      callback_error: 'https://webhook.site/12cfa114-8961-442a-8a26-7bf71549d6a0',
      identificationInResponse: '4554',
      sheets: [
        {
          position: 0,
          rowsValues,
        },
      ],
    };
    const { data } = await laris.post('/lancamentos/export-planilha-lancamentos', json);

    const item = {
      dataEmissao: new Date(),
      nome: 'Planilha_Lancamentos',
      url: data.retorno,
    };

    dispatch(adicionarItemRelatorio(item));
  } catch (error) {
    sendErroRequest(error, 'Erro ao fazer ao exportar a planilha de Lançamentos!');
  }
};

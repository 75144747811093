import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  demonstrativosTaxaFixa: [],
  demonstrativoTaxaFixa: null,
  processandoDemonstrativoTaxaFixa: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.DTF_SET_ESTADO_INICIAL:
      return { ...state, processandoDemonstrativoTaxaFixa: false, demonstrativosTaxaFixa: [] };
    case a.DTF_GET_DEMONSTRATIVOS_TAXA_FIXA:
      return { ...state, demonstrativosTaxaFixa: payload };
    case a.DTF_PROCESSANDO_DEMONSTRATIVO_TAXA_FIXA:
      return { ...state, processandoDemonstrativoTaxaFixa: payload };
    case a.DTF_GET_DEMONSTRATIVO_TAXA_FIXA_BY_ID:
      return { ...state, demonstrativoTaxaFixa: payload };
    default:
      return { ...state };
  }
};

import React from 'react';

// import logoTron from '../../assets/images/footer/logo-tron.png';

const Footer = () => (
  <div className="footer">
    <div className="pull-right">
      <a href="http://www.tron.com.br">{/* <img alt="logo-tron" src={logoTron} /> */}</a>
    </div>
    <div>
      <strong>Copyright</strong> Tron Informática Ltda &copy; {new Date().getFullYear()}
    </div>
  </div>
);

export default Footer;

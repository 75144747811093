import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  relatorios: [],
  processandoRelatorio: false,
  aguardandoRelatorio: false,
  dadosEvolucaoInadimplencia: {
    labels: [],
    values: [],
  },
  dadosDemonstrativoSaldoConta: {
    labels: [],
    values: [],
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.REL_SET_ESTADO_INICIAL:
      return {
        ...state,
        processandoRelatorio: false,
        relatorios: payload,
        dadosEvolucaoInadimplencia: {
          labels: [],
          values: [],
        },
        dadosDemonstrativoSaldoConta: {
          labels: [],
          values: [],
        },
      };
    case a.REL_SET_RELATORIOS:
      return { ...state, relatorios: payload };
    case a.REL_PROCESSANDO_RELATORIO:
      return { ...state, processandoRelatorio: payload };
    case a.REL_AGUARDANDO_RELATORIO:
      return { ...state, aguardandoRelatorio: payload };
    case a.REL_SET_DADOS_EVOLUCAO_INADIMPLENCIA:
      return { ...state, dadosEvolucaoInadimplencia: payload };
    case a.REL_SET_DADOS_DEMONSTRATIVO_SALDO_CONTA:
      return { ...state, dadosDemonstrativoSaldoConta: payload };
    default:
      return { ...state };
  }
};

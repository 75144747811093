import AcessoApp from 'ajuda-inicializacao';

import * as types from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  rotas: [
    {
      path: '/acesso-app',
      exact: true,
      component: AcessoApp,
      permitido: true,
    },
  ],
  menus: [],
  carregandoAplicacao: true,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.ROT_SET_ROTAS_MENUS_SUCCESS:
      return { ...state, rotas: payload.rotas, menus: payload.menus, carregandoAplicacao: false };
    default:
      return state;
  }
};

import {
  adicionarItemRelatorio,
  adicionarAguardandoRelatorio,
  aguardandoRelatorio,
} from './gerenciadorRelatorioActions';
import {
  createSuccessNotification,
  sendErroRequest,
  createInfoNotification,
} from '../utils/Notification';
import laris from '../api/laris';

export const emitirBoletos = values => async dispatch => {
  try {
    createSuccessNotification('A solicitação de emissao de boletos foi realizada com sucesso!');

    const boletoFiltro = {
      ...(values.emitirApenasFaturasEmAberto && {
        emitirApenasFaturasEmAberto: values.emitirApenasFaturasEmAberto,
      }),
      ...(values.emitirDadosAcessoPortal && {
        emitirDadosAcessoPortal: values.emitirDadosAcessoPortal,
      }),
      ...(values.periodoFinal && { periodoFinal: values.periodoFinal }),
      ...(values.periodoInicial && { periodoInicial: values.periodoInicial }),
      ...(values.vencimentoFinal && { vencimentoFinal: values.vencimentoFinal }),
      ...(values.vencimentoInicial && { vencimentoInicial: values.vencimentoInicial }),
      ...(values.tipoEmissaoBoleto && { tipoEmissaoBoleto: values.tipoEmissaoBoleto }),
      ...(values.condomino_Id && { condomino_Id: values.condomino_Id }),
      ...(values.proprietario_Id && { proprietario_Id: values.proprietario_Id }),
      ...(values.unidade_Id && { unidade_Id: values.unidade_Id }),
      ...(values.unidadeCentral_Id && { unidadeCentral_Id: values.unidadeCentral_Id }),
      ...(values.observacao && { observacao: values.observacao }),
      ...(values.nossoNumero && { nossoNumero: values.nossoNumero }),
      ...(values.permitirReenvioFaturasEmail && {
        permitirReenvioFaturasEmail: values.permitirReenvioFaturasEmail,
      }),
    };

    dispatch(adicionarAguardandoRelatorio());
    dispatch(aguardandoRelatorio(true));

    const { data } = await laris.post('/relatorios/emitir-boletos', boletoFiltro);

    dispatch(aguardandoRelatorio(false));
    if (data.length === 0 && boletoFiltro.tipoEmissaoBoleto !== 3)
      createInfoNotification('Não existe nenhum boleto à ser emitido', 5);

    if (boletoFiltro.tipoEmissaoBoleto === 3 && data.length > 0)
      createSuccessNotification('Boleto(s) enviados com sucesso para o email!');
    if (boletoFiltro.tipoEmissaoBoleto === 3 && data.length <= 0)
      createSuccessNotification('Não existe nenhum boleto a ser enviado para o  email!');

    if (boletoFiltro.tipoEmissaoBoleto !== 3 && data.length > 0) {
      data.forEach(item => {
        dispatch(adicionarItemRelatorio(item));
      });
    }
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão de boleto. Verifique os dados e tente novamente!',
    );
    dispatch(aguardandoRelatorio(false));
  }
};

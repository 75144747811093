import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { createSuccessNotification, sendErroRequest } from '../utils/Notification';

const { hashHistory } = hash;

export const getEscritoriosCobrancaSuccess = (response) => ({
  type: a.ECB_GET_ESCRITORIOS_COBRANCA,
  payload: response,
});

export const processandoEscritorioCobranca = (bool) => ({
  type: a.ECB_PROCESSANDO_ESCRITORIO_COBRANCA,
  payload: bool,
});

const setEstadoInicial = () => (dispatch) =>
  dispatch({
    type: a.ECB_SET_ESTADO_INICIAL,
  });
export const setEscritorioCobrancaById = (data) => async (dispatch) =>
  dispatch({
    type: a.ECB_GET_ESCRITORIOS_COBRANCA_BY_ID,
    payload: data,
  });

export const getEscritoriosCobranca = () => async (dispatch) => {
  try {
    dispatch(processandoEscritorioCobranca(true));

    const { data } = await laris.get(`/escritorios-cobranca`);
    dispatch(getEscritoriosCobrancaSuccess(data), processandoEscritorioCobranca(false));
  } catch (err) {
    dispatch(processandoEscritorioCobranca(false));
    sendErroRequest(err, 'Ocorreu um erro ao buscar os Escritórios de Cobrança!');
  }
};

export const getEscritorioCobrancaById = (id) => async (dispatch) => {
  try {
    dispatch(processandoEscritorioCobranca(true));

    const { data } = await laris.get(`/escritorios-cobranca/${id}`);
    dispatch(setEscritorioCobrancaById(data));
    dispatch(processandoEscritorioCobranca(false));
  } catch (err) {
    dispatch(processandoEscritorioCobranca(false));
    sendErroRequest(err, 'Ocorreu um erro ao buscar o Escritório de Cobrança selecionada!');
  }
};

export const deleteEscritorioCobranca = (id) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      await laris.delete(`/escritorios-cobranca/${id}`);

      dispatch(getEscritoriosCobranca());
      createSuccessNotification('Escritório de Cobrança excluído com sucesso!');
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao excluir o Escritório de Cobrança!');
  }
};

export const putEscritorioCobranca = (values) => async (dispatch) => {
  try {
    dispatch(processandoEscritorioCobranca(true));
    await laris.put(`/escritorios-cobranca`, values);

    dispatch(processandoEscritorioCobranca(false));
    createSuccessNotification('Escritório de Cobrança alterado com sucesso!');
    hashHistory.push('/escritorios-cobranca');
  } catch (err) {
    sendErroRequest(err, 'Ocorreu um erro ao alterar o Escritório de Cobrança!');
  }
};

export const postEscritorioCobranca = (values) => async (dispatch) => {
  try {
    dispatch(processandoEscritorioCobranca(true));
    await laris.post(`/escritorios-cobranca`, values);
    dispatch(processandoEscritorioCobranca(false));
    createSuccessNotification('Escritório de Cobrança cadastrado com sucesso');
    hashHistory.push('/escritorios-cobranca');
  } catch (err) {
    sendErroRequest(err, 'Ocorreu um erro ao cadastrar o Escritório de Cobrança!');
  }
};

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  rateios: [],
  processandoRateio: false,
  percentuaisRateio: [],
  rateioById: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.RAT_SET_ESTADO_INICIAL:
      return { ...state, processandoRateio: false, rateios: [] };
    case a.RAT_GET_RATEIOS:
      return { ...state, rateios: payload };
    case a.RAT_PROCESSANDO_RATEIO:
      return { ...state, processandoRateio: payload };
    case a.RAT_SET_PERCENTUAIS_RATEIO:
      return { ...state, percentuaisRateio: payload };
    case a.RAT_SET_RATEIO_BY_ID:
      return { ...state, rateioById: payload };
    default:
      return { ...state };
  }
};

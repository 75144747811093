import jQuery from 'jquery';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import Loading from './Loading';
import Topnavbar from '../topnavbar';
import Footer from '../footer';
import { correctHeight, detectBody } from '../helpers';
import Navigation from '../Navigation';

const BasicLayout = ({ children, carregandoAplicacao }) => {
  useEffect(() => {
    jQuery(window).bind('load resize', () => {
      correctHeight();
      detectBody();
    });
  });

  if (carregandoAplicacao)
    return (
      <Fragment>
        <Loading title="Estamos preparando tudo para você! Aguarde..." type="bars" />
        {children}
      </Fragment>
    );

  return (
    <div id="wrapper">
      <Navigation />

      <div id="page-wrapper" className="gray-bg">
        <Topnavbar />

        {children}

        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({ carregandoAplicacao: state.rota.carregandoAplicacao });

export default connect(mapStateToProps)(BasicLayout);

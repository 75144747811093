import * as types from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  unidadesCentrais: [],
  unidadesCentraisById: null,
  processandoUnidadeCentral: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.UCT_SET_ESTADO_INICIAL:
      return { ...state, processandoUnidadeCentral: false, unidadesCentrais: [] };
    case types.UCT_GET_UNIDADES_CENTRAIS:
      return { ...state, unidadesCentrais: payload };
    case types.UCT_PROCESSANDO_UNIDADE_CENTRAL:
      return { ...state, processandoUnidadeCentral: payload };
    case types.UCT_GET_UNIDADES_CENTRAIS_BY_ID:
      return { ...state, unidadesCentraisById: payload };
    default:
      return { ...state };
  }
};

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  processandoNotificacao: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.PRT_SET_ESTADO_INICIAL:
      return { ...state, processandoNotificacao: false };
    case a.PTR_PROCESSANDO_NOTIFICACAO:
      return { ...state, processandoNotificacao: payload };

    default:
      return { ...state };
  }
};

export const formatarValor = (valor, precisao) => {
  if (valor) return `R$ ${valor.toFixed(precisao || 4).replace(/\d(?=(\d{3})+\.)/g, '$&.')}`;

  return `R$ ${(0).toFixed(precisao || 2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

export const formatarNumeroDecimal = valor => {
  if (valor)
    return parseFloat(
      valor
        .slice(3)
        .replace('.', '')
        .replace(',', '.'),
    );
};

export const formatarNumeroPercentualDecimal = valor => {
  if (valor) return parseFloat(valor.replace('%', '').replace(',', '.'));
};

export const newGuid = () => {
  let dt = new Date().getTime();

  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0;

    dt = Math.floor(dt / 16);

    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uuid;
};

export const guidEmpty = () => {
  return '00000000-0000-0000-0000-000000000000';
};

export const formatarNumeroGeral = valor => {
  let response = `${valor}`.replace('R$', '').replace('%', '');

  if (isNaN(response)) {
    response = response.replace('.', '').replace(',', '.');
  }

  if (response === '') response = '0.0';

  return parseFloat(response);
};

export const formatarNumeroMoeda = valor => {
  let response = `${valor}`.replace('R$', '').replace('%', '');

  if (isNaN(response)) {
    response = response.replace(',', '.');
  }
  if (response === '') response = '0.0';

  return parseFloat(response);
};

export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

export const isNumber = n => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const verIsNaN = arr => {
  if (
    arr.every(
      item => Number(item.codigoNormal.replace(/[^\d]+/g, '')) && item.codigoNormal.length === 8,
    )
  ) {
    return true;
  }

  return false;
};
export const cpfCnpj = value => {
  if (value) {
    const cpfCnpjEditado = value.toString().replace(/[^0-9]/g, '');

    if (cpfCnpjEditado.length === 14) {
      if (validaCnpj(value.toString())) {
        return true;
      }
      return false;
    }
    if (cpfCnpjEditado.length === 11) {
      if (validaCpf(value.toString())) {
        return true;
      }
      return false;
    }
    return false;
  }
  return false;
};

function validaCnpj(s) {
  const cnpj = s.toString().replace(/[^\d]+/g, '');

  // Valida a quantidade de caracteres
  if (cnpj.length !== 14) return false;

  // Elimina inválidos com todos os caracteres iguais
  if (/^(\d)\1+$/.test(cnpj)) return false;

  // Cáculo de validação
  let t = cnpj.length - 2,
    d = cnpj.substring(t),
    d1 = parseInt(d.charAt(0)),
    d2 = parseInt(d.charAt(1)),
    calc = x => {
      let n = cnpj.substring(0, x),
        y = x - 7,
        s = 0,
        r = 0;

      for (let i = x; i >= 1; i--) {
        s += n.charAt(x - i) * y--;
        if (y < 2) y = 9;
      }

      r = 11 - (s % 11);
      return r > 9 ? 0 : r;
    };

  return calc(t) === d1 && calc(t + 1) === d2;
}

function validaCpf(value) {
  const cpfCnpjEditado = value.toString();
  if (typeof cpfCnpjEditado !== 'string') return false;

  const cpf = cpfCnpjEditado.replace(/[\s.-]*/gim, '');

  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i += 1) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i += 1) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
}

export const sugestaoCodigoContaFunction = (value, data) => {
  if (data !== null) {
    const pos1 = value[0];
    const pos2 = value.length > 2 ? value.substring(2, 4) : null;
    const pos3 = value.length > 5 ? value.substring(5, 8) : null;

    let listaCodigos = [];
    let listaCodigoNumeros = [];
    let maxCodigo;

    if (pos1 != null && pos2 == null && pos3 == null) {
      let result = null;
      listaCodigos = data.filter(c => c.codigoNormal.split('.')[0].includes(pos1));

      listaCodigoNumeros = listaCodigos.map(l =>
        parseInt(l.codigoNormal.replace('.', '').replace('.', '')),
      );
      if (listaCodigoNumeros.length > 0) {
        maxCodigo = Math.max.apply(null, listaCodigoNumeros);
        maxCodigo = parseInt(maxCodigo.toString().substr(0, 3)) + 1;

        result = `${maxCodigo.toString().substr(0, 1)}.${maxCodigo.toString().substr(1, 2)}.000`;
      }
      return result;
    }
    if (pos2 != null) {
      let result = null;
      listaCodigos = data.filter(
        c =>
          c.codigoNormal.split('.')[0].includes(pos1) &&
          c.codigoNormal.split('.')[1].includes(pos2),
      );
      listaCodigoNumeros = listaCodigos.map(l =>
        parseInt(l.codigoNormal.replace('.', '').replace('.', '')),
      );
      maxCodigo = Math.max.apply(null, listaCodigoNumeros);
      maxCodigo += 1;
      if (listaCodigoNumeros.length > 0) {
        result = `${maxCodigo.toString().substr(0, 1)}.${maxCodigo
          .toString()
          .substr(1, 2)}.${maxCodigo.toString().substr(3)}`;
      }
      return result;
    }

    if (pos2 && pos3 !== null) {
      listaCodigos = data.filter(
        c =>
          c.codigoNormal.split('.')[0].includes(pos1) &&
          c.codigoNormal.split('.')[1].includes(pos2) &&
          c.codigoNormal.split('.')[2].includes(pos3),
      );
      listaCodigoNumeros = listaCodigos.map(l =>
        parseInt(l.codigoNormal.replace('.', '').replace('.', '')),
      );

      maxCodigo = Math.max.apply(null, listaCodigoNumeros);
      maxCodigo += 1;
      const result = `${maxCodigo.toString().substr(0, 1)}.${maxCodigo
        .toString()
        .substr(1, 2)}.${maxCodigo.toString().substr(3)}`;
      return result;
    }
  }
};

export const formatarValorMoeda = (valor, precisao) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: precisao || 2,
  });

  return formatter.format(valor || 0);
};

export const formatarData = values => {
  if (values != null) {
    const dia = new Date().getDate();
    const mes = values.toString().substr(4);
    const ano = values.toString().substr(0, 4);
    const dataFormatada = `${ano}${mes}${dia}`;
    return dataFormatada;
  }
  return null;
};

export const formatJsonExcel = rowsValues => {
  const json = {
    callback_error: 'https://webhook.site/12cfa114-8961-442a-8a26-7bf71549d6a0',
    identificationInResponse: '4554',
    sheets: [
      {
        position: 0,
        rowsValues,
      },
    ],
  };
  return json;
};

export const formatColummRelatoBoletoGerado = (boletoSelecionado, rowInicial) => {
  const linha = {
    row: rowInicial,
    action: 2,
    columns: [
      {
        col: 2,
        value: boletoSelecionado.unidade,
      },
      {
        col: 3,
        value: boletoSelecionado.condomino,
      },
      {
        col: 4,
        value: boletoSelecionado.referencia,
      },

      {
        col: 5,
        value: boletoSelecionado.dataDoVencimento,
      },
      {
        col: 6,
        value: boletoSelecionado.valorDaTaxa,
      },
      {
        col: 7,
        value: boletoSelecionado.valorDoFundoDeReserva,
      },
      {
        col: 8,
        value: boletoSelecionado.valorDaFatura,
      },
      {
        col: 9,
        value: boletoSelecionado.dataDoPagamento,
      },

      {
        col: 10,
        value: boletoSelecionado.valorPago,
      },
    ],
  };
  return linha;
};

export function mascaraInscricao(value) {
  // Remove tudo o que não é dígito
  let inscricao = value.replace(/\D/g, '');

  if (inscricao.length < 14) {
    // CPF

    // Coloca um ponto entre o terceiro e o quarto dígitos
    inscricao = inscricao.replace(/(\d{3})(\d)/, '$1.$2');

    // Coloca um ponto entre o terceiro e o quarto dígitos
    // de novo (para o segundo bloco de números)
    inscricao = inscricao.replace(/(\d{3})(\d)/, '$1.$2');

    // Coloca um hífen entre o terceiro e o quarto dígitos
    inscricao = inscricao.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    // CNPJ

    // Coloca ponto entre o segundo e o terceiro dígitos
    inscricao = inscricao.replace(/^(\d{2})(\d)/, '$1.$2');

    // Coloca ponto entre o quinto e o sexto dígitos
    inscricao = inscricao.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');

    // Coloca uma barra entre o oitavo e o nono dígitos
    inscricao = inscricao.replace(/\.(\d{3})(\d)/, '.$1/$2');

    // Coloca um hífen depois do bloco de quatro dígitos
    inscricao = inscricao.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return inscricao;
}

import swal from 'sweetalert2';
import hash from 'react-router-history';
import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { createSuccessNotification, sendErroRequest } from '../utils/Notification';
import { formatarNumeroGeral } from '../utils/Functions';

const { hashHistory } = hash;

const setUnidadesIsentas = response => ({
  type: a.UDI_GET_UNIDADES_ISENTAS,
  payload: response,
});

const processandoUnidadeIsenta = bool => ({
  type: a.UDI_PROCESSANDO_UNIDADE_ISENTA,
  payload: bool,
});

export const setUnidadeIsentaById = data => dispatch => {
  dispatch({ type: a.UDI_GET_UNIDADES_ISENTAS_BY_ID, payload: data });
};
export const getUnidadesIsentas = () => async dispatch => {
  try {
    dispatch(processandoUnidadeIsenta(true));

    const { data } = await laris.get(`/unidades-isentas`);

    dispatch(setUnidadesIsentas(data));
    dispatch(processandoUnidadeIsenta(false));
  } catch (error) {
    dispatch(processandoUnidadeIsenta(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar as Unidades Isentas!');
  }
};

export const getUnidadeIsentaById = id => async dispatch => {
  try {
    dispatch(processandoUnidadeIsenta(true));

    const { data } = await laris.get(`/unidades-isentas/${id}`);

    dispatch(setUnidadeIsentaById(data));

    dispatch(processandoUnidadeIsenta(false));
  } catch (error) {
    dispatch(processandoUnidadeIsenta(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar a Unidade Isenta selecionada!');
  }
};

export const deleteUnidadeIsenta = id => async dispatch => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      const { data } = await laris.delete(`/unidades-isentas/${id}`);
      if (data.sucesso) createSuccessNotification('Unidade Isenta excluída com sucesso!');
    }

    dispatch(getUnidadesIsentas());
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao excluir a Unidade Isenta!');
  }
};

export const putUnidadeIsenta = values => async dispatch => {
  try {
    dispatch(processandoUnidadeIsenta(true));
    const params = {
      ...values,
      ...(values.unidade && { unidade: { id: values.unidade } }),
      ...(values.percentualIsencao && {
        percentualIsencao: formatarNumeroGeral(values.percentualIsencao),
      }),
    };
    await laris.put('/unidades-isentas', params);

    dispatch(processandoUnidadeIsenta(false));
    createSuccessNotification('Unidade Isenta alterada com sucesso!');
    hashHistory.push('/unidades-isentas');
    dispatch(getUnidadesIsentas());
  } catch (error) {
    dispatch(processandoUnidadeIsenta(false));
    sendErroRequest(error, 'Ocorreu um erro ao alterar a Unidade Isenta!');
  }
};

export const postUnidadeIsenta = values => async dispatch => {
  try {
    dispatch(processandoUnidadeIsenta(true));
    const params = {
      ...values,
      ...(values.unidade && { unidade: { id: values.unidade } }),
      ...(values.percentualIsencao && {
        percentualIsencao: formatarNumeroGeral(values.percentualIsencao),
      }),
    };

    const { data } = await laris.post(`/unidades-isentas`, params);
    if (data.sucesso === true) {
      createSuccessNotification('Unidade Isenta cadastrada com sucesso!');
      dispatch(processandoUnidadeIsenta(false));
      dispatch(getUnidadesIsentas());
      hashHistory.push('/unidades-isentas');
    }
  } catch (error) {
    dispatch(processandoUnidadeIsenta(false));
    sendErroRequest(error, 'Ocorreu um erro ao cadastrar a Unidade Isenta!');
  }
};

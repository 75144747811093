import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

export default function ({ children }) {
  return (
    <li
      className="dropdown"
      style={{
        marginLeft: '90%',
        listStyleType: 'none',
        display: 'inline',
        padding: '0',
      }}>
      <Tooltip title="Dúvidas">
        <i
          data-toggle="dropdown"
          href="#"
          aria-expanded="false"
          className="fa fa-question-circle fa-2x"
        />
      </Tooltip>
      <ul className="dropdown-menu dropdown-alerts">
        <li>
          <div>
            <h5> Tire suas dúvidas!</h5>
          </div>
        </li>
        <li className="dropdown-divider" />

        <li>
          <div style={{ textAlign: 'center' }}>
            <a href={children} className="btn btn-primary" target="_blank" rel="noreferrer">
              Acessar Aqui
            </a>
          </div>
        </li>
      </ul>
    </li>
  );
}

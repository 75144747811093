import { DateBox } from 'devextreme-react';
import React from 'react';

export const FormDate = ({ field, form: { touched, errors, setFieldValue }, ...props }) => {
  const { col, label, onChange } = props;
  const { value, name } = field;

  return (
    <div className={`col-lg-${col}`}>
      <div className={`form-group ${errors[field.name] ? 'has-error' : null}`}>
        <label className="control-label">{label}</label>
        <DateBox
          value={value}
          name={name}
          {...props}
          onValueChanged={option => {
            setFieldValue(field.name, option ? option.value : '');

            onChange && onChange(option);
          }}
          min="01/01/2000"
        />

        {touched[field.name] && errors[field.name] && (
          <span className="help-block m-b-none">{errors[field.name]}</span>
        )}
      </div>
    </div>
  );
};

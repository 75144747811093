import React from 'react';

type Props = {
  loading: boolean;
  children: React.ReactNode;
};
export default function Spin({ loading, children }: Props): JSX.Element {
  return (
    <div className={`ibox-content ${loading === true ? 'sk-loading' : ''}`}>
      <div className="sk-spinner sk-spinner-fading-circle">
        <div className="sk-circle1 sk-circle" />
        <div className="sk-circle2 sk-circle" />
        <div className="sk-circle3 sk-circlev" />
        <div className="sk-circle4 sk-circle" />
        <div className="sk-circle5 sk-circle" />
        <div className="sk-circle6 sk-circle" />
        <div className="sk-circle7 sk-circle" />
        <div className="sk-circle8 sk-circlev" />
        <div className="sk-circle9 sk-circle" />
        <div className="sk-circle10 sk-circle" />
      </div>
      {children}
    </div>
  );
}

import moment from 'moment';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { BLOB_STORAGE_REMESSA } from '../helpers/consts/urlConstants';
import Util from '../helpers/util';
import {
  createSuccessNotification,
  sendErroRequest,
  createInfoNotification,
} from '../utils/Notification';
import { processandoFatura, getFaturasSuccess } from './faturaActions';

export const setEstadoInicialRemessa = () => (dispatch) => {
  dispatch({ type: a.RMS_SET_ESTADO_INICIAL });
};

export const setRemessas = (response) => ({
  type: a.RMS_SET_REMESSAS,
  payload: response,
});

export const setNumeroRemessa = (data) => ({
  type: a.RMS_SET_NUMERO_REMESSA,
  payload: data,
});

export const processandoRemessa = (bool) => ({
  type: a.RMS_PROCESSANDO_REMESSA,
  payload: bool,
});
export const dadosAtualizacaoFaturaRemessa = (data) => ({
  type: a.RMS_SET_DADOS_FATURA_REMESSA,
  payload: data,
});

export const processandoFaturaRemessa = (bool) => ({
  type: a.RMS_PROCESSANDO_FATURA_REMESSA,
  payload: bool,
});

export const faturasRemessaId = (data) => ({
  type: a.RMS_SET_FATURA_REMESSA,
  payload: data,
});

export const getRemessas = (values) => async (dispatch) => {
  try {
    const filtro = {
      dataInicio: moment(values.periodoInicial).startOf('day'),
      dataFim: moment(values.periodoFinal).startOf('day'),
      portador_Id: values.portador || undefined,
    };

    dispatch(processandoRemessa(true));
    const { data } = await laris.post('/remessas/getRemessas', filtro);
    dispatch(setRemessas(data));
    dispatch(processandoRemessa(false));
  } catch (err) {
    dispatch([processandoRemessa(false)]);
    sendErroRequest(err, 'Ocorreu um erro inesperado ao tentar buscar as remessas!');
  }
};

export const getfaturasRemessaId = (id) => async (dispatch) => {
  try {
    dispatch(processandoFaturaRemessa(true));
    const { data } = await laris.get(`/remessas/faturasRemessaId/${id}`);
    dispatch(faturasRemessaId(data));
    dispatch(processandoFaturaRemessa(false));
  } catch (err) {
    sendErroRequest(err, 'Ocorreu um erro ao buscar as faturas da Remessa selecionada!');
  }
};

export const cancelarRemessa = (dataItem, remessas) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: `Tem certeza que deseja cancelar a remessa bancária com sequência ${dataItem.sequenciaRemessa} e portador ${dataItem.portador.descricao}!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      dispatch(processandoRemessa(true));
      const { data } = await laris.delete(`/remessas/${dataItem.id}`);

      const novaListaRemessas = remessas.map((item) => ({
        ...item,
        sequenciaRemessa: item.id === data.id ? data.sequenciaRemessa : item.sequenciaRemessa,
        remessaCancelada: item.id === data.id ? data.remessaCancelada : item.remessaCancelada,
        dataCancelamentoFormatada:
          item.id === data.id ? data.dataCancelamentoFormatada : item.dataCancelamentoFormatada,
        dataCancelamento: item.id === data.id ? data.dataCancelamento : item.dataCancelamento,
      }));

      dispatch(setRemessas(novaListaRemessas));

      dispatch(processandoRemessa(false));
    }
  } catch (err) {
    dispatch(processandoRemessa(false));
    sendErroRequest(err, 'Ocorreu um erro ao tentar cancelar a remessa!');
  }
};

export const gerarRemessa = (values, faturas) => async (dispatch) => {
  try {
    if (values.numeroArquivoRemessa <= 0) {
      return sendErroRequest('Remessa', 'Numeração de arquivo Remessa inválido');
    }

    const fatuasSelecionadas = faturas.map((item) => item.id);
    const { numeroArquivoRemessa } = values;
    dispatch(processandoFatura(true));

    const { data } = await laris.post(
      `/remessas/gerarRemessa/${numeroArquivoRemessa}`,
      fatuasSelecionadas,
    );
    if (data) {
      createSuccessNotification('Geração de remessa feita com sucesso!');

      Util.fazerDownloadArquivoRemessa(
        `${BLOB_STORAGE_REMESSA}/${data.linkArquivo}`,
        data.linkArquivo,
      );

      const numero = numeroArquivoRemessa + 1;

      dispatch(processandoFatura(false));
      dispatch(setNumeroRemessa(numero));

      dispatch(getFaturasSuccess([data]));
    }
  } catch (err) {
    dispatch(processandoFatura(false));
    sendErroRequest(
      err,
      'Ocorreu um erro ao gerar o arquivo remessa. Verifique os dados e tente novamente!',
    );
  }
};

export const numeracaoArquivoRemessa = () => async (dispatch) => {
  try {
    const { data } = await laris.post(`/remessas/numero-arquivo-remessa`);

    dispatch(setNumeroRemessa(data));
  } catch (err) {
    sendErroRequest(err, 'Ocorreu um erro ao buscar  a numeração do Arquivo Remessa!');
  }
};

export const calcularJurosCancelamentoFaturaRemessa = (values) => async (dispatch) => {
  try {
    if (!values.dataDoVencimentoNova) return createInfoNotification('É necessário uma data Válida');
    const dadosDto = {
      dataVencimentoAtualizada: moment(values.dataDoVencimentoNova).format('MM-DD-YYYY'),
      dataVencimento: moment(values.faturaSelecionada.dataDoVencimento).format('MM-DD-YYYY'),
      idFatura: values.faturaSelecionada.id,
    };
    const { data } = await laris.post('/remessas/calcularJurosFaturaRemessa/', dadosDto);
    if (data) {
      dispatch(dadosAtualizacaoFaturaRemessa(data.dadosAtualizacaoFaturaRemessa));
    }
  } catch (error) {
    sendErroRequest(error, 'Fatura', 'Ocorreu um erro ao carregar as faturas!');
  }
};

export const cancelarFaturaRemessa = (values, faturas) => async (dispatch) => {
  try {
    if (!values.dataDoVencimentoNova || !values.dataDoVencimento)
      return createInfoNotification('É necessário uma data Válida');
    const dadosDto = {
      dataVencimentoAtualizada: moment(values.dataDoVencimentoNova).format('MM-DD-YYYY'),
      dataVencimento: moment(values.dataDoVencimento).format('MM-DD-YYYY'),
      idFatura: values.id,
      valorDaFatura: values.valorDaFatura,
      juro: values.checkCalculo === true ? parseFloat(values.juro) : 0,
      multa: values.checkCalculo === true ? parseFloat(values.multa) : 0,
    };

    dispatch(processandoFaturaRemessa(true));

    const { data } = await laris.post('/remessas/cancelarFaturaRemessa/', dadosDto);

    createInfoNotification('A Fatura foi Editada é necessário gerar o arquivo Remessa novamente');

    const NovaLista = [...faturas];

    const idxFatura = faturas.findIndex((f) => f.id === data.id);
    if (idxFatura !== -1) NovaLista[idxFatura] = data;

    dispatch(getFaturasSuccess(NovaLista));
    dispatch(processandoFaturaRemessa(false));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao Editar as faturas!');
  }
};

import * as Yup from 'yup';

const VALIDATION_NUMBER_TEXT = 'Você deve informar apens números.';

export const CadastroSchemaPatrimonio = Yup.object().shape({
  descricao: Yup.string()
    .nullable()
    .required('Campo requerido.'),
  tipo: Yup.string()
    .nullable()
    .required('Campo requerido.'),
  natureza: Yup.string()
    .nullable()
    .required('Campo requerido.'),
  numero: Yup.string()
    .nullable()
    .required('Campo requerido.'),
  dataDeAquisicao: Yup.string()
    .nullable()
    .required('Campo requerido.'),
  quantidade: Yup.number()
    .nullable()
    .required('Campo requerido.'),
  valorDeAquisicao: Yup.string()
    .nullable()
    .required('Campo requerido.'),
});

export const CadastroShemaConta = Yup.object().shape({
  descricao: Yup.string().required('Campo requerido.'),
  codigoNormal: Yup.string().required('Campo requerido.'),
});

export const CadastroSchemaPortador = Yup.object().shape({
  descricao: Yup.string().required('Campo requerido.'),
  contaRecursoFinanceiro: Yup.string().required('Campo requerido.'),
  banco: Yup.string().required('Campo requerido.'),
  agencia: Yup.number()
    .typeError(VALIDATION_NUMBER_TEXT)
    .required('Campo requerido.'),
  conta: Yup.number()
    .typeError(VALIDATION_NUMBER_TEXT)
    .required('Campo requerido.'),
  dvConta: Yup.number()
    .typeError(VALIDATION_NUMBER_TEXT)
    .required('Campo requerido.'),
  tipoDeInscricao: Yup.string().required('Campo requerido.'),
  inscricaoDoCedente: Yup.string().required('Campo requerido.'),
  nossoNumeroInicial: Yup.string().required('Campo requerido.'),
  nossoNumeroFinal: Yup.string().required('Campo requerido.'),
  // posto: Yup.number().required('Campo requerido.'),
  tipoCodificacaoArquivo: Yup.number()
    .typeError(VALIDATION_NUMBER_TEXT)
    .required('Campo requerido.'),
  layoutCNAB: Yup.number()
    .typeError(VALIDATION_NUMBER_TEXT)
    .required('Campo requerido.'),
});

export const CadastroSchemaRateio = Yup.object().shape({
  descricao: Yup.string().required('Campo requerido.'),
  tipoDoRateio: Yup.number().required('Campo requerido.'),
});

export const CadastroUnidadeIsenta = Yup.object().shape({
  unidade: Yup.string()
    .nullable()
    .required('Campo requerido.'),
  percentualIsencao: Yup.string()
    .nullable()
    .required('Campo requerido.'),
  inicioIsencao: Yup.string()
    .nullable()
    .required('Campo requerido.'),
  fimIsencao: Yup.string()
    .nullable()
    .required('Campo requerido.'),
});

export const CadastroSchemaSindico = Yup.object().shape({
  sindico_Id: Yup.string().required('Campo requerido.'),
  dataInicioMandato: Yup.date().required('Campo requerido.'),
  dataFimMandato: Yup.date().required('Campo requerido.'),
});

export const CadastroSchemaTabelaPreco = Yup.object().shape({
  conta: Yup.string()
    .nullable()
    .required('Campo requerido.'),
  tipoDoConsumo: Yup.number()
    .nullable()
    .required('Campo requerido.'),
  dataDeInicioDaVigencia: Yup.string()
    .nullable()
    .required('Campo requerido.'),
  valorUnitario: Yup.string()
    .nullable()
    .required('Campo requerido.'),
});

export const CadastroSchemaTaxa = Yup.object().shape({
  descricao: Yup.string().required('Campo requerido.'),
});
export const CadastroSchemaUnidades = Yup.object().shape({
  unidadeBasica: Yup.string().required('Campo requerido.'),
  fracaoIdeal: Yup.string().required('Campo requerido.'),
  condomino_Id: Yup.string().required('Campo requerido.'),
  proprietario_Id: Yup.string().required('Campo requerido.'),
  unidadesCentrais: Yup.string().required('Campo requerido.'),
});

export const CadastroSchemaCondomino = Yup.object().shape({
  nome: Yup.string().required('Campo requerido.'),
  tipoInscricao: Yup.number().required('Campo requerido.'),
  inscricao: Yup.mixed()
    .when('tipoInscricao', {
      is: 2,
      then: Yup.string()
        .min(11, 'CPF deve conter 11 digitos')
        .required('Campo requerido.'),
    })
    .when('tipoInscricao', {
      is: 1,
      then: Yup.string()
        .min(14, 'CNPJ deve conter 14 digitos')
        .required('Campo requerido.'),
    }),
  endereco: Yup.string().required('Campo requerido.'),
  bairro: Yup.string().required('Campo requerido.'),
  ufId: Yup.string().required('Campo requerido.'),
  cidadeId: Yup.string().required('Campo requerido.'),
});

export const CadastroHistoricoSchema = Yup.object().shape({
  descricao: Yup.string().required('Campo requerido.'),
});

export const CadastroLocaisSchema = Yup.object().shape({
  descricao: Yup.string().required('Campo requerido.'),
});

export const cadastroMedidorUnidade = Yup.object().shape({
  numero: Yup.string()
    .nullable()
    .required('Campo requerido.'),
  leituraAtual: Yup.string()
    .nullable()
    .required('Campo requerido.'),
  tipoLeitura: Yup.string()
    .nullable()
    .required('Campo requerido.'),
  dataInstalacao: Yup.string()
    .nullable()
    .required('Campo requerido.'),
});

export const cadastroInstrucaoBancaria = Yup.object().shape({
  descricao: Yup.string().required('Campo requerido.'),
});

export const CadastroSchemaIndexadores = Yup.object().shape({
  descricao: Yup.string().required('Campo requerido.'),
  valor: Yup.string().required('Campo requerido.'),
});

import * as Yup from 'yup';

export const envioNotificacaoCondomino = Yup.object().shape({
  assunto: Yup.string()
    .required('Assunto Obrigatório.')
    .nullable(),
  unidadeId: Yup.array()
    .required('Unidades Obrigatórias.')
    .nullable(),
});

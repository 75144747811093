import React from 'react';

import TronHelp from '../tronHelp';

type Props = {
  title: string;
  helperLink: string;
};

export default function HeaderGrid({ title, helperLink }: Props): JSX.Element {
  return (
    <div className="ibox-title">
      {' '}
      <h5>{title}</h5> {helperLink && <TronHelp children={helperLink} />}
    </div>
  );
}

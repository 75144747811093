import moment from 'moment';
import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import {
  createSuccessNotification,
  sendErroRequest,
  createInfoNotification,
} from '../utils/Notification';
import { adicionandoItemFatura, setListaItensFatura, setValorTotalFaturas } from './faturaActions';

const { hashHistory } = hash;

export const setDesmonstrativosTaxaFixa = (response) => ({
  type: a.DTF_GET_DEMONSTRATIVOS_TAXA_FIXA,
  payload: response,
});

export const processandoDemonstrativoTaxaFixa = (bool) => ({
  type: a.DTF_PROCESSANDO_DEMONSTRATIVO_TAXA_FIXA,
  payload: bool,
});

export const setDemonstrativoTaxaFixa = (response) => ({
  type: a.DTF_GET_DEMONSTRATIVO_TAXA_FIXA_BY_ID,
  payload: response,
});

const setEstadoInicial = () => (dispatch) => dispatch({ type: a.DTF_SET_ESTADO_INICIAL });

export const getDemonstrativosTaxaFixa = () => async (dispatch) => {
  try {
    dispatch(processandoDemonstrativoTaxaFixa(true));

    const { data } = await laris.get(`/demonstrativos-taxa-fixa`);

    dispatch(setDesmonstrativosTaxaFixa(data));
    dispatch(processandoDemonstrativoTaxaFixa(false));
  } catch (error) {
    dispatch(processandoDemonstrativoTaxaFixa(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar as Demonstrativos Taxa Fixa!');
  }
};

export const getDemonstrativoTaxaFixaById = (id) => async (dispatch) => {
  try {
    dispatch(processandoDemonstrativoTaxaFixa(true));
    dispatch(adicionandoItemFatura(true));

    const { data } = await laris.get(`/demonstrativos-taxa-fixa/${id}`);

    dispatch(setDemonstrativoTaxaFixa(data));
    dispatch(setValorTotalFaturas(data.valorTotal));
    dispatch(setListaItensFatura(data.itensDemonstrativo));
    dispatch(adicionandoItemFatura(false));
    dispatch(processandoDemonstrativoTaxaFixa(false));
    return data;
  } catch (error) {
    dispatch(processandoDemonstrativoTaxaFixa(false));
    dispatch(adicionandoItemFatura(false));
  }
};

export const buscarUltimoDemonstrativoTaxaFixa = () => async (dispatch) => {
  try {
    dispatch(adicionandoItemFatura(true));

    const { data } = await laris.get(`/demonstrativos-taxa-fixa/buscarUltimoDemonstrativoTaxaFixa`);

    dispatch(setListaItensFatura(data.itensDemonstrativo));
    dispatch(setValorTotalFaturas(data.valorTotal));
    dispatch(adicionandoItemFatura(false));
  } catch (error) {
    dispatch(adicionandoItemFatura(false));
    sendErroRequest(
      error,
      'Ocorreu um erro inesperado ao tentar buscar o ultimo demonstrativo. Verifique os dados e tente novamente!',
    );
  }
};

export const deleteDemonstrativoTaxaFixa = (id) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      await laris.delete(`/demonstrativos-taxa-fixa/${id}`);
      dispatch(getDemonstrativosTaxaFixa('', 0));
      createSuccessNotification('Demonstrativo Taxa Fixa excluído com sucesso!');
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao excluir a Demonstrativo Taxa Fixa!');
  }
};

export const putDemonstrativoTaxaFixa =
  (demonstrativoTaxaFixa, itensFatura, valorTotalFaturas) => async (dispatch) => {
    try {
      if (itensFatura.length === 0)
        return sendErroRequest(
          'Demonstrativo de Taxa Fixa',
          'Não é possivel gravar um demonstrativo sem nenhum ítem. Insira ao menos um item e tente novamente!',
        );

      if (!(valorTotalFaturas > 0))
        return sendErroRequest(
          'Demonstrativo de Taxa Fixa',
          'Não é possivel gravar um demonstrativo com o valor total zerado ou negativo. Verifique os dados e tente novamente!',
        );

      const demonstrativo = {
        ...demonstrativoTaxaFixa,
        dataPeriodo: moment(demonstrativoTaxaFixa.dataPeriodo),
        periodoCompetencia: 0,
        itensDemonstrativo: itensFatura,
      };

      dispatch(processandoDemonstrativoTaxaFixa(true));

      await laris.put(`/demonstrativos-taxa-fixa`, demonstrativo);

      dispatch(processandoDemonstrativoTaxaFixa(false));
      createSuccessNotification('Demonstrativo Taxa Fixa alterada com sucesso!');
      hashHistory.push('/demonstrativos-taxa-fixa');
      dispatch(getDemonstrativosTaxaFixa());
    } catch (error) {
      dispatch(processandoDemonstrativoTaxaFixa(false));
      sendErroRequest(error, 'Ocorreu um erro ao alterar a Demonstrativo Taxa Fixa!');
    }
  };

export const postDemonstrativoTaxaFixa =
  (values, itensFatura, valorTotalFaturas) => async (dispatch) => {
    try {
      if (itensFatura.length === 0)
        return createInfoNotification(
          'Não é possivel gravar um demonstrativo sem nenhum ítem. Insira ao menos um item e tente novamente!',
        );

      if (!(valorTotalFaturas > 0))
        return createInfoNotification(
          'Não é possivel gravar um demonstrativo com o valor total zerado ou negativo. Verifique os dados e tente novamente!',
        );

      const mes = moment(values.dataPeriodo).month() + 1;
      const ano = moment(values.dataPeriodo).year();

      const {
        data: { existeDemonstrativo },
      } = await laris.get(`/demonstrativos-taxa-fixa/existeDemonstrativoNoPeriodo/${mes}/${ano}`);

      if (existeDemonstrativo)
        return createInfoNotification(
          `Não é possível salvar o demonstrativo pois já existe um demonstrativo de taxa fixa lançado no periodo selecionado!`,
        );

      const demonstrativo = {
        dataPeriodo: moment(values.dataPeriodo),
        periodoCompetencia: 0,
        itensDemonstrativo: itensFatura,
      };
      dispatch(processandoDemonstrativoTaxaFixa(true));
      await laris.post(`/demonstrativos-taxa-fixa`, demonstrativo);
      createSuccessNotification('Demonstrativo Taxa Fixa cadastrada com sucesso');
      dispatch(processandoDemonstrativoTaxaFixa(false));
      hashHistory.push('/demonstrativos-taxa-fixa');
      dispatch(getDemonstrativosTaxaFixa());
    } catch (error) {
      dispatch(processandoDemonstrativoTaxaFixa(false));
      sendErroRequest(error, 'Ocorreu um erro ao cadastrar a Demonstrativo Taxa Fixa!');
    }
  };

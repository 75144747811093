import * as a from '../actionsTypes/actionTypes';
import { createSuccessNotification, sendErroRequest } from '../utils/Notification';
import larisPortalUrl from '../api/LarisPortalURL';
import laris from '../api/laris';

export const setPessoaLogada = response => ({
  type: a.USR_GET_PESSOA_LOGADA,
  payload: response,
});

export const setEstadoInicialUsuarioLogado = () => ({
  type: a.USR_SET_ESTADO_INICIAL_USUARIO_LOGADO,
});

export const pessoaJaLogada = bool => ({
  type: a.USR_PESSOA_JA_LOGADA,
  payload: bool,
});

export const getPessoaLogada = () => async dispatch => {
  try {
    const { data } = await laris.get(`/pessoas-usuarios/pessoa-logada`);

    if (!data.pessoaUsuarioJaExiste) {
      await laris.post(`/pessoa-usuario/criar-pessoa-para-usuario`);

      dispatch(getPessoaLogada());
    } else {
      if (data.grupoUsuario === 'Condomino' || data.grupoUsuario === 'Sindico') {
        const baseUrlDestino = `${process.env.REACT_APP_LARIS_PORTAL}/#`;

        const tenant = sessionStorage.getItem('tenant');
        const token = sessionStorage.getItem('token');

        const moduloURL = `${baseUrlDestino}/acesso-app?accessKey=${token}&tenant=${tenant}&redirect=/${data.grupoUsuario}`;

        return window.location.assign(moduloURL);
      }

      dispatch(setPessoaLogada(data));
      dispatch(pessoaJaLogada(true));
    }
  } catch (error) {
    if (error?.response?.data) {
      sendErroRequest('Dados do Usuário', `${error?.response?.data?.erro}`, { timeOut: 8000 });
    } else {
      sendErroRequest('Dados do Usuário', `${error?.message}`, { timeOut: 8000 });
    }
  }
};

export const verificaPessoaLogada = async () => (dispatch, getState) => {
  const { usuario } = getState();

  dispatch(pessoaJaLogada(usuario.pessoaLogada.id ? true : false));
};

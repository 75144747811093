import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  estados: [],
  cidades: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.COMUM_GET_ESTADOS:
      return { ...state, estados: payload };
    case a.COMUM_GET_CIDADES:
      return { ...state, cidades: payload };
    default:
      return { ...state };
  }
};

import moment from 'moment';
import * as a from '../actionsTypes/actionTypes';
import {
  createSuccessNotification,
  sendErroRequest,
  createInfoNotification,
} from '../utils/Notification';
import laris from '../api/laris';

export const setSaldosConta = response => ({
  type: a.SLD_GET_SALDOS_CONTA,
  payload: response,
});

export const processandoSaldoConta = bool => ({
  type: a.SLD_PROCESSANDO_SALDO_CONTA,
  payload: bool,
});

export const setEstadoInicialSaldoConta = () => dispatch =>
  dispatch({ type: a.SLD_SET_ESTADO_INICIAL });

export const fazerChecagemGeralDeSaldos = values => async dispatch => {
  try {
    createInfoNotification('Checagem de saldos em Andamento! Aguarde...');
    const AnoMes = moment(values.periodo).format('YYYYMM');

    dispatch(processandoSaldoConta(true));
    const {
      data: { chave, mensagem },
    } = await laris.put(
      `/checagem-saldos/${values.fazerChecagemAnoInteiro ? `ano/${values.ano}` : AnoMes}`,
      {},
    );

    dispatch(processandoSaldoConta(false));

    createSuccessNotification(`A ${chave} realizada com sucesso, ${mensagem}!`);
  } catch (err) {
    dispatch(processandoSaldoConta(false));
    sendErroRequest(err, 'Ocorreu um erro ao fazer a chacagem geral de saldos!');
  }
};

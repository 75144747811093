import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  parametros: null,
  processandoParametro: false,
  sindicos: [],
  descricoesPrevisao: [],
  modelosBoleto: [],
  listaEmails: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.PRM_SET_ESTADO_INICIAL:
      return { ...state, processandoParametro: false, parametros: [] };
    case a.PRM_GET_PARAMETROS:
      return { ...state, parametros: payload };
    case a.PRM_PROCESSANDO_PARAMETRO:
      return { ...state, processandoParametro: payload };
    case a.PRM_GET_SINDICOS_CONDOMINO:
      return { ...state, sindicos: payload };
    case a.PRM_GET_DESCRICAO_PREVISAO_EMISSAO_BOLETO:
      return { ...state, descricoesPrevisao: payload };
    case a.PRM_GET_MODELOS_BOLETO:
      return { ...state, modelosBoleto: payload };
    case a.PRM_SET_LISTA_EMAILS_CONDOMINIO:
      return { ...state, listaEmails: payload };
    default:
      return { ...state };
  }
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import { ExcelRenderer } from 'react-excel-renderer';
import FileUploader from 'devextreme-react/file-uploader';
import { importLeituraConsumo, getTiposConsumo } from '../../../actions';
import { sendErroRequest } from '../../../utils/Notification';
import { FormDate, FormSelect } from '../../../utils/Inspinia';
import { movLeituraConsumoAutomatica } from '../../../utils/validations';
import Spin from '../../../utils/spin';

export default function ImportacaoLeituraConsumo({ visible, loading }) {
  const dispatch = useDispatch();
  const [nomeUpload, setNomeUpload] = useState(null);

  useEffect(() => {
    dispatch(getTiposConsumo());
  }, []);

  function handleOnDrop({ value }, props) {
    setNomeUpload(value?.name);
    ExcelRenderer(value, (err, resp) => {
      if (err) {
        sendErroRequest(err, 'Erro ao fazer o upload!');
      } else {
        const { values } = props;
        values.upload = resp;
      }
    });
  }
  const data = useSelector(state => state);

  const arrayTiposConsumo = data.enumerador.tiposConsumo.map(item => {
    return {
      value: item.id,
      label: item.descricao,
    };
  });

  async function handleSubmit(values) {
    const retorno = await dispatch(importLeituraConsumo(values));
    if (retorno) visible();
  }

  function handleUpload(props) {
    setNomeUpload(null);
    props.values.upload = null;
  }
  return (
    <Spin loading={loading}>
      <Formik
        initialValues={{
          tipoDoConsumo: null,
          upload: null,
          dataDeReferencia: new Date(),
        }}
        onSubmit={handleSubmit}
        validationSchema={movLeituraConsumoAutomatica}
      >
        {props => (
          <>
            <div className="row" style={{ width: '100%' }}>
              <Field
                col={6}
                label="Tipo de Consumo:"
                name="tipoDoConsumo"
                component={FormSelect}
                options={arrayTiposConsumo}
              />

              <Field
                col={6}
                name="dataDeReferencia"
                label="Data de referência: "
                component={FormDate}
              />
            </div>

            <div
              className="row"
              style={{
                width: '100%',
                marginLeft: '3px',
              }}
            >
              <div
                className="col-md-6"
                style={{
                  border: ' dashed 2px',
                  textAlign: 'center',
                }}
              >
                <div className="form-group">
                  <b> Planilha para ser Anexada</b>
                  <FileUploader
                    rtlEnabled
                    showFileList={false}
                    uploadFile={value => handleOnDrop(value, props)}
                    selectButtonText="clique aqui"
                    labelText="Arraste ou"
                    uploadMode="instantly"
                  />
                </div>
              </div>
              <div
                className="col-md-6"
                style={{
                  textAlign: 'center',
                }}
              >
                {' '}
                <b> Obs:</b> Lembre-se de Anexar a Planilha Padrão do Laris{' '}
                <b>(exportada pelo Laris)</b> e não fazer alterações no id ou na unidade,pois o
                consumo é inserido com base nessas informações.{' '}
              </div>
            </div>
            <div className="row" style={{ width: '100%', marginTop: '10px' }}>
              <div className="cold-md-1">
                {nomeUpload && (
                  <>
                    <a style={{ marginLeft: 15 }}>{nomeUpload}</a>
                    <i
                      className="fa fa-trash"
                      style={{ marginLeft: 10, trigger: 'click' }}
                      onClick={() => handleUpload(props)}
                    />
                  </>
                )}
              </div>
              <div className="col-md-11" style={{ marginTop: '10px' }}>
                <button
                  onClick={visible}
                  className="btn btn-primary pull-right"
                  type="button"
                  style={{ marginLeft: '8px' }}
                >
                  Cancelar
                </button>

                <button
                  className="btn btn-primary pull-right"
                  type="submit"
                  onClick={props.handleSubmit}
                >
                  Importar Dados
                </button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </Spin>
  );
}

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  logs: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case a.UND_SET_ESTADO_INICIAL:
      return {
        ...state,
        processandoLogs:false,
        logs: [],
      };
    case a.LOG_GET_LOGS:
      return { ...state, logs: payload };
    case a.LOG_PROCESSANDO_LOGS:
      return { ...state, processandoLogs: payload };
    default:
      return { ...state };
  }
};

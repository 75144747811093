import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  observacoes: [],
  processandoObservacoes: false,
  observacaoById: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.OBS_SET_ESTADO_INICIAL:
      return { ...state, processandoObservacoes: false, observacoes: [] };
    case a.OBS_GET_OBSERVACOES:
      return { ...state, observacoes: payload };
    case a.OBS_PROCESSANDO_OBSERVACOES:
      return { ...state, processandoObservacoes: payload };
    case a.OBS_GET_OBSERVACOES_BY_ID:
      return { ...state, observacaoById: payload };
    default:
      return { ...state };
  }
};

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  taxas: [],
  taxasMarcadas: [],
  processandoTaxa: false,
  taxaById: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.TXA_SET_ESTADO_INICIAL:
      return { ...state, processandoTaxa: false, taxas: [] };
    case a.TXA_GET_TAXAS:
      return { ...state, taxas: payload };
    case a.TXA_GET_TAXAS_MARCADAS:
      return { ...state, taxasMarcadas: payload };
    case a.TXA_PROCESSANDO_TAXA:
      return { ...state, processandoTaxa: payload };
    case a.TXA_GET_TAXAS_BY_ID:
      return { ...state, taxaById: payload };
    default:
      return { ...state };
  }
};

import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  indexadores: [],
  processandoIndexadores: false,
  indexadorById: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.IND_SET_ESTADO_INICIAL:
      return { ...state, processandoIndice: false, indexadores: [], indexadorById: null };
    case a.IND_GET_INDEXADORES:
      return { ...state, indexadores: payload };
    case a.IND_PROCESSANDO_INDEXADORES:
      return { ...state, processandoIndexadores: payload };
    case a.IND_SET_INDEXADORES_BY_ID:
      return { ...state, indexadorById: payload };
    default:
      return { ...state };
  }
};

import swal from 'sweetalert2';
import hash from 'react-router-history';
import * as a from '../actionsTypes/actionTypes';
import { createSuccessNotification, sendErroRequest } from '../utils/Notification';
import laris from '../api/laris';

const { hashHistory } = hash;

export const setTiposValor = response => {
  return {
    type: a.ENUM_SET_TIPOS_VALOR,
    payload: response,
  };
};

export const setTiposDespesaReceita = response => {
  return {
    type: a.ENUM_SET_TIPOS_DESPESA_RECEITA,
    payload: response,
  };
};

export const getTipoDespesaReceitaSuccess = (response) => ({
  type: a.ENUM_GET_TIPOS_DESPESA_RECEITA,
  payload: response,
});

export const setTiposCalculoFundoReserva = response => {
  return {
    type: a.ENUM_SET_TIPOS_CALCULO_FUNDO_RESERVA,
    payload: response,
  };
};

export const setTiposConsumo = response => {
  return {
    type: a.ENUM_SET_TIPOS_CONSUMO,
    payload: response,
  };
};

export const setTiposLancamento = response => {
  return {
    type: a.ENUM_SET_TIPOS_LANCAMENTO,
    payload: response,
  };
};

export const setTiposRateio = response => {
  return {
    type: a.ENUM_SET_TIPOS_RATEIO,
    payload: response,
  };
};

export const setTiposPatrimonio = response => {
  return {
    type: a.ENUM_SET_TIPOS_PATRIMONIO,
    payload: response,
  };
};

export const setNaturezasPatrimonio = response => {
  return {
    type: a.ENUM_SET_NATUREZAS_PATRIMONIO,
    payload: response,
  };
};

export const setModelosBoleto = response => {
  return {
    type: a.ENUM_SET_MODELOS_BOLETO,
    payload: response,
  };
};

export const setDescricoesPrevisaoEmissaoBoleto = response => {
  return {
    type: a.ENUM_SET_DESCRICAO_PREVISAO_EMISSAO_BOLETO,
    payload: response,
  };
};

export const setTiposInscricao = response => {
  return {
    type: a.ENUM_SET_TIPOS_INSCRICAO,
    payload: response,
  };
};

export const setModelosCarteira = response => {
  return {
    type: a.ENUM_SET_MODELOS_CARTEIRA,
    payload: response,
  };
};

export const setSistemasDeCobranca = response => {
  return {
    type: a.ENUM_SET_SISTEMAS_DE_COBRANCA,
    payload: response,
  };
};

export const setTiposValorJuro = response => {
  return {
    type: a.ENUM_SET_TIPOS_VALOR_JURO,
    payload: response,
  };
};

export const setTiposValorMulta = response => {
  return {
    type: a.ENUM_SET_TIPOS_VALOR_MULTA,
    payload: response,
  };
};

export const setStatusFatura = response => {
  return {
    type: a.ENUM_SET_STATUS_FATURA,
    payload: response,
  };
};

const setStatusAcordoSuccess = payload => ({
  type: a.ENUM_SET_STATUS_ACORDO_SUCCESS,
  payload,
});

const setStatusAcordoFail = payload => ({
  type: a.ENUM_SET_STATUS_ACORDO_FAIL,
  payload,
});

const setTipoFatura = payload => ({
  type: a.ENUM_SET_TIPOS_FATURA,
  payload,
});

export const setTiposRelatorios = response => {
  return {
    type: a.ENUM_SET_TIPOS_RELATORIOS,
    payload: response,
  };
};

export const processandoTipoReceita = (bool) => ({
  type: a.ENUM_PROCESSANDO_TIPOS_DESPESA_RECEITA,
  payload: bool,
});

//#endregion

export const getStatusFatura = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getStatusFatura');
    if (data) dispatch(setStatusFatura(data));
  } catch (error) {
    sendErroRequest('Ocorreu um erro ao carregar os status da fatura!');
  }
};

export const getTipoFatura = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getTipoFatura');
    if (data) dispatch(setTipoFatura(data));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar os status da fatura!');
  }
};

export const getTiposValorJuro = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getTiposValorJuro');
    if (data) dispatch(setTiposValorJuro(data));
  } catch (error) {
    sendErroRequest('Ocorreu um erro ao carregar os tipos de valor juros');
  }
};

export const getTiposValorMulta = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getTiposValorMulta');
    dispatch(setTiposValorMulta(data));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar os tipos de valor Multa');
  }
};

export const getSistemasDeCobranca = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getSistemasDeCobranca');
    dispatch(setSistemasDeCobranca(data));
  } catch (error) {
    sendErroRequest(error, 'Erro ao carregar os sitemas de cobrança!');
  }
};

export const getModelosCarteira = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getModelosCarteira');
    dispatch(setModelosCarteira(data));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar os modelos de carteira');
  }
};

export const getTiposInscricao = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getTiposInscricao');
    if (data) dispatch(setTiposInscricao(data));
  } catch (error) {
    sendErroRequest('Ocorreu um erro ao carregar os tipos de inscrição');
  }
};

export const getDescricaoPrevisaoEmissaoBoleto = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getDescricaoPrevisaoEmissaoBoleto');
    if (data) dispatch(setDescricoesPrevisaoEmissaoBoleto(data));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar as descrições para previsão!');
  }
};

export const getModelosBoleto = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getModelosBoleto');
    const dataReturn = data.filter(f => f.descricao.indexOf('Segzoom') === -1);

    if (dataReturn) dispatch(setModelosBoleto(dataReturn));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar os modelos de boleto!');
  }
};

export const getTiposPatrimonio = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getTiposPatrimonio');
    if (data) dispatch(setTiposPatrimonio(data));
  } catch (error) {
    sendErroRequest(error, 'Erro ao carregar os tipos de patrimônio!');
  }
};

export const getNaturezasPatrimonio = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getNaturezasPatrimonio');
    if (data) dispatch(setNaturezasPatrimonio(data));
  } catch (error) {
    sendErroRequest(error, 'Erro ao carregar as naturezas do patrimônio!');
  }
};

export const getTiposValor = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getTiposValor');
    if (data) dispatch(setTiposValor(data));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar os tipos de valor!');
  }
};

export const getTiposCalculoFundoReserva = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getTiposCalculoFundoReserva');
    if (data) dispatch(setTiposCalculoFundoReserva(data));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar os tipos de valor do fundo de reserva!');
  }
};

export const getTiposConsumo = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getTiposConsumo');

    dispatch(setTiposConsumo(data));
  } catch (error) {
    sendErroRequest('Ocorreu um erro ao carregar os Tipos de consumo!');
  }
};

export const getTiposDespesaReceita = () => async dispatch => {
  try {
    dispatch(processandoTipoReceita(true));

    const { data } = await laris.get('/enumeradores/getTiposDespesaReceita');

    dispatch(setTiposDespesaReceita(data));
    dispatch(getTipoDespesaReceitaSuccess(data), processandoTipoReceita(false));
  } catch (error) {
    dispatch(processandoTipoReceita(false));
    sendErroRequest(error, 'Ocorreu um erro ao carregar os tipos de receita e despesa!');
  }
};

export const getTiposLancamento = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getTiposLancamento');
    dispatch(setTiposLancamento(data));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar os tipos de lançamento!');
  }
};

export const getTiposRateio = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getTiposRateio');

    dispatch(setTiposRateio(data));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar os tipos de rateio!');
  }
};

export const getStatusAcordo = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/status-acordo');

    dispatch(setStatusAcordoSuccess(data));
  } catch (error) {
    dispatch(setStatusAcordoFail());
    sendErroRequest('Ocorreu um erro ao carregar os status de acordo!');
  }
};

export const getTipoRelatorio = () => async dispatch => {
  try {
    const { data } = await laris.get('/enumeradores/getTiposRelatorios');
    if (data) dispatch(setTiposRelatorios(data));
  } catch (error) {
    sendErroRequest('Ocorreu um erro ao carregar os tipos de relatórios!');
  }
};

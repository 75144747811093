import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  leiturasDeConsumo: [],
  processandoLeituraDeConsumo: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.LDC_SET_ESTADO_INICIAL:
      return { ...state, processandoLeituraDeConsumo: false, leiturasDeConsumo: [] };
    case a.LDC_GET_LEITURAS_DE_CONSUMO:
      return { ...state, leiturasDeConsumo: payload };
    case a.LDC_PROCESSANDO_LEITURA_DE_CONSUMO:
      return { ...state, processandoLeituraDeConsumo: payload };
    default:
      return { ...state };
  }
};

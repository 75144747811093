import { sumBy } from 'lodash';
import moment from 'moment';
import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { newGuid, formatarNumeroDecimal, formatarNumeroGeral } from '../utils/Functions';
import {
  sendErroRequest,
  createInfoNotification,
  createSuccessNotification,
} from '../utils/Notification';
import { processandoPrevisao, limparListaItensPrevisao, setItensPrevisao } from './previsaoActions';

const { hashHistory } = hash;

const setParcelamentos = (response) => ({
  type: a.PRC_GET_PARCELAMENTOS,
  payload: response,
});

const processandoParcelamento = (bool) => ({
  type: a.PRC_PROCESSANDO_PARCELAMENTO,
  payload: bool,
});

const parcelamentoById = (data) => ({
  type: a.PRC_GET_PARCELAMENTOS_BY_ID,
  payload: data,
});

export const setListaUnidadesAdiantamento = (response) => ({
  type: a.PRC_SET_UNIDADES_ADIANTAMENTO,
  payload: response,
});

const setParcelasSimuladas = (response) => ({
  type: a.PRC_SET_PARCELAS_SIMULADAS,
  payload: response,
});

export const setListaParcelasAnaliticas = (response) => ({
  type: a.PRC_SET_PARCELAS_ANALITICAS,
  payload: response,
});

export const getParcelamentos = () => async (dispatch) => {
  try {
    dispatch(processandoParcelamento(true));

    const { data } = await laris.get(`/parcelamentos`);

    dispatch(setParcelamentos(data));
    dispatch(setListaParcelasAnaliticas([]));
    dispatch(setParcelasSimuladas([]));
    dispatch(processandoParcelamento(false));
  } catch (error) {
    dispatch(processandoParcelamento(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar as Parcelamentos!');
  }
};

export const getParcelamentoById = (id) => async (dispatch) => {
  try {
    dispatch(processandoParcelamento(true));

    const { data } = await laris.get(`/parcelamentos/${id}`);

    dispatch(parcelamentoById(data));
    dispatch(setListaParcelasAnaliticas([]));
    dispatch(setParcelasSimuladas(data.parcelasSinteticas));
    dispatch(processandoParcelamento(false));
  } catch (error) {
    dispatch(processandoParcelamento(false));
    sendErroRequest(error, 'Ocorreu um erro ao carregar a Parcelamento!');
  }
};

export const simularAdiantamento = (values) => async (dispatch) => {
  try {
    if (values.parcelasAdiantar > values.quantidadeParcelas)
      return createInfoNotification(
        'A quantidade de parcelas adiantadas é maior que o número de parcelas!',
      );

    if (!values.id)
      return createInfoNotification('Não é possível fazer a simulação sem Id selecionado!');

    if (!values.parcelasAdiantar)
      return createInfoNotification(
        'Não é possível fazer a simulação sem  a quantidade de parcelas !',
      );

    if (!values.idUnidade)
      return createInfoNotification('Não é possível fazer a simulação sem a unidade selecionada!');

    dispatch(processandoParcelamento(true));

    const { data } = await laris.get(
      `/parcelamentos/simularAdiantamentoParcelamentoPorUnidade/${values.id}/${values.idUnidade}/${values.parcelasAdiantar}`,
    );

    dispatch(setListaParcelasAnaliticas(data));
    dispatch(processandoParcelamento(false));
  } catch (error) {
    dispatch(processandoParcelamento(false));
    sendErroRequest(
      error,
      'Ocorreu um erro ao simular o adiantamento das parcelas. Verifique os dados e tente novamente!',
    );
  }
};

export const salvarAdiantamento = (values) => async (dispatch) => {
  try {
    if (!values.id)
      return createInfoNotification('Não é possível fazer a simulação sem Id selecionado!');

    if (!values.parcelasAdiantar)
      return createInfoNotification(
        'Não é possível fazer a simulação sem a quantidade de parcelas !',
      );

    if (!values.idUnidade)
      return createInfoNotification('Não é possível fazer a simulação sem a unidade selecionada!');
    dispatch(processandoParcelamento(true));

    await laris.put(
      `/parcelamentos/salvarAdiantamentoParcelamentoPorUnidade/${values.id}/${values.idUnidade}/${values.parcelasAdiantar}`,
      {},
    );

    dispatch(processandoParcelamento(false));

    hashHistory.push(`/parcelamentos/`);
    createInfoNotification('Adiantamento realizado com sucesso');
  } catch (error) {
    dispatch(processandoParcelamento(false));
    sendErroRequest(
      error,
      'Ocorreu um erro ao salvar o adiantamento das parcelas. Verifique os dados e tente novamente!',
    );
  }
};

export const getAdiantamentoById = (id) => async (dispatch) => {
  try {
    dispatch(processandoParcelamento(true));

    const { data } = await laris.get(`/parcelamentos/${id}`);

    const unidades = [];
    data.parcelasAnaliticas.map((item) => {
      if (!unidades.find((und) => item.unidade.id === und.id)) unidades.push(item.unidade);
    });

    dispatch(parcelamentoById(data));
    dispatch(setListaParcelasAnaliticas([]));
    dispatch(setParcelasSimuladas(data.parcelasSinteticas));
    dispatch(setListaUnidadesAdiantamento(unidades));
    dispatch(processandoParcelamento(false));
  } catch (error) {
    dispatch(processandoParcelamento(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar o adiantamento selecionada!');
  }
};

export const deleteParcelamento = (id) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      const { data } = await laris.delete(`/parcelamentos/${id}`);
      if (data) {
        createSuccessNotification('Parcelamento excluído com sucesso!');
        dispatch(getParcelamentos());
      }
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao exluir a Parcelamento!');
  }
};

export const postParcelamento =
  (values, parcelasSimuladas, parcelasAnaliticas) => async (dispatch) => {
    try {
      let totalParcelasSimuladas = 0;

      parcelasSimuladas.forEach((item) => (totalParcelasSimuladas += item.valor));

      if (
        Math.trunc(totalParcelasSimuladas * 100) / 100 !==
        formatarNumeroGeral(values.valorTotalParcelamento)
      ) {
        return sendErroRequest(
          'Parcelamento',
          'O valor das parcelas deve ser Igual ao valor total do parcelamento!',
        );
      }

      const parcelamento = {
        ...values,
        id: null,
        decricao: values.descricao,
        dataPrimeiroVencimento: values.dataPrimeiroVencimento,
        descricaoContaSintetica: values.descricaoContaSintetica,
        documento: values.documento,
        quantidadeParcelas: values.quantidadeParcelas,
        tipoDespesaReceita: values.tipoDespesaReceita,
        tipoLancamentoDespesaReceita: values.tipoLancamentoDespesaReceita,
        valorDoLancamento: formatarNumeroGeral(values.valorDoLancamento),
        valorTotalParcelamento: formatarNumeroGeral(values.valorTotalParcelamento),
        taxa: { id: values.taxaId },
        historico: values.historicoId && { id: values.historicoId },
        conta: { id: values.contasDespesa },
        parcelasSinteticas: parcelasSimuladas,
        parcelasAnaliticas,
      };

      dispatch(processandoParcelamento(true));
      await laris.post(`/parcelamentos`, parcelamento);

      dispatch(processandoParcelamento(false));
      createSuccessNotification('Parcelamento Salvo com sucesso!');
      hashHistory.push('/parcelamentos');
    } catch (error) {
      dispatch(processandoParcelamento(false));
      sendErroRequest(error, 'Ocorreu um erro ao Salvar o Parcelamento!');
    }
  };

export const editarParcelaSimulada = (parcelasSimuladas) => async (dispatch) => {
  try {
    dispatch(processandoParcelamento(true));
    dispatch(setParcelasSimuladas(parcelasSimuladas));
    dispatch(setListaParcelasAnaliticas([]));
    dispatch(processandoParcelamento(false));
  } catch (error) {
    dispatch(processandoParcelamento(false));
    sendErroRequest(error, 'Ocorreu um erro ao Editar a Parcela');
  }
};

export const simularParcelamentoMovimentacao = (values) => async (dispatch) => {
  try {
    const parcelamento = values;
    if (!parcelamento.quantidadeParcelas)
      return createInfoNotification(
        'Não é possível fazer o parcelamento sem o número de parcelas!',
      );

    if (!parcelamento.dataPrimeiroVencimento)
      return createInfoNotification(
        'Informe a data do primeiro vencimento antes de simular o parcelamento!',
      );

    if (!parcelamento.valorTotalParcelamento)
      return createInfoNotification(
        'Informe o valor total do parcelamento antes de continuar com a simulação!',
      );

    const calculoParcela = calcularValorParcela(
      parcelamento.valorTotalParcelamento,
      parcelamento.quantidadeParcelas,
    );

    dispatch(processandoParcelamento(true));

    const parcelas = [];

    for (let index = 0; index < parcelamento.quantidadeParcelas; index++) {
      let { valorParcela } = calculoParcela;

      if (index + 1 === parcelamento.quantidadeParcelas) valorParcela += calculoParcela.diferenca;

      parcelas.push({
        numeroParcela: index + 1,
        periodoCompetenciaFormatado: moment(parcelamento.dataPrimeiroVencimento)
          .add(index, 'M')
          .format('MM/YYYY'),
        valor: valorParcela,
      });
    }

    dispatch(setParcelasSimuladas(parcelas));
    dispatch(setListaParcelasAnaliticas([]));

    // setTimeout(() => dispatch(processandoParcelamento(false)), 300);
  } catch (error) {
    dispatch(processandoParcelamento(false));
    sendErroRequest(error, 'Ocorreu um erro ao Simular Parcelamento');
  }
};

const calcularValorParcela = (valorParcelamento, quantidadeParcelas) => {
  const valorParcelamentoFormatado = formatarNumeroDecimal(valorParcelamento);

  const valorParcela = parseFloat(
    (valorParcelamentoFormatado / quantidadeParcelas).toFixed(3).slice(0, -1),
  );

  const novoValorParcelamento = parseFloat(
    (valorParcela * quantidadeParcelas).toFixed(3).slice(0, -1),
  );

  const diferenca = parseFloat(
    (valorParcelamentoFormatado - novoValorParcelamento).toFixed(3).slice(0, -1),
  );

  return {
    valorParcela,
    diferenca,
  };
};

export const adicionarItemParcelaAnalitica =
  (values, parcelasSimuladas, unidades) => async (dispatch) => {
    try {
      if (values.unidadeId === '') {
        return createInfoNotification(
          'É necessário selecionar uma unidade antes de adicionar ou simular as previsões!',
        );
      }
      const itensParcelaAnaliticas = [];

      const arrayUnidade = unidades.filter((u) => u.id === values.unidadeId);

      let idUnidadeSelecionada = '';
      let descricaoUnidadeBasica = '';

      if (arrayUnidade.length > 0) {
        idUnidadeSelecionada = arrayUnidade[0].id;
        descricaoUnidadeBasica = arrayUnidade[0].unidadeBasica;
      }
      const parcelamentoForm = {
        dataPrimeiroVencimento: values.dataPrimeiroVencimento,
        ValorTotalParcelamento: formatarNumeroGeral(values.valorTotalParcelamento),
        valorDoLancamento: formatarNumeroGeral(values.valorDoLancamento),
        conta: { id: values.contasDespesa },
        unidade_Id: { id: values.unidadeId },
        quantidadeParcelas: values.quantidadeParcelas,
      };
      // const { parcelasSimuladas } = values.parcelamento;

      if (parcelasSimuladas.length === 0) {
        return createInfoNotification(
          'É necessário simular um parcelamento antes de adicionar ou simular as previsões!',
        );
      }

      if (!parcelamentoForm.valorDoLancamento) {
        createInfoNotification('Informe o valor antes de gerar as previsões!');
        return;
      }

      if (!parcelamentoForm.dataPrimeiroVencimento) {
        createInfoNotification('Informe a data do lançamento antes de gerar as previsões!');
        return;
      }

      if (!parcelamentoForm.quantidadeParcelas) {
        createInfoNotification(
          'Informe a quantidade de parcelas na aba parcelamento para gerar a simulação das previsões!',
        );
        return;
      }

      dispatch(processandoParcelamento(true));

      let itemParcelaAnalitica = {};

      const dataPrimeiroVencimento = moment(parcelamentoForm.dataPrimeiroVencimento);

      for (let i = 0; i < parcelamentoForm.quantidadeParcelas; i++) {
        const periodoCompetenciaFormatado = Intl.DateTimeFormat('pt-BR', {
          year: 'numeric',
          month: '2-digit',
        }).format(new Date(dataPrimeiroVencimento));

        const ano = Intl.DateTimeFormat('pt-BR', { year: 'numeric' }).format(
          new Date(dataPrimeiroVencimento),
        );
        const mes = Intl.DateTimeFormat('pt-BR', { month: '2-digit' }).format(
          new Date(dataPrimeiroVencimento),
        );
        const contadorParcela = i + 1;
        const periodoCompetencia = parseInt(`${ano + mes}`);
        const valorAtual = parcelasSimuladas.filter((item) => {
          if (item.numeroParcela == contadorParcela) {
            return item.valor;
          }
        });
        itemParcelaAnalitica = {
          numeroParcela: i + 1,
          unidade: {
            id: idUnidadeSelecionada,
            unidadeBasica: descricaoUnidadeBasica,
          },

          valorParcela: valorAtual[0].valor,
          geradaPrevisao: false,
          periodoCompetencia,
          periodoCompetenciaFormatado,
          percentual: 100,
          memoriaCalculo: `Valor da previsão R$ ${
            parcelasSimuladas.valor ? parcelasSimuladas.valor : 0.0
          }`,
        };
        itensParcelaAnaliticas.push(itemParcelaAnalitica);
        itemParcelaAnalitica = {};
        dataPrimeiroVencimento.add(1, 'M');
      }
      dispatch(setListaParcelasAnaliticas(itensParcelaAnaliticas));

      setTimeout(() => dispatch(processandoParcelamento(false)), 300);
    } catch (error) {
      sendErroRequest(error, 'Ocorreu um erro ao gerar os itens da previsão!');
    }
  };

export const gerarItensPrevisao = (values, contaRateio) => async (dispatch) => {
  try {
    const parcelamentoForm = {
      dataPrimeiroVencimento: values.dataPrimeiroVencimento,
      ValorTotalParcelamento: formatarNumeroGeral(values.valorTotalParcelamento),
      valorDoLancamento: formatarNumeroGeral(values.valorDoLancamento),
      conta: { id: values.contasDespesa },
      quantidadeParcelas: values.quantidadeParcelas,
    };

    if (!parcelamentoForm.valorDoLancamento) {
      return createInfoNotification('Informe o valor antes de gerar as previsões!');
    }

    if (!parcelamentoForm.dataPrimeiroVencimento) {
      return createInfoNotification(
        'Informe a data do primeiro vencimento antes de gerar as previsões!',
      );
    }

    if (contaRateio) {
      dispatch(processandoParcelamento(true));

      const { data } = await laris.post(
        '/parcelamentos/simularPrevisaoParcelada',
        parcelamentoForm,
      );

      if (data) dispatch(setListaParcelasAnaliticas(data.parcelasAnaliticas));

      dispatch(processandoParcelamento(false));
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao simular ou Adicionar os itens da previsão');
  }
};

import React, { Suspense } from 'react';
import { connect } from 'react-redux';

import { HashRouter, Route, Switch } from 'react-router-dom';
import RouteWithSubRoutes from './routeWithSubRoutes';
import SentinelaURL from '../api/SentinelaURL';
import NaoEncontrada from '../components/NaoEncontrada';
import BasicLayout from '../theme/BasicLayout';

const Routers = ({ rota }) => {
  const verificaToken = () => {
    const token = sessionStorage.getItem('token');
    const { origin } = window.location;
    const redirectURL = `redirectURL=${origin}&toLocation=/`;

    return token ? (
      <NaoEncontrada />
    ) : (
      window.location.assign(
        `${SentinelaURL[process.env.NODE_ENV]}/#/login?${redirectURL}&fromApplication=2`,
      )
    );
  };

  return (
    <HashRouter>
      <BasicLayout>
        <Suspense fallback={<div />}>
          <Switch>
            {rota.rotas.map(route => (
              <RouteWithSubRoutes key={route.path} {...route} />
            ))}

            <Route path="*" render={verificaToken} />
          </Switch>
        </Suspense>
      </BasicLayout>
    </HashRouter>
  );
};

const mapStateToProps = state => ({ rota: state.rota });

export default connect(mapStateToProps)(Routers);

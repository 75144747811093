import * as a from '../actionsTypes/actionTypes';

const INITIAL_STATE = {
  condominos: [],
  processandoCondomino: false,
  processandoUploadCondomino: false,
  tipoInscricao: {},
  listaEmails: [],
  proprietarios: [],
  condominoById: null,
  listaCondominosEditar: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.CDM_SET_ESTADO_INICIAL:
      return {
        ...state,
        processandoCondomino: false,
        processandoUploadCondomino: false,
        condominos: [],
        tipoInscricao: {},
        listaEmails: [],
        condominoById: null,
        listaCondominosEditar: [],
      };
    case a.CDM_GET_CONDOMINOS:
      return { ...state, condominos: payload };
    case a.CDM_PROCESSANDO_CONDOMINO:
      return { ...state, processandoCondomino: payload };
    case a.CDM_SET_MASCARA_INSCRICAO:
      return { ...state, tipoInscricao: payload };
    case a.CDM_SET_LISTA_EMAILS:
      return { ...state, listaEmails: payload };
    case a.CDM_GET_PROPRIETARIOS:
      return { ...state, proprietarios: payload };
    // case a.CDM_ADD_EMAIL_CONDOMINO:
    //     return { ...state, listaEmails, payload }
    case a.CDM_GET_CONDOMINO_BY_ID:
      return { ...state, condominoById: payload };
    case a.CDM_SET_CONDOMINOS_EDITAR: {
      return { ...state, listaCondominosEditar: payload };
    }
    case a.CDM_PROCESSANDO_UPLOAD_CONDOMINO: {
      return { ...state, processandoUploadCondomino: payload };
    }
    default:
      return { ...state };
  }
};
